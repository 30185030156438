import React from 'react';
import { tss } from 'tss-react';
import { GetAutopilotsResponse, GetListsResponse, SearchResponse2 } from 'src/generated/api';
import {
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { compactNumber } from 'src/utils';
import { Language, LocationOn } from '@mui/icons-material';
import normalizeUrl from 'normalize-url';
import TextWithHighlights from 'src/components/TextWithHighlights';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    contents: {
      display: 'flex',
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    statsRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      marginLeft: 'auto',
    },
    companyInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    chipsSection: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
    },
    companyTitle: {
      fontWeight: 600,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    score: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    employeeChipContents: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    tooltip: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 400,
      padding: theme.spacing(1),
    },
    chip: {
      '&:hover': {
        filter: 'brightness(120%)',
        cursor: 'pointer',
      },
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    listingRow: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.neutral.plainHoverBg,
      },
    },
    description: {
      fontSize: 12,
      marginTop: 4,
    },
    saChip: {
      fontSize: 10,
    },
    addIcon: {
      width: 14,
      height: 14,
      marginRight: 2,
    },
    subtitleRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 2,
    },
    subtitleIcon: {
      width: 16,
      height: 16,
    },
    detailsCard: {
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      padding: theme.spacing(1),
      flex: 1,
    },
    detailsCards: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
  }));

type Props = {
  selectedIds: string[];
  setSelectedIds: ((prev: (ids: string[]) => string[]) => void);
  hasDescription: boolean;
  numProductServices: number;
  numCustomerSegments: number;
  numServiceAreas: number;
  result: SearchResponse2['results'][number];
  setSelectedBusinessId: (id: string) => void;
  lists: GetListsResponse['results'] | null;
  autopilots: GetAutopilotsResponse['autopilots'] | null;
};

function SearchResult({
  selectedIds,
  setSelectedIds,
  hasDescription,
  numProductServices,
  numCustomerSegments,
  result,
  setSelectedBusinessId,
  lists,
  autopilots,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  return (
    <tr
      key={result.businessId}
      className={classes.listingRow}
      onClick={() => setSelectedBusinessId(result.businessId)}
    >
      <td>
        <Checkbox
          size='lg'
          checked={selectedIds.includes(result.businessId)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedIds((prev) =>
                [...new Set([...prev, result.businessId])]);
            } else {
              setSelectedIds((prev) =>
                prev.filter((id) => id !== result.businessId));
            }
          }}
          slotProps={{
            checkbox: {
              onClick: (e) => {
                e.stopPropagation();
              },
            },
          }}
        />
      </td>
      <td>
        <div className={classes.contents}>
          <div className={classes.row}>
            <div className={classes.companyInfo}>
              <div className={classes.companyTitle}>
                <Typography level='body-md'>
                  {result.name}
                </Typography>
                {
                  autopilots && result.autopilots.map((l) => (
                    <Chip size='sm' key={l} color='primary'>
                      {autopilots.find((l2) => l === l2.id)?.name}
                    </Chip>
                  ))
                }
                {
                  lists && result.lists.map((l) => (
                    <Chip size='sm' key={l}>
                      {lists.find((l2) => l === l2.id)?.name}
                    </Chip>
                  ))
                }
              </div>
              <div className={classes.row}>
                <Typography
                  level='body-xs'
                  className={classes.subtitleRow}
                  onClick={(e) => {
                    window.open(normalizeUrl(result.domain));
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <Language className={classes.subtitleIcon} />
                  {result.domain}
                </Typography>
                <Typography level='body-xs' className={classes.subtitleRow}>
                  <LocationOn className={classes.subtitleIcon} />
                  {result.city}, {result.state}
                </Typography>
                {
                  result.serviceAreaStates.length > 0 &&
                    <div className={classes.chipsSection}>
                      {
                        result.serviceAreaStates
                          .slice(0, 8)
                          .map((s) => (
                            <Chip
                              color="primary"
                              size='sm'
                              key={`${result.businessId}-${s}`}
                              className={classes.saChip}
                            >
                              {s}
                            </Chip>
                          ))
                        }
                    </div>
                }
              </div>
            </div>
            <div className={classes.statsRow}>
              {
                result.revenue &&
                  <div className={classes.score}>
                    <Typography level='body-md'>
                      <b>
                        ${
                          compactNumber(result.revenue)
                        }
                      </b>
                    </Typography>
                    <Typography level='body-xs'>
                      Revenue
                    </Typography>
                  </div>
              }
              {
                result.totalFunding &&
                  <div className={classes.score}>
                    <Typography level='body-md'>
                      <b>
                        ${
                          compactNumber(result.totalFunding)
                        }
                      </b>
                    </Typography>
                    <Typography level='body-xs'>
                      Funding
                    </Typography>
                  </div>
              }
              {
                result.numEmployees &&
                  <div className={classes.score}>
                    <Typography level='body-md'>
                      <b>
                        {result.numEmployees}
                      </b>
                    </Typography>
                    <Typography level='body-xs'>
                      Employees
                    </Typography>
                  </div>
              }
              {
                hasDescription &&
                  <div className={classes.score}>
                    <CircularProgress
                      determinate
                      color='neutral'
                      size='sm'

                      value={result.descriptionSimilarity * 100}
                    >
                      <Typography level='body-xs'>
                        {Math.round(result.descriptionSimilarity * 100)}
                      </Typography>
                    </CircularProgress>
                    <Typography level='body-xs'>
                      Description
                    </Typography>
                  </div>
              }
              {
                numProductServices > 0 &&
                  <div className={classes.score}>
                    <CircularProgress
                      determinate
                      color='warning'
                      size='sm'
                      value={result.avgProductAndServiceSimilarity * 100}
                    >
                      <Typography level='body-xs'>
                        {Math.round(result.avgProductAndServiceSimilarity * 100)}
                      </Typography>
                    </CircularProgress>
                    <Typography level='body-xs'>
                      Products
                    </Typography>
                  </div>
              }
              {
                numCustomerSegments > 0 &&
                  <div className={classes.score}>
                    <CircularProgress
                      determinate
                      size='sm'
                      color='success'
                      value={result.avgCustomerSegmentSimilarity * 100}
                    >
                      <Typography level='body-xs'>
                        {Math.round(result.avgCustomerSegmentSimilarity * 100)}
                      </Typography>
                    </CircularProgress>
                    <Typography level='body-xs'>
                      Customers
                    </Typography>
                  </div>
              }
            </div>
          </div>
          <Typography level='body-sm' className={classes.description}>
            <TextWithHighlights
              text={result.description}
              hightlights={result.descriptionHighlights}
            />
          </Typography>
          <div className={classes.detailsCards}>
            <Card variant='soft' color='warning' className={classes.detailsCard}>
              <Typography level='body-xs'>
                Products / Services
              </Typography>
              <Typography level='body-sm' className={classes.description}>
                <TextWithHighlights
                  text={result.productsAndServicesInfo}
                  hightlights={result.productsAndServicesHighlights}
                />
              </Typography>
            </Card>
            <Card variant='soft' color='success' className={classes.detailsCard}>
              <Typography level='body-xs'>
                End Customers
              </Typography>
              <Typography level='body-sm' className={classes.description}>
                <TextWithHighlights
                  text={result.customerSegmentsInfo}
                  hightlights={result.customerSegmentsHighlights}
                />
              </Typography>
            </Card>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default React.memo(SearchResult);
