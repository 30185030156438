import React, { useCallback, useEffect, useState } from 'react';
import { tss } from 'tss-react';
import {
  Button,
  IconButton,
  Skeleton,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { ChevronLeft, Science } from '@mui/icons-material';
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { useNavigate, useParams } from 'react-router-dom';
import { GetAutopilotManageResearchResponse } from 'src/generated/api';
import { BusinessDrawer } from 'src/components/BusinessDrawer';
import StepsModal from 'src/components/RequirementStepsModal';
import { getAPI } from 'src/api';
import RequirementModal from './RequirementModal';
import RequirementSummaryCell from './RequirementSummaryCell';
import ContactInfoCell from './ContactInfoCell';
import RequirementResultCell from './RequirementResultCell';
import ColHeader from '../ColHeader';
import ContactInfoModal from './ContactInfoModal';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    contents: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    headerLeft: {
      display: 'flex',
      flexDirection: 'column',
    },
    tableContainer: {
      marginTop: theme.spacing(2),
      height: '75vh',
    },
    tableScroller: {
      '::-webkit-scrollbar': {
        WebkitAppearance: 'none',
        width: 12,
        height: 12,
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: 4,
        backgroundColor: 'rgba(0, 0, 0, .5)',
        boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
      },
    },
    cell: {
      wordBreak: 'break-word',
    },
    addButton: {
      marginLeft: 'auto',
      marginTop: 'auto',
      height: 32,
    },
    skeleton: {
      height: 32,
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    result: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    passResult: {
      backgroundColor: theme.palette.success.softBg,
    },
    processingResult: {
      backgroundColor: theme.palette.primary.softBg,
    },
    failResult: {
      backgroundColor: theme.palette.danger.softBg,
    },
  }));

export default function AutopilotResearch() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const { classes } = useStyles({ theme });
  const [showRequirementModal, setShowRequirementModal] = useState(false);
  const [showContactInfoModal, setShowContactInfoModal] = useState(false);
  const [businessDrawerId, setBusinessDrawerId] = React.useState<string | null>(null);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 2000,
  });
  const [list, setList] = useState<GetAutopilotManageResearchResponse | null>(null);
  const api = getAPI();
  const [requirementModalVal, setRequirementModalVal] =
    useState<GetAutopilotManageResearchResponse['requirementCols'][number] | null>(null);
  const [infoModalData, setInfoModalData] =
    useState<{
      result: GetAutopilotManageResearchResponse['businesses'][number]['requirements'][number];
      prompt: string;
    } | null>(null);
  const fetchData = useCallback(async () => {
    const listRes = await api.autopilot.getManageResearch(id!, {
      pageNum: paginationModel.page,
      pageSize: paginationModel.pageSize,
    });
    setList(listRes.data);
  }, [api, paginationModel, id]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    // Hacky workaround until websockets are added
    const handle = setInterval(() => {
      if (
        list && (
          list.businesses.some(
            (b) => b.isProcessing,
          )
        )
      ) {
        fetchData();
      }
    }, 3000);
    return () => {
      clearInterval(handle);
    };
  }, [fetchData, api, list]);
  const gridApiRef = useGridApiRef();
  const columns: GridColDef[] = [
    {
      field: 'about',
      width: 350,
      disableReorder: true,
      headerName: 'About',
      valueGetter: (data: GridValueGetterParams<GetAutopilotManageResearchResponse['businesses'][number]>) =>
        data.row.name,
      renderCell: (data) => (
        <RequirementSummaryCell
          business={data.row}
          setDrawerId={setBusinessDrawerId}
        />
      ),
      disableColumnMenu: true,
      renderHeader: () => (
        <Typography level='body-md'>
          <b>
            About
          </b>
        </Typography>
      ),
    },
    {
      field: 'calculatedTime',
      width: 150,
      disableReorder: true,
      headerName: 'Processed Date',
      valueGetter: (params: GridRenderCellParams<GetAutopilotManageResearchResponse['businesses'][number]>) =>
        (params.row as GetAutopilotManageResearchResponse['businesses'][number]).calculatedTime,
      renderCell: (data: GridRenderCellParams<GetAutopilotManageResearchResponse['businesses'][number]>) => (
        <div className={classes.result}>
          <Typography level='body-md'>
            { data.row.calculatedTime && (new Date(data.row.calculatedTime)).toDateString() }
          </Typography>
        </div>
      ),
      disableColumnMenu: true,
      renderHeader: () => (
        <Typography level='body-md'>
          <b>
            Process Date
          </b>
        </Typography>
      ),
    },
    {
      field: 'result',
      width: 200,
      disableReorder: true,
      headerName: 'Result',
      cellClassName: (params) => {
        if (params.row.isProcessing) {
          return classes.processingResult;
        }
        if (params.value) {
          return classes.passResult;
        }
        return classes.failResult;
      },
      valueGetter: (params: GridRenderCellParams<GetAutopilotManageResearchResponse['businesses'][number]>) =>
        (params.row as GetAutopilotManageResearchResponse['businesses'][number]).contactInfo?.emailAddress &&
        (params.row as GetAutopilotManageResearchResponse['businesses'][number]).requirements.map(
          (v) => v.value,
        ).reduce((a, b) => (a ?? true) && b, null),
      renderCell: (data) => {
        if (data.row.isProcessing) {
          return (
            <div className={classes.result}>
              <Typography level='body-lg'>
                Processing
              </Typography>
            </div>
          );
        }
        return (
          <div className={classes.result}>
            <Typography level='body-lg'>
              <b>
                {
                  data.value ? 'Pass' : 'Fail'
                }
              </b>
            </Typography>
          </div>
        );
      },
      disableColumnMenu: true,
      renderHeader: () => (
        <Typography level='body-md'>
          <b>
            Result
          </b>
        </Typography>
      ),
    },
    ...(list?.requirementCols
      .map((col) => ({
        field: col.id,
        width: 400,
        disableColumnMenu: true,
        headerName: col.name,
        valueGetter: (params: GridRenderCellParams<GetAutopilotManageResearchResponse['businesses'][number]>) =>
          params.row.requirements
            .find((x) => x.id === col.id)?.value,
        renderCell: (data: GridRenderCellParams<GetAutopilotManageResearchResponse['businesses'][number]>) => {
          if (data.row.isProcessing) {
            return (
              <Skeleton
                className={classes.skeleton}
                style={{
                  width: 350,
                }}
              />
            );
          }
          const val = data.row.requirements.find((x) => x.id === col.id);
          return (
            <RequirementResultCell
              onClick={() => setInfoModalData({
                result: val!,
                prompt: col.prompt,
              })}
              value={val}
            />
          );
        },
        renderHeader: () => (
          <ColHeader
            title={col.name}
            showEditModal={() => {
              setRequirementModalVal(col);
              setShowRequirementModal(true);
            }}
            onDelete={async () => {
              await api.autopilot.deleteRequirement(id!, col.id);
              fetchData();
            }}
          />
        ),
      })) ?? []),
    {
      field: 'contactInfo',
      width: 250,
      disableReorder: true,
      headerName: 'Has Contact Info',
      disableColumnMenu: true,
      valueGetter: (params: GridRenderCellParams<GetAutopilotManageResearchResponse['businesses'][number]>) =>
        params.row.contactInfo?.emailAddress,
      renderCell: (data: GridRenderCellParams<GetAutopilotManageResearchResponse['businesses'][number]>) => {
        if (data.row.isProcessing) {
          return (
            <Skeleton
              className={classes.skeleton}
              style={{
                width: 200,
              }}
            />
          );
        }
        return (
          <ContactInfoCell
            value={data.row.contactInfo}
          />
        );
      },
      renderHeader: () => (
        <ColHeader
          title='Contact Info'
          showEditModal={() => {
            setShowContactInfoModal(true);
          }}
        />
      ),
    },
  ];
  return (
    <div className={classes.container}>
      <div className={classes.contents}>
        <div className={classes.header}>
          <IconButton onClick={() => navigate(`/autopilot/${id}/manage`)}>
            <ChevronLeft />
          </IconButton>
          <div className={classes.headerLeft}>
            <Typography level="h3" className={classes.title}>
              <Science />
              Research
            </Typography>
            <Typography level='body-lg'>
              Businesses that have been researched by our AI to ensure criteria fit
            </Typography>
          </div>
          <Button
            color='success'
            variant='solid'
            className={classes.addButton}
            onClick={() => setShowRequirementModal(true)}
          >
            Add Requirement
          </Button>
        </div>
        <div className={classes.tableContainer}>
          <DataGridPro
            pagination
            apiRef={gridApiRef}
            disableRowSelectionOnClick
            rowCount={list?.size}
            loading={list == null}
            paginationMode='server'
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            getRowHeight={() => 'auto'}
            showCellVerticalBorder
            pageSizeOptions={[2000]}
            showColumnVerticalBorder
            classes={{
              virtualScroller: classes.tableScroller,
            }}
            pinnedColumns={{
              left: ['about'],
            }}
            columnHeaderHeight={32}
            columns={columns}
            rows={list?.businesses ?? []}
          />
        </div>
        <RequirementModal
          campaignId={id!}
          open={showRequirementModal}
          req={requirementModalVal}
          onClose={() => {
            setShowRequirementModal(false);
            setRequirementModalVal(null);
            fetchData();
          }}
        />
        {
          list &&
            <ContactInfoModal
              currentJobTitles={list.jobTitles}
              campaignId={id!}
              onClose={() => setShowContactInfoModal(false)}
              open={showContactInfoModal}
            />
        }
      </div>
      {
        infoModalData &&
          <StepsModal
            value={infoModalData.result.value}
            explanation={infoModalData.result.explanation!}
            steps={infoModalData.result.steps!}
            prompt={infoModalData.prompt}
            onClose={() => setInfoModalData(null)}
          />
      }
      {
        list &&
          <BusinessDrawer
            initialBusinessId={businessDrawerId}
            onClose={() => setBusinessDrawerId(null)}
          />
      }
    </div>
  );
}
