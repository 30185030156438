import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { tss } from 'tss-react';
import {
  Button,
  IconButton,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { AccountTree, ChevronLeft } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { BusinessDrawer } from 'src/components/BusinessDrawer';
import { GetAutopilotManagePipelineResponse, TargetStatus } from 'src/generated/api';
import { getAPI } from 'src/api';
import { SnackbarContext } from 'src/contexts/snackbar';
import AboutCell from '../../Lists/Cells/AboutCell';
import SimpleCell from '../../Lists/SimpleCell';
import FinancialCell from '../../Lists/Cells/FinancialCell';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    contents: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    headerLeft: {
      display: 'flex',
      flexDirection: 'column',
    },
    tableContainer: {
      marginTop: theme.spacing(2),
      height: '75vh',
    },
    tableScroller: {
      '::-webkit-scrollbar': {
        WebkitAppearance: 'none',
        width: 12,
        height: 12,
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: 4,
        backgroundColor: 'rgba(0, 0, 0, .5)',
        boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
      },
    },
    cell: {
      wordBreak: 'break-word',
    },
    actionRow: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 'auto',
      marginTop: 'auto',
      gap: theme.spacing(1),
      alignItems: 'flex-end',
    },
    statusCell: {
      padding: '0 !important',
    },
    statusContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));

export default function AutopilotPipeline() {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const { id } = useParams();
  const navigate = useNavigate();
  const targetStatusVals: Record<TargetStatus,
  { label: string; bgColor: string; order: number }> = {
    NOT_STARTED: {
      label: 'Not Started',
      bgColor: theme.palette.neutral.softBg,
      order: 0,
    },
    PROCESSING: {
      label: 'Processing',
      bgColor: theme.palette.primary.softBg,
      order: 0,
    },
    FAILED_REQ: {
      label: 'Failed Requirements',
      bgColor: theme.palette.danger.softBg,
      order: 1,
    },
    NO_CONTACT_INFO: {
      label: 'No Contact Info',
      bgColor: theme.palette.danger.softBg,
      order: 2,
    },
    WAITING_FOR_APPROVAL: {
      label: 'Waiting for Approval',
      bgColor: theme.palette.warning.softBg,
      order: 3,
    },
    APPROVED: {
      label: 'Approved',
      bgColor: theme.palette.success.softBg,
      order: 4,
    },
    SEQUENCE_STARTED: {
      label: 'Running',
      bgColor: theme.palette.success.softBg,
      order: 5,
    },
    SEQUENCE_COMPLETED: {
      label: 'Completed',
      bgColor: theme.palette.success.softBg,
      order: 6,
    },
    REPLIED: {
      label: 'Replied',
      bgColor: theme.palette.success.softBg,
      order: 7,
    },
  };
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 2000,
  });
  const { showSnackbar } = useContext(SnackbarContext);
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [businessDrawerId, setBusinessDrawerId] = React.useState<string | null>(null);
  const api = getAPI();
  const [list, setList] = useState<GetAutopilotManagePipelineResponse | null>(null);
  const fetchData = useCallback(async () => {
    const listRes = await api.autopilot.getManagePipeline(id!, {
      pageNum: paginationModel.page,
      pageSize: paginationModel.pageSize,
    });
    setList(listRes.data);
  }, [api, paginationModel, id]);
  const removeRows = useCallback(async () => {
    await api.autopilot.removeFromAutopilot(id!, {
      businessIds: rowSelectionModel as string[],
    });
    await fetchData();
    setRowSelectionModel([]);
  }, [api, rowSelectionModel, id, fetchData]);
  const runRows = useCallback(async () => {
    api.autopilot.runSequences(id!, {
      businessIds: rowSelectionModel as string[],
    });
    showSnackbar({
      message: `Processing ${rowSelectionModel.length} businesses...`,
      color: 'success',
    });
    setRowSelectionModel([]);
    await fetchData();
  }, [api, rowSelectionModel, id, showSnackbar, fetchData]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    // Hacky workaround until websockets are added
    const handle = setInterval(() => {
      if (
        list && (
          list.businesses.some(
            (b) => b.status === 'PROCESSING',
          )
        )
      ) {
        fetchData();
      }
    }, 3000);
    return () => {
      clearInterval(handle);
    };
  }, [fetchData, api, list]);
  const columns: GridColDef[] = [{
    field: 'about',
    width: 350,
    disableReorder: true,
    headerName: 'About',
    valueGetter: (data: GridValueGetterParams<GetAutopilotManagePipelineResponse['businesses'][number]>) =>
      data.row.name,
    renderCell: (data) => (
      <AboutCell
        business={data.row}
        setDrawerId={setBusinessDrawerId}
      />
    ),
    disableColumnMenu: true,
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          About
        </b>
      </Typography>
    ),
  }, {
    field: 'status',
    width: 180,
    disableColumnMenu: true,
    headerName: 'Status',
    cellClassName: classes.statusCell,
    renderCell: (data: GridRenderCellParams<GetAutopilotManagePipelineResponse['businesses'][number]>) => (
      <div
        className={classes.statusContainer}
        style={{
          backgroundColor: targetStatusVals[data.row.status].bgColor,
        }}
      >
        <Typography level='body-sm'>
          <b>
            {targetStatusVals[data.row.status].label}
          </b>
        </Typography>
      </div>
    ),
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          Status
        </b>
      </Typography>
    ),
  }, {
    field: 'description',
    width: 800,
    disableColumnMenu: true,
    headerName: 'Description',
    cellClassName: classes.cell,
    renderCell: (data) => (
      <SimpleCell
        value={data.value}
      />
    ),
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          Description
        </b>
      </Typography>
    ),
  }, {
    field: 'city',
    width: 120,
    disableColumnMenu: true,
    headerName: 'City',
    cellClassName: classes.cell,
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          HQ City
        </b>
      </Typography>
    ),
  }, {
    field: 'state',
    width: 120,
    disableColumnMenu: true,
    headerName: 'HQ State',
    cellClassName: classes.cell,
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          HQ State
        </b>
      </Typography>
    ),
  }, {
    field: 'revenue',
    width: 120,
    disableColumnMenu: true,
    headerName: 'Revenue',
    renderCell: (data) => (
      <FinancialCell
        num={data.row.revenue}
      />
    ),
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          Revenue
        </b>
      </Typography>
    ),
  }];
  const gridApiRef = useGridApiRef();
  return (
    <div className={classes.container}>
      <div className={classes.contents}>
        <div className={classes.header}>
          <IconButton onClick={() => navigate(`/autopilot/${id}/manage`)}>
            <ChevronLeft />
          </IconButton>
          <div className={classes.headerLeft}>
            <Typography level="h3" className={classes.title}>
              <AccountTree />
              Pipeline
            </Typography>
            <Typography level='body-lg'>
              All the businesses that have been added to this autopilot campaign
            </Typography>
          </div>
          <div className={classes.actionRow}>
            {
              rowSelectionModel.length > 0 &&
                <>
                  <Button
                    color='danger'
                    onClick={removeRows}
                  >
                    Remove ({rowSelectionModel.length})
                  </Button>
                  <Button
                    color='success'
                    onClick={runRows}
                  >
                    Run Pipeline ({rowSelectionModel.length})
                  </Button>
                </>
            }
          </div>
        </div>
        <div className={classes.tableContainer}>
          <DataGridPro
            pagination
            checkboxSelection
            apiRef={gridApiRef}
            isRowSelectable={
              (params: GridRowParams<GetAutopilotManagePipelineResponse['businesses'][number]>) =>
                !(['SEQUENCE_COMPLETED', 'SEQUENCE_STARTED'].includes(params.row.status))
            }
            disableRowSelectionOnClick
            rowCount={list?.size}
            loading={list == null}
            paginationMode='server'
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={setRowSelectionModel}
            getRowHeight={() => 'auto'}
            showCellVerticalBorder
            pageSizeOptions={[2000]}
            showColumnVerticalBorder
            classes={{
              virtualScroller: classes.tableScroller,
            }}
            pinnedColumns={{
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'about'],
            }}
            columnHeaderHeight={32}
            columns={columns}
            rows={list?.businesses ?? []}
          />
        </div>
      </div>
      {
        list &&
          <BusinessDrawer
            initialBusinessId={businessDrawerId}
            onClose={() => setBusinessDrawerId(null)}
          />
      }
    </div>
  );
}
