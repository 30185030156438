import React, { useEffect } from 'react';
import { tss } from 'tss-react';
import {
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { getAPI } from 'src/api';
import { logEvent } from 'src/analytics';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      width: 450,
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
  }));

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function SubscriptionModal({
  open,
  onClose,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const api = getAPI();
  useEffect(() => {
    if (open) {
      logEvent({
        name: 'export_paywall_show',
        type: 'impression',
      });
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <ModalDialog className={classes.container}>
        <ModalClose />
        <Typography level='h4'>
          Subscribe to OffDeal
        </Typography>
        <Typography level='body-md'>
          To enable exporting, you need to first subscribe
          to OffDeal. Your subscription comes with 1000 monthly
          credits for enrichments.
        </Typography>
        <Button
          color='success'
          onClick={async () => {
            const res = await api.billing.createCheckboutSessions();
            window.location.href = res.data.checkoutUrl;
          }}
        >
          Subscribe
        </Button>
      </ModalDialog>
    </Modal>
  );
}
