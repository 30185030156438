import React from 'react';
import { GetListResponse } from 'src/generated/api';
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import EnrichmentHeader, { EnrichmentColProps } from './Header';
import EnrichmentCellWrapper from './Wrapper';
import SimpleCell from '../SimpleCell';

export function getColumns(
  enrichmentColProps: EnrichmentColProps,
  getValue: (enrichmentValues: GetListResponse['businesses'][number]['enrichmentValues'][number] | null) => string | null,
) {
  return [{
    field: enrichmentColProps.col.id,
    width: enrichmentColProps.columnWidths[enrichmentColProps.col.id] ?? 300,
    headerName: enrichmentColProps.col.name,
    sortable: true,
    disableColumnMenu: true,
    valueGetter: (data: GridValueGetterParams<GetListResponse['businesses'][number]>) => {
      const val = data.row.enrichmentValues.find((x) => x.colId === enrichmentColProps.col.id);
      return val?.simplifiedName?.result;
    },
    renderCell: (data: GridRenderCellParams<GetListResponse['businesses'][number]>) => {
      const val = data.row.enrichmentValues.find((x) => x.colId === enrichmentColProps.col.id);
      return (
        <EnrichmentCellWrapper
          enrichmentColProps={enrichmentColProps}
          businessId={data.row.id}
          value={val}
        >
          <SimpleCell
            value={getValue(val ?? null)}
          />
        </EnrichmentCellWrapper>
      );
    },
    renderHeader: () => (
      <EnrichmentHeader
        {...enrichmentColProps}
      />
    ),
  }];
}
