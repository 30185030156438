import React, { useContext, useState, useCallback } from 'react';
import { tss } from 'tss-react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Select,
  Theme,
  Typography,
  useTheme,
  Option,
  Card,
  Textarea,
  IconButton,
  Autocomplete,
  Chip,
  ChipDelete,
} from '@mui/joy';
import { getAPI } from 'src/api';
import {
  Type24EnumsDayOfWeek,
} from 'src/generated/api';
import { AuthContext } from 'src/contexts/auth';
import TimeSelect from 'src/components/TimeSelect';
import {
  Delete,
  Edit,
  Email,
  RocketLaunch,
  Science,
  Send,
  Settings,
} from '@mui/icons-material';
import RequirementModal, { Requirement } from './RequirementModal';
import EmailModal, { EmailCol } from './EmailModal';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    contents: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      gap: theme.spacing(4),
      maxWidth: 700,
    },
    general: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      boxShadow: theme.shadow.lg,
      padding: theme.spacing(2),
      gap: theme.spacing(2),
      borderRadius: theme.spacing(2),
    },
    generalRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(2),
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    numInput: {
      width: 80,
    },
    windowInput: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
    },
    runOnField: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    runOnOptions: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
      maxWidth: '75%',
      justifyContent: 'flex-end',
    },
    dayButton: {
      width: 56,
    },
    selectedDay: {
      // So the size doesnt change from outlined -> solid
      paddingTop: 7,
      paddingBottom: 7,
    },
    research: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      padding: theme.spacing(2),
      borderRadius: theme.spacing(2),
      boxShadow: theme.shadow.lg,
    },
    headerButtons: {
      marginLeft: 'auto',
      marginTop: 'auto',
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    requirementHeader: {
      display: 'flex',
      flexDirection: 'row',
    },
    requirement: {
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
    },
    icon: {
      width: 20,
      height: 20,
    },
    emailSettings: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      padding: theme.spacing(2),
      borderRadius: theme.spacing(2),
      boxShadow: theme.shadow.lg,
    },
    sectionTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 4,
    },
    nameRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    emailSequence: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      padding: theme.spacing(2),
      borderRadius: theme.spacing(2),
      boxShadow: theme.shadow.lg,
    },
    emailCard: {
      whiteSpace: 'pre-line',
    },
    closeButton: {
      width: 20,
      height: 20,
      minHeight: 0,
      minWidth: 0,
      marginLeft: 'auto',
    },
    closeIcon: {
      width: 16,
      height: 16,
    },
    formLabel: {
      width: '100%',
    },
    stringField: {
      maxWidth: 300,
    },
  }));

type GeneralConfig = {
  name: string;
  maxBusinessReviewedPerDay: number;
  maxSuccessPerDay: number;
  daysActive: Type24EnumsDayOfWeek[];
  timeWindowTimeZone: string;
  timeWindowEndHour: number;
  timeWindowStartHour: number;
  autoApprove: boolean;
  sendNewEmails: boolean;
  processNewBusinesses: boolean;
};

type ResearchConfig = {
  requirements: Requirement[];
};

type EngageConfig = {
  subjectInstruction: string;
  senderEmailAddress: string;
  senderLastName: string;
  senderFirstName: string;
  signature: string;
  jobTitles: string[];
  emailCols: EmailCol[];
};

export default function AutopilotCreate() {
  const theme = useTheme();
  const { classes, cx } = useStyles({ theme });
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [generalConfig, setGeneralConfig] = useState<GeneralConfig>({
    name: '',
    maxBusinessReviewedPerDay: 50,
    maxSuccessPerDay: 10,
    daysActive: [
      Type24EnumsDayOfWeek.MONDAY,
      Type24EnumsDayOfWeek.TUESDAY,
      Type24EnumsDayOfWeek.WEDNESDAY,
      Type24EnumsDayOfWeek.THURSDAY,
      Type24EnumsDayOfWeek.FRIDAY,
    ],
    timeWindowTimeZone: 'America/New_York',
    timeWindowEndHour: 18,
    timeWindowStartHour: 9,
    autoApprove: false,
    sendNewEmails: false,
    processNewBusinesses: false,
  });
  const [requirementModalData, setRequirementModalData] = useState<{
    val: Requirement | null,
    idx: number,
  } | null>(null);
  const [emailModalData, setEmailModalData] = useState<{
    val: EmailCol | null,
    idx: number,
  } | null>(null);
  const [researchConfig, setResearchConfig] = useState<ResearchConfig>({
    requirements: [],
  });
  const [engageConfig, setEngageConfig] = useState<EngageConfig>({
    subjectInstruction: `Use this format: [Business Owner Name] <> ${user?.firstName} Intro`,
    // TODO: This should be set internally
    senderEmailAddress: '',
    senderFirstName: user?.firstName ?? '',
    senderLastName: user?.lastName ?? '',
    signature: '',
    jobTitles: [
      'CEO',
      'Chief Executive Officer',
      'Owner',
      'President',
      'Chairman',
    ],
    emailCols: [],
  });
  const api = getAPI();
  const onCreate = useCallback(async () => {
    const res = await api.autopilot.createAutopilot({
      name: generalConfig.name,
      requirements: researchConfig.requirements.map((r) => ({
        name: r.name,
        prompt: r.prompt,
        ifNotFound: r.ifNotFound,
        sources: [...r.dataSources],
      })),
      emails: engageConfig.emailCols,
      signature: engageConfig.signature,
      subjectInstruction: engageConfig.subjectInstruction,
      firstName: engageConfig.senderFirstName,
      lastName: engageConfig.senderLastName,
      emailAddress: engageConfig.senderEmailAddress,
      jobTitles: engageConfig.jobTitles,
      processNewBusinesses: generalConfig.processNewBusinesses,
      sendNewEmails: generalConfig.sendNewEmails,
      autoApprove: generalConfig.autoApprove,
      timeWindowStartHour: generalConfig.timeWindowStartHour,
      timeWindowEndHour: generalConfig.timeWindowEndHour,
      timeWindowTimeZone: generalConfig.timeWindowTimeZone,
      daysActive: generalConfig.daysActive,
      maxSuccessPerDay: generalConfig.maxSuccessPerDay,
      maxBusinessReviewedPerDay: generalConfig.maxBusinessReviewedPerDay,
    });
    navigate(`/autopilot/${res.data.id}`);
  }, [api, generalConfig, engageConfig, researchConfig, navigate]);

  return (
    <div className={classes.container}>
      <div className={classes.contents}>
        <div className={classes.header}>
          <Typography level='h3' className={classes.sectionTitle}>
            <RocketLaunch />
            Create Autopilot
          </Typography>
        </div>
        <div className={classes.general}>
          <Typography level='h4' className={classes.sectionTitle}>
            <Settings />
            General
          </Typography>
          <div className={classes.generalRow}>
            <Typography level='body-md'>
              <b>
                Campaign Name
              </b>
            </Typography>
            <Input
              size='lg'
              value={generalConfig.name}
              className={classes.stringField}
              onChange={(e) => {
                setGeneralConfig((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
            />
          </div>
          <div className={classes.generalRow}>
            <Typography level='body-md'>
              <b>
                Find More Matches
              </b>
            </Typography>
            <ButtonGroup>
              <Button
                color='success'
                variant={generalConfig.processNewBusinesses ? 'solid' : 'outlined'}
                onClick={() => setGeneralConfig((prev) => ({
                  ...prev!,
                  processNewBusinesses: true,
                }))}
              >
                On
              </Button>
              <Button
                color='neutral'
                variant={generalConfig.processNewBusinesses ? 'outlined' : 'solid'}
                onClick={() => setGeneralConfig((prev) => ({
                  ...prev!,
                  processNewBusinesses: false,
                }))}
              >
                Off
              </Button>
            </ButtonGroup>
          </div>
          <div className={classes.generalRow}>
            <Typography level='body-md'>
              <b>
                Automatically Send Emails
              </b>
            </Typography>
            <ButtonGroup>
              <Button
                color='success'
                variant={generalConfig.sendNewEmails ? 'solid' : 'outlined'}
                onClick={() => setGeneralConfig((prev) => ({
                  ...prev!,
                  sendNewEmails: true,
                }))}
              >
                On
              </Button>
              <Button
                color='neutral'
                variant={generalConfig.sendNewEmails ? 'outlined' : 'solid'}
                onClick={() => setGeneralConfig((prev) => ({
                  ...prev!,
                  sendNewEmails: false,
                }))}
              >
                Off
              </Button>
            </ButtonGroup>
          </div>
          <div className={classes.generalRow}>
            <Typography level='body-md'>
              <b>
                Auto-approve Matches
              </b>
            </Typography>
            <ButtonGroup>
              <Button
                color='success'
                variant={generalConfig.autoApprove ? 'solid' : 'outlined'}
                onClick={() => setGeneralConfig((prev) => ({
                  ...prev!,
                  autoApprove: true,
                }))}
              >
                On
              </Button>
              <Button
                color='neutral'
                variant={generalConfig.autoApprove ? 'outlined' : 'solid'}
                onClick={() => setGeneralConfig((prev) => ({
                  ...prev!,
                  autoApprove: false,
                }))}
              >
                Off
              </Button>
            </ButtonGroup>
          </div>
          <div className={classes.generalRow}>
            <Typography level='body-md'>
              <b>
                Daily Criteria Matches
              </b>
            </Typography>
            <Input
              type='number'
              size='md'
              className={classes.numInput}
              value={generalConfig.maxSuccessPerDay ?? 0}
              onChange={(e) => {
                setGeneralConfig((prev) => ({
                  ...prev!,
                  maxSuccessPerDay: parseInt(e.target.value, 10),
                }));
              }}
            />
          </div>
          <div className={classes.generalRow}>
            <Typography level='body-md'>
              <b>
                Max Daily Researched
              </b>
            </Typography>
            <Input
              type='number'
              size='md'
              className={classes.numInput}
              value={generalConfig.maxBusinessReviewedPerDay ?? 0}
              onChange={(e) => {
                setGeneralConfig((prev) => ({
                  ...prev!,
                  maxBusinessReviewedPerDay: parseInt(e.target.value, 10),
                }));
              }}
            />
          </div>
          <div className={classes.generalRow}>
            <Typography level='body-md'>
              <b>
                Email Window
              </b>
            </Typography>
            <div className={classes.windowInput}>
              <TimeSelect
                value={generalConfig.timeWindowStartHour ?? 8}
                setValue={(val) => setGeneralConfig((prev) => ({
                  ...prev!,
                  timeWindowStartHour: val,
                }))}
              />
              <TimeSelect
                value={generalConfig.timeWindowEndHour ?? 18}
                setValue={(val) => setGeneralConfig((prev) => ({
                  ...prev!,
                  timeWindowEndHour: val,
                }))}
              />
            </div>
          </div>
          <div className={classes.generalRow}>
            <Typography level='body-md'>
              <b>
                Time Zone
              </b>
            </Typography>
            <Select
              value={generalConfig.timeWindowTimeZone ?? ''}
              onChange={(e, val) => setGeneralConfig((prev) => ({
                ...prev!,
                timeWindowTimeZone: val!,
              }))}
            >
              {
                // @ts-expect-error This actually exists
                Intl.supportedValuesOf('timeZone')
                  .map((val: string) => (
                    <Option value={val}>
                      {val} ({
                        new Intl.DateTimeFormat(
                          'en',
                          {
                            timeZone: val,
                            timeZoneName: 'shortOffset',
                          },
                        ).formatToParts()
                          .find((part) => part.type === 'timeZoneName')!
                          .value
                      })
                    </Option>
                  ))
              }
            </Select>
          </div>
          <div className={classes.generalRow}>
            <Typography level='body-md'>
              <b>
                Run On
              </b>
            </Typography>
            <div className={classes.runOnOptions}>
              <Button
                size='sm'
                color='primary'
                className={
                  cx(
                    classes.dayButton,
                    {
                      [classes.selectedDay]: generalConfig.daysActive
                        .includes(Type24EnumsDayOfWeek.SUNDAY),
                    },
                  )
                }
                variant={
                  generalConfig.daysActive.includes(Type24EnumsDayOfWeek.SUNDAY) ? 'solid' : 'outlined'
                }
                onClick={() => {
                  if (generalConfig.daysActive.includes(Type24EnumsDayOfWeek.SUNDAY)) {
                    setGeneralConfig((prev) => ({
                      ...prev!,
                      daysActive: prev!.daysActive.filter(
                        (d) => d !== Type24EnumsDayOfWeek.SUNDAY,
                      ),
                    }));
                  } else {
                    setGeneralConfig((prev) => ({
                      ...prev!,
                      daysActive: [
                        ...prev!.daysActive,
                        Type24EnumsDayOfWeek.SUNDAY,
                      ],
                    }));
                  }
                }}
              >
                Sun
              </Button>
              <Button
                color='primary'
                variant={
                  generalConfig.daysActive.includes(Type24EnumsDayOfWeek.MONDAY) ? 'solid' : 'outlined'
                }
                className={
                  cx(
                    classes.dayButton,
                    {
                      [classes.selectedDay]: generalConfig.daysActive
                        .includes(Type24EnumsDayOfWeek.MONDAY),
                    },
                  )
                }
                onClick={() => {
                  if (generalConfig.daysActive.includes(Type24EnumsDayOfWeek.MONDAY)) {
                    setGeneralConfig((prev) => ({
                      ...prev!,
                      daysActive: prev!.daysActive.filter(
                        (d) => d !== Type24EnumsDayOfWeek.MONDAY,
                      ),
                    }));
                  } else {
                    setGeneralConfig((prev) => ({
                      ...prev!,
                      daysActive: [
                        ...prev!.daysActive,
                        Type24EnumsDayOfWeek.MONDAY,
                      ],
                    }));
                  }
                }}
              >
                Mon
              </Button>
              <Button
                color='primary'
                variant={
                  generalConfig.daysActive.includes(Type24EnumsDayOfWeek.TUESDAY) ? 'solid' : 'outlined'
                }
                className={
                  cx(
                    classes.dayButton,
                    {
                      [classes.selectedDay]: generalConfig.daysActive
                        .includes(Type24EnumsDayOfWeek.TUESDAY),
                    },
                  )
                }
                onClick={() => {
                  if (generalConfig.daysActive.includes(Type24EnumsDayOfWeek.TUESDAY)) {
                    setGeneralConfig((prev) => ({
                      ...prev!,
                      daysActive: prev!.daysActive.filter(
                        (d) => d !== Type24EnumsDayOfWeek.TUESDAY,
                      ),
                    }));
                  } else {
                    setGeneralConfig((prev) => ({
                      ...prev!,
                      daysActive: [
                        ...prev!.daysActive,
                        Type24EnumsDayOfWeek.TUESDAY,
                      ],
                    }));
                  }
                }}
              >
                Tue
              </Button>
              <Button
                color='primary'
                variant={
                  generalConfig.daysActive.includes(Type24EnumsDayOfWeek.WEDNESDAY) ? 'solid' : 'outlined'
                }
                className={
                  cx(
                    classes.dayButton,
                    {
                      [classes.selectedDay]: generalConfig.daysActive
                        .includes(Type24EnumsDayOfWeek.WEDNESDAY),
                    },
                  )
                }
                onClick={() => {
                  if (generalConfig.daysActive.includes(Type24EnumsDayOfWeek.WEDNESDAY)) {
                    setGeneralConfig((prev) => ({
                      ...prev!,
                      daysActive: prev!.daysActive.filter(
                        (d) => d !== Type24EnumsDayOfWeek.WEDNESDAY,
                      ),
                    }));
                  } else {
                    setGeneralConfig((prev) => ({
                      ...prev!,
                      daysActive: [
                        ...prev!.daysActive,
                        Type24EnumsDayOfWeek.WEDNESDAY,
                      ],
                    }));
                  }
                }}
              >
                Wed
              </Button>
              <Button
                color='primary'
                variant={
                  generalConfig.daysActive.includes(Type24EnumsDayOfWeek.THURSDAY) ? 'solid' : 'outlined'
                }
                className={
                  cx(
                    classes.dayButton,
                    {
                      [classes.selectedDay]: generalConfig.daysActive
                        .includes(Type24EnumsDayOfWeek.THURSDAY),
                    },
                  )
                }
                onClick={() => {
                  if (generalConfig.daysActive.includes(Type24EnumsDayOfWeek.THURSDAY)) {
                    setGeneralConfig((prev) => ({
                      ...prev!,
                      daysActive: prev!.daysActive.filter(
                        (d) => d !== Type24EnumsDayOfWeek.THURSDAY,
                      ),
                    }));
                  } else {
                    setGeneralConfig((prev) => ({
                      ...prev!,
                      daysActive: [
                        ...prev!.daysActive,
                        Type24EnumsDayOfWeek.THURSDAY,
                      ],
                    }));
                  }
                }}
              >
                Thu
              </Button>
              <Button
                color='primary'
                variant={
                  generalConfig.daysActive.includes(Type24EnumsDayOfWeek.FRIDAY) ? 'solid' : 'outlined'
                }
                className={
                  cx(
                    classes.dayButton,
                    {
                      [classes.selectedDay]: generalConfig.daysActive
                        .includes(Type24EnumsDayOfWeek.FRIDAY),
                    },
                  )
                }
                onClick={() => {
                  if (generalConfig.daysActive.includes(Type24EnumsDayOfWeek.FRIDAY)) {
                    setGeneralConfig((prev) => ({
                      ...prev!,
                      daysActive: prev!.daysActive.filter(
                        (d) => d !== Type24EnumsDayOfWeek.FRIDAY,
                      ),
                    }));
                  } else {
                    setGeneralConfig((prev) => ({
                      ...prev!,
                      daysActive: [
                        ...prev!.daysActive,
                        Type24EnumsDayOfWeek.FRIDAY,
                      ],
                    }));
                  }
                }}
              >
                Fri
              </Button>
              <Button
                color='primary'
                variant={
                  generalConfig.daysActive.includes(Type24EnumsDayOfWeek.SATURDAY) ? 'solid' : 'outlined'
                }
                className={
                  cx(
                    classes.dayButton,
                    {
                      [classes.selectedDay]: generalConfig.daysActive
                        .includes(Type24EnumsDayOfWeek.SATURDAY),
                    },
                  )
                }
                onClick={() => {
                  if (generalConfig.daysActive.includes(Type24EnumsDayOfWeek.SATURDAY)) {
                    setGeneralConfig((prev) => ({
                      ...prev!,
                      daysActive: prev!.daysActive.filter(
                        (d) => d !== Type24EnumsDayOfWeek.SATURDAY,
                      ),
                    }));
                  } else {
                    setGeneralConfig((prev) => ({
                      ...prev!,
                      daysActive: [
                        ...prev!.daysActive,
                        Type24EnumsDayOfWeek.SATURDAY,
                      ],
                    }));
                  }
                }}
              >
                Sat
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.research}>
          <div className={classes.row}>
            <Typography level='h4' className={classes.sectionTitle}>
              <Science />
              Research
            </Typography>
            <Button
              color='success'
              variant='solid'
              onClick={() => setRequirementModalData({
                val: null,
                idx: researchConfig.requirements.length,
              })}
            >
              Add
            </Button>
          </div>
          {
            researchConfig.requirements.map((r, idx) => (
              <Card className={classes.requirement} key={idx}>
                <div className={classes.requirementHeader}>
                  <Typography level='body-lg'>
                    <b>{r.name}</b>
                  </Typography>
                  <div className={classes.headerButtons}>
                    <IconButton
                      color='primary'
                      variant='outlined'
                      onClick={() => setRequirementModalData({
                        val: r,
                        idx,
                      })}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color='danger'
                      variant='outlined'
                      onClick={() => setResearchConfig((prev) => {
                        const newVal = { ...prev };
                        newVal.requirements.splice(idx, 1);
                        return newVal;
                      })}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                </div>
                <Typography level='body-md'>
                  <i>
                    {r.prompt}
                  </i>
                </Typography>
              </Card>
            ))
          }
          {
            researchConfig.requirements.length === 0 &&
              <Card color='danger' variant='soft'>
                At least one requirement must be added
              </Card>
          }
        </div>
        <div className={classes.emailSettings}>
          <Typography level='h4' className={classes.sectionTitle}>
            <Email />
            Email Settings
          </Typography>
          <div className={classes.nameRow}>
            <FormControl>
              <FormLabel>
                First Name
              </FormLabel>
              <Input
                className={classes.stringField}
                value={engageConfig.senderFirstName}
                onChange={(e) => setEngageConfig((prev) => ({
                  ...prev,
                  senderFirstName: e.target.value,
                }))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>
                Last Name
              </FormLabel>
              <Input
                className={classes.stringField}
                value={engageConfig.senderLastName}
                onChange={(e) => setEngageConfig((prev) => ({
                  ...prev,
                  senderLastName: e.target.value,
                }))}
              />
            </FormControl>
          </div>
          <FormControl>
            <FormLabel>
              Email Address
            </FormLabel>
            <Input
              value={engageConfig.senderEmailAddress}
              onChange={(e) => setEngageConfig((prev) => ({
                ...prev,
                senderEmailAddress: e.target.value,
              }))}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Subject Instruction
            </FormLabel>
            <Textarea
              minRows={2}
              value={engageConfig.subjectInstruction}
              onChange={(e) => setEngageConfig((prev) => ({
                ...prev,
                subjectInstruction: e.target.value,
              }))}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Signature
            </FormLabel>
            <Textarea
              minRows={3}
              value={engageConfig.signature}
              onChange={(e) => setEngageConfig((prev) => ({
                ...prev,
                signature: e.target.value,
              }))}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Job Title
            </FormLabel>
            <Autocomplete
              multiple
              freeSolo
              autoSelect
              placeholder='CEO, Owner, VP of Engineering'
              value={engageConfig.jobTitles}
              onChange={(e, val) => setEngageConfig((prev) => ({
                ...prev,
                jobTitles: val,
              }))}
              onInputChange={(e, newVal) => {
                if (newVal.endsWith(',')) {
                  (e.target as HTMLElement).blur();
                  (e.target as HTMLElement).focus();
                }
              }}
              renderTags={(tags, getTagProps) =>
                tags.map((item, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    color='primary'
                    onClick={undefined}
                    endDecorator={<ChipDelete
                      onDelete={getTagProps({ index }).onClick}
                    />}
                  >
                    {item}
                  </Chip>
                ))
              }
              options={[]}
            />
          </FormControl>
        </div>
        <div className={classes.emailSequence}>
          <div className={classes.row}>
            <Typography level='h4' className={classes.sectionTitle}>
              <Send />
              <b>Email Sequence</b>
            </Typography>
            <Button
              color='success'
              variant='solid'
              onClick={() => setEmailModalData({
                val: null,
                idx: engageConfig.emailCols.length,
              })}
            >
              Add
            </Button>
          </div>
          {
            engageConfig.emailCols
              .sort((a, b) => a.waitForDays - b.waitForDays)
              .map((col, idx) => (
                <Card className={classes.emailCard} key={idx}>
                  <div className={classes.row}>
                    <Typography level='body-lg'>
                      <b>Day {col.waitForDays + 1}</b>
                    </Typography>
                    <div className={classes.headerButtons}>
                      <IconButton
                        color='primary'
                        variant='outlined'
                        onClick={() => setEmailModalData({
                          val: col,
                          idx,
                        })}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        color='danger'
                        variant='outlined'
                        onClick={() => setEngageConfig((prev) => {
                          const newVal = { ...prev };
                          newVal.emailCols.splice(idx, 1);
                          return newVal;
                        })}
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  </div>
                  <Typography level='body-md'>
                    {col.instruction}
                  </Typography>
                </Card>
              ))
          }
          {
            engageConfig.emailCols.length === 0 &&
              <Card color='danger' variant='soft'>
                At least one email must be added to the sequence
              </Card>
          }
        </div>
        <Button color='success' onClick={onCreate}>
          Create
        </Button>
      </div>
      {
        requirementModalData &&
          <RequirementModal
            req={requirementModalData.val}
            onClose={(req) => {
              if (req) {
                setResearchConfig((prev) => {
                  const newVal = { ...prev };
                  newVal.requirements[requirementModalData.idx] = req;
                  return newVal;
                });
              }
              setRequirementModalData(null);
            }}
          />
      }
      {
        emailModalData &&
          <EmailModal
            emailCol={emailModalData.val}
            onClose={(col) => {
              if (col) {
                setEngageConfig((prev) => {
                  const newVal = { ...prev };
                  newVal.emailCols[emailModalData.idx] = col;
                  return newVal;
                });
              }
              setEmailModalData(null);
            }}
          />
      }
    </div>
  );
}
