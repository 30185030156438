import React from 'react';
import { GetListResponse } from 'src/generated/api';
import { Typography } from '@mui/joy';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import EnrichmentHeader, { EnrichmentColProps } from './Header';
import EnrichmentCellWrapper from './Wrapper';

export function getColumns(
  enrichmentColProps: EnrichmentColProps,
  getValue: (enrichmentValues: GetListResponse['businesses'][number]['enrichmentValues'][number] | null) => boolean | null,
  onCellClick?: (enrichmentValues: GetListResponse['businesses'][number]['enrichmentValues'][number], businessId: string) => void,
) {
  return [{
    field: enrichmentColProps.col.id,
    width: enrichmentColProps.columnWidths[enrichmentColProps.col.id] ?? 300,
    headerName: enrichmentColProps.col.name,
    sortable: true,
    disableColumnMenu: true,
    type: 'boolean',
    valueGetter: (data: GridValueGetterParams<GetListResponse['businesses'][number]>) => {
      const val = data.row.enrichmentValues.find((x) => x.colId === enrichmentColProps.col.id);
      return getValue(val!);
    },
    renderCell: (data: GridRenderCellParams<GetListResponse['businesses'][number]>) => {
      const val = data.row.enrichmentValues.find((x) => x.colId === enrichmentColProps.col.id);
      let boolVal: boolean | null = null;
      if (val) {
        boolVal = getValue(val);
      }
      return (
        <EnrichmentCellWrapper
          enrichmentColProps={enrichmentColProps}
          businessId={data.row.id}
          onInfoClick={val && onCellClick ? () => onCellClick(val, data.row.id) : undefined}
          value={val}
        >
          <Typography
            level='body-sm'
            textColor='text.primary'
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '2px',
              alignItems: 'center',
            }}
          >
            {
              boolVal ?
                <>
                  <CheckCircle
                    color='success'
                  />
                  Yes
                </> :
                <>
                  <Cancel
                    color='error'
                  />
                  No
                </>
            }
          </Typography>
        </EnrichmentCellWrapper>
      );
    },
    renderHeader: () => (
      <EnrichmentHeader
        {...enrichmentColProps}
      />
    ),
  }];
}
