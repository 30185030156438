import React, { useCallback } from 'react';
import { tss } from 'tss-react';
import {
  Button,
  Card,
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { GetListResponse } from 'src/generated/api';
import { getAPI } from 'src/api';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      width: 500,
      padding: theme.spacing(2),
      overflow: 'auto',
    },
    errors: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    cardHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    clearAllErrorsButton: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }));

type Props = {
  listId: string;
  importErrors: GetListResponse['businessImportErrors'];
  open: boolean;
  onClose: () => void;
  refetch: () => void;
};

export default function ImportErrorModal({
  listId,
  importErrors,
  open,
  onClose,
  refetch,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const api = getAPI();

  const clearError = useCallback(async (id: string) => {
    await api.lists.deleteImportError(listId, id);
    refetch();
  }, [api, listId, refetch]);

  const clearAllErrors = useCallback(async () => {
    await api.lists.deleteAllImportError(listId);
    refetch();
    onClose();
  }, [api, listId, refetch, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <ModalDialog className={classes.container}>
        <ModalClose />
        <Typography level='h3'>
          Import Errors
        </Typography>
        <div className={classes.errors}>
          {
            importErrors.map((err) => (
              <Card key={err.id}>
                <div className={classes.cardHeader}>
                  <Typography level='body-md'>
                    <b>{err.website}</b>
                  </Typography>
                  <Button
                    onClick={() => clearError(err.id)}
                    variant='outlined'
                    size='sm'
                  >
                    Clear
                  </Button>
                </div>
                <Typography color='danger' level='body-sm'>
                  {err.error}
                </Typography>
              </Card>
            ))
          }
        </div>
        <Button
          variant='outlined'
          className={classes.clearAllErrorsButton}
          onClick={() => clearAllErrors()}
        >
          Clear All Errors
        </Button>
      </ModalDialog>
    </Modal>
  );
}
