import {
  Divider,
  IconButton,
  Button,
  Theme,
  useTheme,
  Typography,
} from '@mui/joy';
import { tss } from 'tss-react';
import React from 'react';
import { Control, FieldErrors, useFieldArray } from 'react-hook-form';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import { ControlledInput } from 'src/components/ControlledInput';
import { CIMData } from './types';

type CompanyInfoProps = {
  control: Control<CIMData>;
  errors: FieldErrors<CIMData>;
  projectLocked: boolean;
};

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      gap: theme.spacing(2),
      width: '100%',
    },
    fullWidth: {
      width: '100%',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      position: 'sticky',
      top: theme.spacing(8),
      backgroundColor: theme.palette.background.body,
      zIndex: 1,
      padding: theme.spacing(2, 0),
    },
  }));

export default function CompanyOverview({
  control,
  errors,
  projectLocked,
}: CompanyInfoProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });

  const {
    fields: valuePropsFields,
    append: appendValueProp,
    remove: removeValueProp,
  } = useFieldArray({ control, name: 'valueProps' });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography level='h3'>Company Overview</Typography>
      </div>
      <ControlledInput
        label="Description"
        name="companyOverview"
        control={control}
        error={errors.companyOverview}
        required
        textArea
        className={classes.fullWidth}
        inputProps={{ disabled: projectLocked }}
      />
      <Divider>Value Props</Divider>
      {valuePropsFields.map((field, index) => (
        <div key={field.id} className={classes.row}>
          <ControlledInput
            name={`valueProps.${index}.value`}
            control={control}
            error={errors.valueProps?.[index]?.value}
            className={classes.fullWidth}
            inputProps={{ disabled: projectLocked }}
          />
          <IconButton
            onClick={() => removeValueProp(index)}
            size="lg"
            color="danger"
            variant="outlined"
            disabled={projectLocked}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      <Button
        color="success"
        startDecorator={<AddIcon />}
        variant="outlined"
        onClick={() => appendValueProp({ value: '' })}
        disabled={projectLocked}
      >
        Add Value Prop
      </Button>
    </div>
  );
}
