import React, {
  useContext,
  useState,
  useCallback,
} from 'react';
import {
  Button,
  Card,
  Theme,
  Typography,
  useTheme,
  IconButton,
} from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';
import { tss } from 'tss-react';
import {
  useFieldArray,
  Control,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { SnackbarContext } from 'src/contexts/snackbar';
import axios from 'axios';
import Dropzone from 'react-dropzone';

import { ControlledInput } from 'src/components/ControlledInput';
import { getAPI } from 'src/api';
import { uploadedLink } from 'src/utils';
import { CIMData } from './types';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      paddingBottom: theme.spacing(2),
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.background.body,
      zIndex: 1,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      gap: theme.spacing(1),
      overflowY: 'auto',
    },
    title: {
    },
    textArea: {
      height: '100%',
      width: '100%',
    },
    field: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    postTransactionTitle: {
      marginTop: theme.spacing(2),
    },
    leadershipEntry: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      width: '100%',
    },
    leadershipEntryCol: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: theme.spacing(2),
      flex: 1,
    },
    uploadZone: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      height: 200,
      width: 200,
      borderRadius: theme.spacing(1),
      borderWidth: 1,
      borderStyle: 'dashed',
      cursor: 'pointer',
      overflow: 'hidden',
      position: 'relative',
    },
    removeImageButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      zIndex: 1,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
      },
    },
    uploadedImage: {
      height: 200,
      width: 200,
      objectFit: 'cover',
    },
    emptyState: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    saveAndContinue: {
      marginTop: theme.spacing(4),
    },
  }));

export type LeadershipProps = {
  control: Control<CIMData>;
  errors: FieldErrors<CIMData>;
  setValue: UseFormSetValue<CIMData>;
  projectLocked: boolean;
};

export default function Leadership({
  control,
  errors,
  setValue,
  projectLocked,
}: LeadershipProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const { showSnackbar } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'leadershipBios',
  });

  const api = getAPI();

  const onDropAccepted = useCallback(async (acceptedFiles: File[], index: number) => {
    setLoading(true);
    try {
      const signedUrlRes = await api.files.createSignedUrl();
      await axios.put(
        signedUrlRes.data.url.replace(/"/g, ''),
        acceptedFiles[0],
        {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        },
      );
      setValue(`leadershipBios.${index}.pictureFileId`, signedUrlRes.data.fileId);
      setUploadedImages((prev) => {
        const newImages = [...prev];
        newImages[index] = uploadedLink(signedUrlRes.data.fileId);
        return newImages;
      });
      showSnackbar({
        message: 'Image uploaded successfully!',
        color: 'success',
      });
    } catch (error) {
      showSnackbar({
        message: 'Failed to upload image. Please try again.',
        color: 'danger',
      });
    } finally {
      setLoading(false);
    }
  }, [api.files, setValue, showSnackbar]);

  const onDropRejected = useCallback(() => {
    showSnackbar({
      color: 'danger',
      message: 'Only image files are supported!',
    });
  }, [showSnackbar]);

  const handleRemoveImage = useCallback((index: number) => {
    setValue(`leadershipBios.${index}.pictureFileId`, '');
    setUploadedImages((prev) => {
      const newImages = [...prev];
      newImages[index] = '';
      return newImages;
    });
  }, [setValue]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography level='h3' className={classes.title}>Leadership</Typography>
      </div>
      {fields.map((field, index) => (
        <Card key={field.id} className={classes.leadershipEntry}>
          <Dropzone
            accept={{ 'image/*': [] }}
            onDropAccepted={(files) => onDropAccepted(files, index)}
            onDropRejected={onDropRejected}
            multiple={false}
            disabled={loading || projectLocked}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={classes.uploadZone}>
                <input {...getInputProps()} />
                {field.pictureFileId || uploadedImages[index] ? (
                  <>
                    <img
                      src={uploadedImages[index] || uploadedLink(field.pictureFileId)}
                      alt={`Leader ${index + 1}`}
                      className={classes.uploadedImage}
                    />
                    <IconButton
                      className={classes.removeImageButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveImage(index);
                      }}
                      size="sm"
                      disabled={projectLocked}
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                ) : (
                  <div className={classes.emptyState}>
                    <Typography level='body-md'>
                      {loading ? 'Uploading...' : 'Drop an image here or click to upload'}
                    </Typography>
                  </div>
                )}
              </div>
            )}
          </Dropzone>
          <div className={classes.leadershipEntryCol}>
            <ControlledInput
              name={`leadershipBios.${index}.bio`}
              control={control}
              error={errors.leadershipBios?.[index]?.bio}
              label="Bio"
              className={classes.textArea}
              textArea
              required
              inputProps={{ disabled: projectLocked }}
            />
            <Button
              variant='outlined'
              color='danger'
              disabled={projectLocked}
              onClick={() => remove(index)}
            >
              Remove
            </Button>
          </div>
        </Card>
      ))}
      <Button
        onClick={() => append({ pictureFileId: '', bio: '' })}
        color='success'
        variant='outlined'
        size='lg'
      >
        Add Leader
      </Button>
    </div>
  );
}
