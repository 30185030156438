import React, { useCallback, useContext, useState } from 'react';
import { tss } from 'tss-react';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
  Button,
  IconButton,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import {
  Email,
  Password,
  Person,
  Phone,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { getAPI } from 'src/api';
import { getConfig } from 'src/config';
import PhoneMaskAdapter, { PhoneMaskAdapterCustomProps } from 'src/components/PhoneMask';
import { ControlledInput } from 'src/components/ControlledInput';
import { AuthContext } from 'src/contexts/auth';
import { SnackbarContext } from 'src/contexts/snackbar';
import posthog from 'posthog-js';
import { IMaskInputProps } from 'react-imask';

const PhoneMaskAdapterRef = React.forwardRef<
HTMLInputElement,
PhoneMaskAdapterCustomProps & IMaskInputProps<HTMLInputElement>
>(
  PhoneMaskAdapter,
);
const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      padding: theme.spacing(2),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(4),
      marginTop: theme.spacing(2),
      width: '100%',
    },
    logout: {
      marginTop: theme.spacing(3),
    },
    input: {
      flex: 1,
    },
  }));

export default function Account() {
  const theme = useTheme();
  const api = getAPI();
  const { classes } = useStyles({ theme });
  const config = getConfig();
  const { user } = useContext(AuthContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      firstName: user!.firstName,
      lastName: user!.lastName,
      phone: user!.phone,
      email: user!.email,
      intro: user!.intro,
    },
  });
  const onSubmit: SubmitHandler<{
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    intro: string;
  }> = async (data) => {
    await api.auth.updateAccount({
      ...data,
    });
    showSnackbar({
      color: 'success',
      message: 'Successfully updated profile!',
    });
  };

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const {
    control: passwordControl,
    handleSubmit: handlePasswordSubmit,
    formState: { errors: passwordErrors },
    reset: resetPasswordField,
  } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
    },
  });
  const onPasswordSubmit: SubmitHandler<{
    currentPassword: string;
    newPassword: string;
  }> = async (data) => {
    try {
      await api.auth.updatePassword({
        ...data,
      });
      showSnackbar({
        color: 'success',
        message: 'Successfully updated password!',
      });
      resetPasswordField();
    } catch (err) {
      const errorBody = await (err as Response).json();
      let reason = 'An unknown error has occurred. Contact hello@offdeal.io for support';
      if (errorBody?.reason) {
        reason = errorBody.reason as string;
      }
      showSnackbar({
        color: 'danger',
        message: reason,
      });
    }
  };

  const logout = useCallback(async () => {
    await api.auth.logout();
    posthog.reset();
    window.location.href = config.hosts.landing;
  }, [api, config]);
  return (
    <div className={classes.container}>
      <Typography level='h4'>
        Profile
      </Typography>
      <div className={classes.row}>
        <ControlledInput
          required
          label='First Name'
          name='firstName'
          control={control}
          StartDecorator={Person}
          error={errors.firstName}
          className={classes.input}
        />
        <ControlledInput
          required
          className={classes.input}
          label='Last Name'
          name='lastName'
          control={control}
          StartDecorator={Person}
          error={errors.lastName}
        />
      </div>
      <div className={classes.row}>
        <ControlledInput
          required
          label='Phone'
          name='phone'
          control={control}
          StartDecorator={Phone}
          error={errors.phone}
          className={classes.input}
          inputProps={{
            slotProps: {
              input: {
                component: PhoneMaskAdapterRef,
              },
            },
          }}
        />
        <ControlledInput
          required
          className={classes.input}
          label='Email Address'
          name='email'
          control={control}
          StartDecorator={Email}
          error={errors.email}
          extraRules={{
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'This is not a valid email',
            },
          }}
        />
      </div>
      <div className={classes.row}>
        <Button
          size='lg'
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </div>
      <br />
      <Typography level='h4'>
        Password
      </Typography>
      <div className={classes.row}>
        <ControlledInput
          required
          minLength={6}
          className={classes.input}
          label='Password'
          name='currentPassword'
          control={passwordControl}
          StartDecorator={Password}
          error={passwordErrors.currentPassword}
          inputProps={{
            endDecorator:
              <IconButton onClick={() => setShowCurrentPassword((prev) => !prev)}>
                { showCurrentPassword ? <Visibility /> : <VisibilityOff /> }
              </IconButton>,
            type: showCurrentPassword ? 'text' : 'password',
          }}
        />
        <ControlledInput
          required
          minLength={6}
          className={classes.input}
          label='Password'
          name='newPassword'
          control={passwordControl}
          StartDecorator={Password}
          error={passwordErrors.newPassword}
          inputProps={{
            endDecorator:
              <IconButton onClick={() => setShowNewPassword((prev) => !prev)}>
                { showNewPassword ? <Visibility /> : <VisibilityOff /> }
              </IconButton>,
            type: showNewPassword ? 'text' : 'password',
          }}
        />
      </div>
      <div className={classes.row}>
        <Button
          size='lg'
          onClick={handlePasswordSubmit(onPasswordSubmit)}
        >
          Update Password
        </Button>
      </div>
      <Button
        variant='outlined'
        color='danger'
        size='lg'
        className={classes.logout}
        onClick={logout}
      >
        Log Out
      </Button>
    </div>
  );
}
