import {
  Divider,
  IconButton,
  Button,
  Theme,
  useTheme,
  Typography,
  Switch,
} from '@mui/joy';
import { tss } from 'tss-react';
import React from 'react';
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormSetValue,
} from 'react-hook-form';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import { ControlledInput } from 'src/components/ControlledInput';
import { CIMData, ShowSections } from './types';

type GrowthProps = {
  control: Control<CIMData>;
  setValue: UseFormSetValue<CIMData>;
  errors: FieldErrors<CIMData>;
  projectLocked: boolean;
  showSections: ShowSections;
  setShowSections: React.Dispatch<React.SetStateAction<ShowSections>>;
};

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      gap: theme.spacing(2),
      width: '100%',
    },
    fullWidth: {
      width: '100%',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      position: 'sticky',
      top: theme.spacing(8),
      backgroundColor: theme.palette.background.body,
      zIndex: 1,
      padding: theme.spacing(2, 0),
    },
    keyPoints: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
  }));

type KeyPointsProps = {
  nestIndex: number;
  control: Control<CIMData>;
  errors: FieldErrors<CIMData>;
  projectLocked: boolean;
  classes: ReturnType<typeof useStyles>['classes'];
};

function KeyPoints({
  nestIndex,
  control,
  errors,
  projectLocked,
  classes,
}: KeyPointsProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `growthOppertunities.${nestIndex}.keyPoints`,
  });

  return (
    <div className={classes.keyPoints}>
      <Typography level="body-md">Key Points</Typography>
      {fields.map((item, k) => (
        <div key={item.id} className={classes.row}>
          <ControlledInput
            name={`growthOppertunities.${nestIndex}.keyPoints.${k}.value`}
            control={control}
            error={errors.growthOppertunities?.[nestIndex]?.keyPoints?.[k]}
            className={classes.fullWidth}
            inputProps={{ disabled: projectLocked }}
          />
          <IconButton
            onClick={() => remove(k)}
            size="lg"
            color="danger"
            variant="outlined"
            disabled={projectLocked}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      <Button
        color="primary"
        startDecorator={<AddIcon />}
        variant="outlined"
        onClick={() => append({ value: '' })}
        disabled={projectLocked}
      >
        Add Key Point
      </Button>
    </div>
  );
}

export default function Growth({
  control,
  errors,
  projectLocked,
  showSections,
  setShowSections,
}: GrowthProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });

  const {
    fields: growthOpportunities,
    append: appendGrowthOpportunity,
    remove: removeGrowthOpportunity,
  } = useFieldArray({ control, name: 'growthOppertunities' });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography level='h3'>Growth Opportunities</Typography>
        <Switch
          checked={showSections.growthOpportunities}
          onChange={(event) => setShowSections((prev) => ({
            ...prev,
            growthOpportunities: event.target.checked,
          }))}
          disabled={projectLocked}
        />
      </div>
      {showSections.growthOpportunities && (
        <>
          {growthOpportunities.map((field, index) => (
            <div key={field.id}>
              <div className={classes.row}>
                <ControlledInput
                  name={`growthOppertunities.${index}.title`}
                  control={control}
                  error={errors.growthOppertunities?.[index]?.title}
                  className={classes.fullWidth}
                  inputProps={{ disabled: projectLocked }}
                  label="Title"
                />
                <IconButton
                  onClick={() => removeGrowthOpportunity(index)}
                  size="lg"
                  color="danger"
                  variant="outlined"
                  disabled={projectLocked}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <KeyPoints
                nestIndex={index}
                control={control}
                errors={errors}
                projectLocked={projectLocked}
                classes={classes}
              />
              <Divider sx={{ my: 2 }} />
            </div>
          ))}
          <Button
            color="success"
            startDecorator={<AddIcon />}
            variant="outlined"
            onClick={() => appendGrowthOpportunity({ title: '', keyPoints: [{ value: '' }] })}
            disabled={projectLocked}
          >
            Add Growth Opportunity
          </Button>
        </>
      )}
    </div>
  );
}
