import { extendTheme } from '@mui/joy/styles';

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {
          body: '#FBFCFE',
        },
      },
    },
  },
  components: {
    JoyButton: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
        },
      },
    },
  },
});

export default theme;
