import React from 'react';
import {
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { tss } from 'tss-react';
import { getAPI } from 'src/api';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';
import { GetAutopilotBusinessViewResponse } from 'src/generated/api';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    contents: {
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    header: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    modal: {
      padding: theme.spacing(2),
      overflowY: 'auto',
      width: 400,
    },
    nameRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      justifyContent: 'space-between',
    },
    nameInput: {
      width: 150,
    },
  }));

type Props = {
  campaignId: string;
  businessId: string;
  contactInfo: GetAutopilotBusinessViewResponse['contactInfo'],
  onClose: () => void;
};

export default function ContactInfoModal({
  campaignId,
  businessId,
  contactInfo,
  onClose,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: {
      contactFirstName: contactInfo.firstName ?? '',
      contactLastName: contactInfo.lastName ?? '',
      contactEmailAddress: contactInfo.emailAddress ?? '',
      contactJobTitle: contactInfo.jobTitle ?? '',
    },
  });
  const onSubmit: SubmitHandler<{
    contactFirstName: string;
    contactLastName: string;
    contactEmailAddress: string;
    contactJobTitle: string;
  }> = async (data) => {
    const api = getAPI();
    await api.autopilot.updateSequenceContactInfo(campaignId, businessId, {
      contactEmailAddress: data.contactEmailAddress,
      contactFirstName: data.contactFirstName,
      contactLastName: data.contactLastName,
      contactJobTitle: data.contactJobTitle,
    });
    onClose();
  };
  return (
    <Modal
      open
      onClose={(e, reason) => {
        if (reason === 'closeClick') {
          onClose();
        }
      }}
    >
      <ModalDialog className={classes.modal}>
        <ModalClose />
        <div className={classes.contents}>
          <div className={classes.header}>
            <div className={classes.headerRow}>
              <Typography level='h4'>
                Update Contact Info
              </Typography>
            </div>
          </div>
          <div className={classes.nameRow}>
            <ControlledInput
              required
              fullWidth={false}
              label='First Name'
              name='contactFirstName'
              control={control}
              error={errors.contactFirstName}
              className={classes.nameInput}
            />
            <ControlledInput
              required
              fullWidth={false}
              label='Last Name'
              name='contactLastName'
              control={control}
              error={errors.contactLastName}
              className={classes.nameInput}
            />
          </div>
          <ControlledInput
            required
            label='Job Title'
            name='contactJobTitle'
            control={control}
            error={errors.contactJobTitle}
          />
          <ControlledInput
            required
            label='Email Address'
            name='contactEmailAddress'
            control={control}
            error={errors.contactEmailAddress}
          />
          <Button onClick={handleSubmit(onSubmit)} className={classes.submitButton}>
            Submit
          </Button>
        </div>
      </ModalDialog>
    </Modal>
  );
}
