import React from 'react';
import { tss } from 'tss-react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';
import { getAPI } from 'src/api';
import { logEvent } from 'src/analytics';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      width: 300,
      padding: theme.spacing(2),
    },
  }));

type Props = {
  open: boolean;
  onClose: (id: string, name: string) => void;
  navigateOnCreate?: boolean;
};

export default function CreateListModal({
  open,
  onClose,
  navigateOnCreate = false,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
  });
  const api = getAPI();
  const onCreate: SubmitHandler<{
    name: string;
  }> = async (data) => {
    logEvent({
      name: 'lists_create',
      type: 'click',
      extra: {
        name: data.name,
      },
    });
    const res = await api.lists.createList({
      name: data.name,
    });
    logEvent({
      name: 'lists_create_success',
      type: 'response',
      extra: {
        name: data.name,
        id: res.data.id,
      },
    });
    onClose(res.data.id, data.name);
    if (navigateOnCreate) {
      navigate(`/lists/${res.data.id}`);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <ModalDialog className={classes.container}>
        <ModalClose />
        <Typography level='h4'>
          Create List
        </Typography>
        <ControlledInput
          required
          label='Name'
          name='name'
          control={control}
          error={errors.name}
        />
        <Button onClick={handleSubmit(onCreate)}>
          Create
        </Button>
      </ModalDialog>
    </Modal>
  );
}
