import React, { useCallback } from 'react';
import { tss } from 'tss-react';
import {
  Button,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { GetAutopilotManageEngageResponse } from 'src/generated/api';
import { Check, Close, Edit } from '@mui/icons-material';
import { getAPI } from 'src/api';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    companyInfoRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      alignItems: 'center',
      height: '100%',
      width: '100%',
      cursor: 'pointer',
      padding: theme.spacing(1),
    },
    companyTitle: {
      fontWeight: 600,
    },
    companyInfo: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    },
    actions: {
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    approveButton: {
      width: 128,
      marginTop: theme.spacing(1),
    },
    approveButtonIcon: {
      width: 20,
      height: 20,
    },
  }));

type Props = {
  campaignId: string;
  business: GetAutopilotManageEngageResponse['businesses'][number];
  refetch: () => void;
  showOverwriteEmails: () => void;
  setDrawerId: (id: string) => void;
};

export default function AboutCell({
  campaignId,
  business,
  refetch,
  showOverwriteEmails,
  setDrawerId,
}: Props) {
  const theme = useTheme();
  const api = getAPI();
  const setApproved = useCallback(async (approved: boolean) => {
    await api.autopilot.approveSequence(campaignId, business.id, {
      approved,
    });
    refetch();
  }, [api, refetch, campaignId, business.id]);
  const { classes } = useStyles({ theme });
  return (
    <div
      onClick={() => setDrawerId(business.id)}
      className={classes.companyInfoRow}
    >
      <div
        className={classes.companyInfo}
      >
        <Typography
          level='body-md'
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
          }}
        >
          <b>
            {business.name}
          </b>
        </Typography>
        <Typography level='body-sm'>
          { business.contactInfo.firstName } { business.contactInfo.lastName } (
            { business.contactInfo.emailAddress })
        </Typography>
        {
          business.sequence.approved ?
            <Button
              variant='outlined'
              color='danger'
              className={classes.approveButton}
              onClick={(e) => {
                setApproved(false);
                e.stopPropagation();
              }}
            >
              <Close className={classes.approveButtonIcon} />
              Unapprove
            </Button> :
            <div className={classes.buttons}>
              <Button
                variant='outlined'
                color='success'
                className={classes.approveButton}
                onClick={(e) => {
                  setApproved(true);
                  e.stopPropagation();
                }}
              >
                <Check className={classes.approveButtonIcon} />
                Approve
              </Button>
              <Button
                variant='outlined'
                color='primary'
                className={classes.approveButton}
                onClick={(e) => {
                  showOverwriteEmails();
                  e.stopPropagation();
                }}
              >
                <Edit className={classes.approveButtonIcon} />
                Edit
              </Button>
            </div>
        }
      </div>
    </div>
  );
}
