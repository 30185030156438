import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Typography,
  IconButton,
  useTheme,
  Theme,
  Card,
  Table,
  Modal,
  ModalDialog,
  ModalClose,
  List,
  ListItem,
  ListItemContent,
  Tooltip,
} from '@mui/joy';
import {
  AccountBalance,
  Add as AddIcon,
  ArrowBack,
  Delete as DeleteIcon,
  Download,
  Info as InfoIcon,
  Lock,
  LockOpen,
} from '@mui/icons-material';
import { tss } from 'tss-react';
import { ControlledInput } from 'src/components/ControlledInput';
import { AddOnInfo, GetProjectResponse, SponsorInfo } from 'src/generated/api';
import { getAPI } from 'src/api';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingContainer from 'src/components/LoadingContainer';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      maxWidth: 1400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      gap: theme.spacing(2),
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(2),
      width: '100%',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      gap: theme.spacing(2),
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      boxShadow: theme.shadow.md,
    },
    addButton: {
      marginLeft: 'auto',
    },
    actionButtons: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    table: {
      width: '100%',
      tableLayout: 'fixed',
    },
    tableCell: {
      padding: theme.spacing(1),
      verticalAlign: 'top',
    },
    acquirerName: {
      width: 250,
    },
    acquirerWebsite: {
      width: 250,
    },
    matchScore: {
      width: 80,
    },
    actions: {
      width: 200,
    },
    expandCol: {
      width: 'auto',
    },
    longContent: {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      overflowY: 'auto',
    },
    stickyHeader: {
      position: 'sticky',
      top: 0,
      width: '100%',
      backgroundColor: theme.palette.background.body,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    headerActions: {
      display: 'flex',
      gap: theme.spacing(2),
    },
  }));

type MatchedBuyer = {
  acquirerWebsite: string;
  acquirerName: string;
  acquirerDescription: string;
  matchScore: number;
  matchNotes: string;
  addOnsInfo: { name: string; description: string; url: string }[];
  sponsorsInfo: { name: string; url: string; pocName: string }[];
};

export function BuyerList() {
  const { id } = useParams();
  const theme = useTheme();
  const api = getAPI();
  const [project, setProject] = useState<GetProjectResponse | null>(null);
  const { classes, cx } = useStyles({ theme });
  const {
    control,
    handleSubmit,
  } = useForm<MatchedBuyer>();

  const [newBuyer, setNewBuyer] = useState<MatchedBuyer | null>(null);
  const [addOnsModalOpen, setAddOnsModalOpen] = useState(false);
  const [sponsorsModalOpen, setSponsorsModalOpen] = useState(false);
  const [selectedAddOns, setSelectedAddOns] = useState<MatchedBuyer['addOnsInfo']>([]);
  const [selectedSponsors, setSelectedSponsors] = useState<MatchedBuyer['sponsorsInfo']>([]);

  const fetchData = useCallback(async () => {
    const res = await api.clients.getProject(id!);
    setProject(res.data);
  }, [api, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const isListLocked = project?.matchedBuyerList?.isCompleted ?? false;

  const handleAddBuyer = () => {
    setNewBuyer({
      acquirerWebsite: '',
      acquirerName: '',
      acquirerDescription: '',
      matchScore: 0,
      matchNotes: '',
      addOnsInfo: [],
      sponsorsInfo: [],
    });
  };

  const handleRemoveBuyer = async (acquirerWebsite: string) => {
    await api.clients.deleteMatchedBuyer(id!, { acquirerWebsite });
    fetchData();
  };

  const handleCancelNewBuyer = () => {
    setNewBuyer(null);
  };

  const onSubmit = handleSubmit(async (data: MatchedBuyer) => {
    await api.clients.addMatchedBuyer(id!, data);
    setNewBuyer(null);
    fetchData();
  });

  const handleViewAddOns = (addOns: MatchedBuyer['addOnsInfo']) => {
    setSelectedAddOns(addOns);
    setAddOnsModalOpen(true);
  };

  const handleViewSponsors = (sponsors: MatchedBuyer['sponsorsInfo']) => {
    setSelectedSponsors(sponsors);
    setSponsorsModalOpen(true);
  };

  const handleExportCSV = async () => {
    const res = await api.clients.exportBuyersCsv(id!, {
      format: 'arrayBuffer',
    });
    const blob = new Blob([res.data], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'buyers.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFinalize = async () => {
    await api.clients.lockBuyerList(id!);
    fetchData();
  };

  const handleUnlock = async () => {
    await api.clients.unlockBuyerList(id!);
    fetchData();
  };

  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <LoadingContainer isLoading={!project}>
        <div className={classes.content}>
          <div className={classes.stickyHeader}>
            <div className={classes.header}>
              <IconButton
                onClick={() => navigate(`/clients/${id}`)}
                color='neutral'
                size='lg'
              >
                <ArrowBack />
              </IconButton>
              <Typography level="h3">Buyer List</Typography>
            </div>
            <div className={classes.headerActions}>
              <IconButton
                onClick={handleExportCSV}
                color="primary"
                variant="outlined"
                size="lg"
              >
                <Download />
              </IconButton>
              {
                project?.matchedBuyerList?.isCompleted ?
                  <Tooltip title="Unlock List">
                    <IconButton
                      onClick={handleUnlock}
                      variant="solid"
                      color="warning"
                      size="lg"
                    >
                      <LockOpen />
                    </IconButton>
                  </Tooltip> :
                  <Tooltip title="Finalize List">
                    <IconButton
                      onClick={handleFinalize}
                      variant="solid"
                      color="success"
                      size="lg"
                    >
                      <Lock />
                    </IconButton>
                  </Tooltip>
              }
            </div>
          </div>
          <Card className={classes.card}>
            <Table className={classes.table}>
              <thead>
                <tr>
                  <th className={cx(classes.tableCell, classes.acquirerName)}>Potential Buyer</th>
                  <th className={cx(classes.tableCell, classes.expandCol)}>Description</th>
                  <th className={cx(classes.tableCell, classes.matchScore)}>Score</th>
                  <th className={cx(classes.tableCell, classes.expandCol)}>Notes</th>
                  <th className={cx(classes.tableCell, classes.actions)}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {project?.matchedBuyerList?.matchedBuyers.map((buyer) => (
                  <tr key={buyer.acquirerWebsite}>
                    <td className={cx(classes.tableCell, classes.acquirerName)}>
                      <Typography level="body-sm">{buyer.acquirerName}</Typography>
                      <Typography level="body-sm" fontStyle="italic">{buyer.acquirerWebsite}</Typography>
                    </td>
                    <td className={cx(classes.tableCell, classes.expandCol)}>
                      <Typography level="body-sm" className={classes.longContent}>
                        {buyer.acquirerDescription}
                      </Typography>
                    </td>
                    <td className={cx(classes.tableCell, classes.matchScore)}>
                      <Typography level="body-sm">{(buyer.matchScore * 100).toFixed(0)}</Typography>
                    </td>
                    <td className={cx(classes.tableCell, classes.expandCol)}>
                      <Typography level="body-sm" className={classes.longContent}>
                        {buyer.matchNotes}
                      </Typography>
                    </td>
                    <td className={cx(classes.tableCell, classes.actions)}>
                      <div className={classes.actionButtons}>
                        {
                          buyer?.addOnsInfo && (buyer?.addOnsInfo as AddOnInfo[]).length > 0 && (
                            <Tooltip title="View Add-ons">
                              <IconButton
                                onClick={() => handleViewAddOns(buyer.addOnsInfo as MatchedBuyer['addOnsInfo'])}
                                size="lg"
                                color="primary"
                                variant="outlined"
                              >
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          )
                        }
                        {
                          buyer?.sponsorsInfo &&
                          (buyer?.sponsorsInfo as SponsorInfo[]).length > 0 &&
                          (
                            <Tooltip title="View Sponsors">
                              <IconButton
                                onClick={() => handleViewSponsors(buyer.sponsorsInfo as MatchedBuyer['sponsorsInfo'])}
                                size="lg"
                                color="neutral"
                                variant="outlined"
                              >
                                <AccountBalance />
                              </IconButton>
                            </Tooltip>
                          )
                        }
                        {!isListLocked && (
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => handleRemoveBuyer(buyer.acquirerWebsite)}
                              size="lg"
                              color="danger"
                              variant="outlined"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
                {newBuyer && !isListLocked && (
                  <tr>
                    <td className={cx(classes.tableCell, classes.acquirerName)}>
                      <ControlledInput
                        name="acquirerName"
                        control={control}
                        label="Name"
                        required
                      />
                      <ControlledInput
                        name="acquirerWebsite"
                        control={control}
                        label="Website"
                        required
                      />
                    </td>
                    <td className={cx(classes.tableCell, classes.expandCol)}>
                      <ControlledInput
                        name="acquirerDescription"
                        control={control}
                        textArea
                      />
                    </td>
                    <td className={cx(classes.tableCell, classes.matchScore)}>
                      <ControlledInput
                        name="matchScore"
                        control={control}
                        required
                        inputProps={{
                          type: 'number',
                        }}
                      />
                    </td>
                    <td className={cx(classes.tableCell, classes.expandCol)}>
                      <ControlledInput
                        name="matchNotes"
                        control={control}
                        textArea
                      />
                    </td>
                    <td className={cx(classes.tableCell, classes.actions)}>
                      <div className={classes.actionButtons}>
                        <Button
                          onClick={onSubmit}
                          color="success"
                          size="lg"
                        >
                          Save
                        </Button>
                        <Button
                          onClick={handleCancelNewBuyer}
                          color="danger"
                          variant="outlined"
                          size="lg"
                        >
                          Cancel
                        </Button>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card>
          <div className={classes.footer}>
            {!newBuyer && !isListLocked &&
              <Button
                onClick={handleAddBuyer}
                startDecorator={<AddIcon />}
                color="success"
                variant="outlined"
                size="lg"
              >
                Add Buyer
              </Button>
            }
          </div>
        </div>
      </LoadingContainer>
      <Modal open={addOnsModalOpen} onClose={() => setAddOnsModalOpen(false)}>
        <ModalDialog>
          <ModalClose />
          <Typography level="h4">
            Add Ons
          </Typography>
          <List>
            {selectedAddOns.map((item, index) => (
              <ListItem key={index}>
                <ListItemContent>
                  <Typography level="h4">{item.name}</Typography>
                  <Typography>{item.description}</Typography>
                  <Typography>
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      {item.url}
                    </a>
                  </Typography>
                </ListItemContent>
              </ListItem>
            ))}
          </List>
        </ModalDialog>
      </Modal>
      <Modal open={sponsorsModalOpen} onClose={() => setSponsorsModalOpen(false)}>
        <ModalDialog>
          <ModalClose />
          <Typography level="h4">
            Sponsors
          </Typography>
          {selectedSponsors.map((item, index) => (
            <div key={index}>
              <Typography level="body-md"><b>{item.name}</b></Typography>
              <Typography level="body-md">Point of Contact: {item.pocName || 'Unknown'}</Typography>
              <Typography level="body-md">
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.url}
                </a>
              </Typography>
            </div>
          ))}
        </ModalDialog>
      </Modal>
    </div>
  );
}
