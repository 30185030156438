import React, { useState } from 'react';
import { tss } from 'tss-react';
import {
  Box,
  Chip,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';
import { GetListResponse } from 'src/generated/api';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    chips: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    chipsSection: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    tooltip: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 400,
      padding: theme.spacing(1),
    },
    chip: {
      '&:hover': {
        filter: 'brightness(110%)',
        cursor: 'pointer',
      },
      zIndex: 0,
    },
  }));

type Props = {
  business: GetListResponse['businesses'][number];
};

const maxChipsCharNum = 25;
export default function ProductsServicesCell({
  business,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const [showMoreProductServices, setShowMoreProductServices] = useState(false);
  const sortedProductServices = business.productsAndServices
    .sort((a, b) => a.keyPhrase.length - b.keyPhrase.length);
  let maxProductServicesShown = 0;
  let productServiceIdx = 0;
  let numProductServicesChars = 0;
  while (numProductServicesChars < maxChipsCharNum &&
    productServiceIdx < sortedProductServices.length) {
    maxProductServicesShown += 1;
    numProductServicesChars += sortedProductServices[productServiceIdx].keyPhrase.length;
    productServiceIdx += 1;
  }
  return (
    <div className={classes.chips}>
      <div className={classes.chipsSection}>
        {
          sortedProductServices
            .slice(0, showMoreProductServices ? undefined : maxProductServicesShown)
            .map((ps) => (
              <Tooltip
                arrow
                key={`${business.id}-${ps.keyPhrase}`}
                title={
                  <Box className={classes.tooltip}>
                    <Typography level='body-md' textColor={theme.palette.common.white}>
                      {ps.description}
                    </Typography>
                  </Box>
                }
              >
                <Chip color='warning' className={classes.chip}>
                  {ps.keyPhrase.toLowerCase()}
                </Chip>
              </Tooltip>
            ))
        }
        {
          !showMoreProductServices &&
          business.productsAndServices.length > maxProductServicesShown &&
            <Chip
              variant='outlined'
              className={classes.chip}
              onClick={(e) => {
                setShowMoreProductServices(true);
                e.stopPropagation();
              }}
            >
              +{business.productsAndServices.length - maxProductServicesShown}
            </Chip>
        }
      </div>
    </div>
  );
}
