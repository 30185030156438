import React, { useCallback, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Textarea,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import {
  GetAutopilotManageEngageResponse,
} from 'src/generated/api';
import { tss } from 'tss-react';
import { getAPI } from 'src/api';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    contents: {
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
    },
    header: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
    formLabel: {
      width: '100%',
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    backButton: {
      minWidth: 0,
      paddingLeft: 0,
    },
    enrichmentModal: {
      padding: theme.spacing(2),
      overflowY: 'auto',
      width: 600,
    },
  }));

type Props = {
  campaignId: string;
  businessId: string;
  sequence: GetAutopilotManageEngageResponse['businesses'][number]['sequence'];
  onClose: () => void;
};

export default function OverwriteEmailModal({
  campaignId,
  businessId,
  sequence,
  onClose,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const [subject, setSubject] = useState(sequence.subject);
  const [emails, setEmails] = useState(sequence.emails);
  const onSubmit = useCallback(async () => {
    const api = getAPI();
    await api.autopilot.updateEmails(campaignId, businessId, {
      emails: emails.map((e) => ({
        colId: e.colId,
        content: e.value,
      })),
    });
    onClose();
  }, [businessId, campaignId, emails, onClose]);
  return (
    <Modal
      open
      onClose={(e, reason) => {
        if (reason === 'closeClick') {
          onClose();
        }
      }}
    >
      <ModalDialog className={classes.enrichmentModal}>
        <ModalClose />
        <div className={classes.contents}>
          <div className={classes.header}>
            <div className={classes.headerRow}>
              <Typography level='h4'>
                Edit Emails
              </Typography>
            </div>
            <Typography level='body-md'>
              Overwrite the AI generated the emails to be sent to acquisition targets.
            </Typography>
          </div>
          <FormControl>
            <FormLabel>
              Subject
            </FormLabel>
            <Input
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </FormControl>
          {
            emails.map((email, idx) => (
              <FormControl>
                <FormLabel>
                  Email #{idx}
                </FormLabel>
                <Textarea
                  value={email.value}
                  onChange={(e) => setEmails((prev) => {
                    const newCopy = [...prev];
                    newCopy[idx] = {
                      ...newCopy[idx],
                      value: e.target.value,
                    };
                    return newCopy;
                  })}
                />
              </FormControl>
            ))
          }
          <Button onClick={onSubmit} className={classes.submitButton}>
            Edit
          </Button>
        </div>
      </ModalDialog>
    </Modal>
  );
}
