import React, { useContext, useState } from 'react';
import { tss } from 'tss-react';

import {
  ListItemDecorator,
  Sheet,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { AuthContext } from 'src/contexts/auth';
import LoadingContainer from 'src/components/LoadingContainer';
import { Payment, Person, Settings as SettingsIcon } from '@mui/icons-material';
import Account from './Account';
import Billing from './Billing';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: theme.spacing(4),
    },
    content: {
      width: 800,
      display: 'flex',
      flexDirection: 'column',
    },
    sheet: {
      marginTop: theme.spacing(4),
      padding: 0,
      boxShadow: theme.shadow.md,
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  }));

export default function Settings() {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(1);
  const { classes } = useStyles({ theme });
  const { user, org } = useContext(AuthContext);
  return (
    <LoadingContainer isLoading={!org || !user}>
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography level="h3" className={classes.title}>
            <SettingsIcon />
            Settings
          </Typography>
          <Sheet className={classes.sheet}>
            <Tabs
              size='lg'
              value={selectedTab}
              onChange={(_, value) => setSelectedTab(value as number)}
            >
              <TabList>
                <Tab color={selectedTab === 0 ? 'primary' : 'neutral'}>
                  <ListItemDecorator>
                    <Person />
                  </ListItemDecorator>
                  Account
                </Tab>
                <Tab color={selectedTab === 1 ? 'primary' : 'neutral'}>
                  <ListItemDecorator>
                    <Payment />
                  </ListItemDecorator>
                  Billing
                </Tab>
              </TabList>
              <TabPanel value={0}>
                <Account />
              </TabPanel>
              <TabPanel value={1}>
                <Billing />
              </TabPanel>
            </Tabs>
          </Sheet>
        </div>
      </div>
    </LoadingContainer>
  );
}
