import {
  IconButton,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import React, { useCallback, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { tss } from 'tss-react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { uploadedLink } from 'src/utils';
import { getAPI } from 'src/api';
import { SnackbarContext } from 'src/contexts/snackbar';

import { ControlledInput } from 'src/components/ControlledInput';
import { CIMData } from './types';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      gap: theme.spacing(2),
      width: '100%',
    },
    fullWidth: {
      width: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      position: 'sticky',
      top: theme.spacing(8),
      backgroundColor: theme.palette.background.body,
      zIndex: 1,
      padding: theme.spacing(2, 0),
    },
    logoField: {
      width: '100%',
    },
    uploadZone: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 120,
      width: '100%',
      borderRadius: theme.spacing(1),
      borderWidth: 1,
      borderStyle: 'dashed',
      cursor: 'pointer',
      overflow: 'hidden',
      position: 'relative',
    },
    removeImageButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      zIndex: 1,
    },
    uploadedImage: {
      height: 96,
      width: '100%',
      objectFit: 'contain',
    },
    emptyState: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      flex: 1,
    },
  }));

type BasicInfoProps = {
  control: Control<CIMData>;
  errors: FieldErrors<CIMData>;
  setValue: UseFormSetValue<CIMData>;
  getValues: UseFormGetValues<CIMData>;
  projectLocked: boolean;
};

export default function BasicInfo({
  control,
  errors,
  projectLocked,
  setValue,
  getValues,
}: BasicInfoProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const { showSnackbar } = useContext(SnackbarContext);
  const api = getAPI();

  const onDropAccepted = useCallback(async (acceptedFiles: File[]) => {
    try {
      const signedUrlRes = await api.files.createSignedUrl();
      await axios.put(
        signedUrlRes.data.url.replace(/"/g, ''),
        acceptedFiles[0],
        {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        },
      );
      setValue('footerLogoUrl', signedUrlRes.data.fileId);
      showSnackbar({
        message: 'Logo uploaded successfully!',
        color: 'success',
      });
    } catch (error) {
      showSnackbar({
        message: 'Failed to upload logo. Please try again.',
        color: 'danger',
      });
    }
  }, [api.files, setValue, showSnackbar]);

  const onDropRejected = useCallback(() => {
    showSnackbar({
      color: 'danger',
      message: 'Only image files are supported!',
    });
  }, [showSnackbar]);

  const handleRemoveLogo = useCallback(() => {
    setValue('footerLogoUrl', '');
  }, [setValue]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography level='h3'>Basic Information</Typography>
      </div>
      <div className={classes.column}>
        <div className={classes.logoField}>
          <Typography level="body-sm" mb={1}>Header Logo</Typography>
          <Dropzone
            accept={{ 'image/*': [] }}
            onDropAccepted={onDropAccepted}
            onDropRejected={onDropRejected}
            multiple={false}
            disabled={projectLocked}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={classes.uploadZone}>
                <input {...getInputProps()} />
                {getValues('footerLogoUrl') ? (
                  <>
                    <img
                      src={uploadedLink(getValues('footerLogoUrl'))}
                      alt="Header Logo"
                      className={classes.uploadedImage}
                    />
                    <IconButton
                      className={classes.removeImageButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveLogo();
                      }}
                      size="sm"
                      color="danger"
                      variant="outlined"
                      disabled={projectLocked}
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                ) : (
                  <div className={classes.emptyState}>
                    <Typography level='body-md'>
                      Drop logo here or click to upload
                    </Typography>
                  </div>
                )}
              </div>
            )}
          </Dropzone>
        </div>
        <ControlledInput
          label="Business Name"
          name="name"
          control={control}
          error={errors.name}
          required
          className={classes.fullWidth}
          inputProps={{ disabled: projectLocked }}
        />
        <ControlledInput
          label="One-liner Description"
          name="oneLinerDescription"
          control={control}
          error={errors.oneLinerDescription}
          required
          textArea
          className={classes.fullWidth}
          inputProps={{ disabled: projectLocked }}
        />
        <div className={classes.row}>
          <ControlledInput
            label="Owner Name"
            name="ownerName"
            control={control}
            error={errors.ownerName}
            required
            className={classes.fullWidth}
            inputProps={{ disabled: projectLocked }}
          />
          <ControlledInput
            label="Owner Title"
            name="ownerTitle"
            control={control}
            error={errors.ownerTitle}
            required
            className={classes.fullWidth}
            inputProps={{ disabled: projectLocked }}
          />
        </div>
        <div className={classes.row}>
          <ControlledInput
            label="Advisor Name"
            name="advisorName"
            control={control}
            error={errors.advisorName}
            required
            className={classes.fullWidth}
            inputProps={{ disabled: projectLocked }}
          />
          <ControlledInput
            label="Advisor Title"
            name="advisorTitle"
            control={control}
            error={errors.advisorTitle}
            required
            className={classes.fullWidth}
            inputProps={{ disabled: projectLocked }}
          />
        </div>
        <ControlledInput
          label="Advisor Email"
          name="advisorEmail"
          control={control}
          error={errors.advisorEmail}
          required
          className={classes.fullWidth}
          inputProps={{ disabled: projectLocked }}
        />
        <ControlledInput
          label="Process Blurb (Overwrite)"
          name="processBlurb"
          control={control}
          error={errors.processBlurb}
          textArea
          className={classes.fullWidth}
          inputProps={{ disabled: projectLocked }}
        />
      </div>
    </div>
  );
}
