import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { tss } from 'tss-react';
import {
  Theme,
  useTheme,
  CircularProgress,
  Typography,
  Modal,
  ModalDialog,
  ModalClose,
} from '@mui/joy';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    pdfPage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    pages: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    pageInfo: {
      margin: theme.spacing(0, 2),
    },
    modal: {
      overflow: 'auto',
    },
    horizontal: {
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
  }));

type PDFPageViewerProps = {
  pageNumber: number;
  width: number;
};

function PDFPageViewer({
  pageNumber,
  width,
}: PDFPageViewerProps) {
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  const { classes } = useStyles({ theme });

  return (
    <div className={classes.pdfPage}>
      <div onClick={() => setShowModal(true)}>
        <Page
          pageNumber={pageNumber}
          width={width}
          loading={
            <div
              className={classes.loadingContainer}
              style={{ width, height: width / 2 }}
            >
              <CircularProgress />
            </div>
          }
        />
      </div>
      {
        showModal &&
          <Modal
            open
            onClose={() => setShowModal(false)}
          >
            <ModalDialog className={classes.modal}>
              <ModalClose />
              <Page
                pageNumber={pageNumber}
                width={1200}
                loading={
                  <div
                    className={classes.loadingContainer}
                    style={{ width: 1200, height: 800 }}
                  >
                    <CircularProgress />
                  </div>
                }
              />
            </ModalDialog>
          </Modal>
      }
    </div>
  );
}

type PDFViewerProps = {
  url: string;
  widthPerPage: number;
  showPages: number[];
  refreshTrigger?: number;
  horizontal?: boolean;
};

const pdfOptions = {
  withCredentials: true,
};

function PDFMultiPageViewer({
  url,
  widthPerPage,
  showPages,
  refreshTrigger,
  horizontal = false,
}: PDFViewerProps) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const { classes, cx } = useStyles({ theme });

  function onDocumentLoadSuccess() {
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
  }, [refreshTrigger]);

  return (
    <div className={classes.container}>
      {isLoading && (
        <div
          className={classes.loadingContainer}
          style={{ width: widthPerPage, height: widthPerPage / 2 }}
        >
          <CircularProgress />
          <Typography level="body-sm" sx={{ ml: 2 }}>Loading PDF...</Typography>
        </div>
      )}
      <Document
        key={refreshTrigger}
        file={url}
        options={pdfOptions}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={null}
      >
        <div className={cx(classes.pages, {
          [classes.horizontal]: horizontal,
        })}>
          {!isLoading && showPages.map((pageNumber) => (
            <PDFPageViewer
              key={pageNumber}
              pageNumber={pageNumber}
              width={widthPerPage}
            />
          ))}
        </div>
      </Document>
    </div>
  );
}

export default React.memo(PDFMultiPageViewer);
