import { AnalyticsBrowser } from '@segment/analytics-next';
import posthog from 'posthog-js';

export const analytics = AnalyticsBrowser.load({
  writeKey: '3muikM5VF7qzXTYIv5uA8TFTePAnCf0I',
});

export type Event = {
  name: string;
  type: string;
  extra?: Record<string, string>;
};

export function logEvent({
  name,
  type,
  extra,
}: Event) {
  analytics.track(name, {
    type,
    page: window.location.pathname,
    source: 'app',
    ...extra,
  });
}

export function getSessionReplayUrl() {
  return posthog.get_session_replay_url();
}
