import React from 'react';
import { tss } from 'tss-react';
import {
  Typography,
  Theme,
  useTheme,
  Card,
} from '@mui/joy';
import { GetAutopilotManageEngageResponse } from 'src/generated/api';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    cell: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      wordBreak: 'break-word',
      whiteSpace: 'pre-line',
      cursor: 'pointer',
      gap: theme.spacing(1),
      padding: theme.spacing(1),
    },
  }));

type EmailValueProps = {
  subject: string;
  email: GetAutopilotManageEngageResponse['businesses'][number]['sequence']['emails'][number];
  isApproved: boolean;
};

export default function EmailCell({
  subject,
  email,
  isApproved,
}: EmailValueProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  return (
    <div className={classes.cell}>
      {
        email?.sendTime && email?.value &&
        <>
          <Card>
            <Typography
              level='body-sm'
              textColor='text.primary'
            >
              {subject}
            </Typography>
          </Card>
          <Card>
            <Typography
              level='body-sm'
              textColor='text.primary'
            >
              {email.value}
            </Typography>
          </Card>
          {
            isApproved &&
            <Typography level='body-sm'>
              Send On: {
                (new Date(email.sendTime)).toLocaleDateString('en-us', {
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })
              }
            </Typography>
          }
        </>
      }
    </div>
  );
}
