import React, { useState } from 'react';
import { tss } from 'tss-react';
import {
  Button,
  CircularProgress,
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';
import { getAPI } from 'src/api';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      width: 450,
      padding: theme.spacing(2),
    },
    loading: {
      marginTop: theme.spacing(2),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }));

type Props = {
  onClose: (
    data?: {
      productsAndServices: string[];
      customerSegments: string[];
    },
  ) => void;
};

export default function FindSimilarModal({
  onClose,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: {
      domain: '',
    },
  });
  const api = getAPI();
  const onSubmit: SubmitHandler<{
    domain: string;
  }> = async (data) => {
    setLoading(true);
    try {
      setHasError(false);
      const res = await api.businesses.getDataForSimilarSearch({
        domain: data.domain,
      });
      setLoading(false);
      onClose({
        productsAndServices: res.data.productsAndServices,
        customerSegments: res.data.customerSegments,
      });
    } catch (err) {
      setLoading(false);
      setHasError(true);
    }
  };

  return (
    <Modal
      open
      onClose={(e, reason) => {
        if (reason === 'closeClick') {
          onClose();
        }
      }}
    >
      <ModalDialog className={classes.container}>
        <ModalClose />
        <Typography level='h4'>
          Find Similar Businesses
        </Typography>
        <Typography level='body-md'>
          Search for businesses similar to one that you know, by domain.
          This may take a minute or two as our AI crawls it.
        </Typography>
        {
          loading ?
            <CircularProgress className={classes.loading} /> :
            <>
              <ControlledInput
                required
                label='Domain'
                name='domain'
                control={control}
                error={
                  errors.domain ??
                  (hasError ? {
                    message: 'Unable to scrape this domain',
                  } : undefined)
                }
              />
              <Button onClick={handleSubmit(onSubmit)}>
                Submit
              </Button>
            </>
        }
      </ModalDialog>
    </Modal>
  );
}
