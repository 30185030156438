import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { tss } from 'tss-react';
import {
  Button,
  Theme,
  Typography,
  useTheme,
  Table,
  Modal,
  Input,
  Card,
  ModalDialog,
  ModalClose,
  IconButton,
} from '@mui/joy';
import { getAPI } from 'src/api';
import LoadingContainer from 'src/components/LoadingContainer';
import { ChevronLeft, Tune } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { SnackbarContext } from 'src/contexts/snackbar';
import { uploadedLink } from 'src/utils';
import CloseIcon from '@mui/icons-material/Close';
import { AdjustedEBITDAInfoResponse, GetProjectResponse, ValuationInfoResponse } from 'src/generated/api';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    startScreen: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      gap: theme.spacing(2),
      maxWidth: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    card: {
      boxShadow: theme.shadow.md,
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    resultTable: {
      marginTop: theme.spacing(2),
    },
    scoreCell: {
      width: 96,
      textAlign: 'center',
    },
    observationCell: {
      width: 'auto',
    },
    factorCell: {
      width: 150,
    },
    multiplesTable: {
      width: 800,
    },
    headerRow: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      justifyContent: 'space-between',
    },
    headerItem: {
      display: 'flex',
      flexDirection: 'column',
    },
    uploadZone: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      height: 400,
      width: 400,
      borderRadius: theme.spacing(1),
      borderWidth: 1,
      borderStyle: 'dashed',
      cursor: 'pointer',
      overflow: 'hidden',
      position: 'relative',
      marginBottom: theme.spacing(2),
    },
    removeImageButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      zIndex: 1,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
      },
    },
    uploadedImage: {
      height: 400,
      width: 400,
      objectFit: 'cover',
    },
    emptyState: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    addBackInput: {
      marginTop: theme.spacing(1),
    },
    expense: {
      display: 'flex',
      flexDirection: 'column',
    },
    adjustedExpense: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
    },
    adjustmentAmount: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    strikethrough: {
      textDecoration: 'line-through',
      color: theme.palette.text.tertiary,
    },
    explanation: {
      whiteSpace: 'pre-wrap',
    },
  }));

const factorNameMap: { [key: string]: string } = {
  revenueEbitdaGrowth: 'Revenue & EBITDA Growth',
  recurringRevenue: 'Recurring Revenue',
  growthOpportunities: 'Growth Opportunities',
  customerConcentration: 'Customer Concentration',
  customerAcquisition: 'Customer Acquisition',
  teamOperationalEfficiency: 'Team & Operational Efficiency',
  competitiveLandscape: 'Competitive Landscape',
  technologyIP: 'Technology & IP',
  foundersAndManagement: 'Founders & Management',
  operationalTrackRecord: 'Operational Track Record',
};

const getScoreColor = (score: number): string => {
  if (score >= 3) return '#1b5e20'; // very dark green
  if (score >= 2) return '#2e7d32'; // dark green
  if (score >= 1) return '#4caf50'; // light green
  if (score >= 0) return '#757575'; // gray
  if (score >= -1) return '#ff9800'; // orange
  if (score >= -2) return '#f44336'; // light red
  return '#b71c1c'; // dark red
};

const initialMultiples = {
  '-3': -0.5,
  '-2': -0.3,
  '-1': -0.1,
  0: 0,
  1: 0.1,
  2: 0.3,
  3: 0.5,
};

const formatCurrency = (amount: number) => `$${Math.round(amount).toLocaleString()}`;

export default function ValuationCalculator() {
  const { id } = useParams();
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const api = getAPI();
  const [project, setProject] = useState<GetProjectResponse | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<ValuationInfoResponse | null>(null);
  const [isMultipleModalOpen, setIsMultipleModalOpen] = useState(false);
  const [multiples, setMultiples] = useState(initialMultiples);
  const [uploadedImage, setUploadedImage] = useState<string>('');
  const { showSnackbar } = useContext(SnackbarContext);
  const [adjustedEBITDAInfo, setAdjustedEBITDAInfo] =
    useState<AdjustedEBITDAInfoResponse | null>(null);
  const [addBackAnswers, setAddBackAnswers] = useState<{
    [key: string]: { [key: string]: string }
  }>({});
  const [startEBITDAMultiple, setStartEBITDAMultiple] = useState<number>(0);
  const [uploadedFileId, setUploadedFileId] = useState<string>('');

  const calculateValuation = useCallback(async () => {
    setIsLoading(true);
    try {
      const formattedAddBackAnswers = adjustedEBITDAInfo!.possibleAddBacks.flatMap((expense) =>
        expense.questions.map((question) => ({
          expenseName: expense.expenseName,
          totalExpenseAmount: expense.amount,
          question,
          answer: addBackAnswers[expense.expenseName]?.[question] || '',
        })),
      );

      const res = await api.clients.calculateValuation(id!, {
        ebitda: adjustedEBITDAInfo!.ebitda,
        addBackAnswers: formattedAddBackAnswers,
        useO1: false,
      });
      setResult(res.data);
    } catch (error) {
      showSnackbar({
        message: 'Failed to calculate valuation',
        color: 'danger',
      });
    } finally {
      setIsLoading(false);
    }
  }, [id, api, adjustedEBITDAInfo, addBackAnswers, showSnackbar]);

  const getAdjustedEBITDAInfo = useCallback(async (fileId: string) => {
    setIsLoading(true);
    try {
      const res = await api.clients.getAdjustedEbitdaInfo(id!, {
        incomeStatementFileId: fileId,
      });
      setAdjustedEBITDAInfo(res.data);
      setStartEBITDAMultiple(res.data.initialMultiplier);
    } catch (error) {
      showSnackbar({
        message: 'Failed to get adjusted EBITDA info',
        color: 'danger',
      });
    } finally {
      setIsLoading(false);
    }
  }, [id, api, showSnackbar]);

  const onDropAccepted = useCallback(async (acceptedFiles: File[]) => {
    setIsUploading(true);
    try {
      const signedUrlRes = await api.files.createSignedUrl();
      await axios.put(
        signedUrlRes.data.url.replace(/"/g, ''),
        acceptedFiles[0],
        {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        },
      );
      const { fileId } = signedUrlRes.data;
      setUploadedImage(uploadedLink(fileId));
      setUploadedFileId(fileId);
      showSnackbar({
        message: 'Image uploaded successfully!',
        color: 'success',
      });
    } catch (error) {
      showSnackbar({
        message: 'Failed to upload image',
        color: 'danger',
      });
    } finally {
      setIsUploading(false);
    }
  }, [api.files, showSnackbar]);

  const handleAddBackChange = useCallback((
    expenseName: string, question: string, value: string) => {
    setAddBackAnswers((prev) => ({
      ...prev,
      [expenseName]: {
        ...prev[expenseName],
        [question]: value,
      },
    }));
  }, []);

  const calculateTotalMultiple = () => {
    if (!result) return 0;
    return Number((Number(startEBITDAMultiple) + Object.values(result.multipleAdjustments).reduce(
      (acc: number, factor: any) =>
        acc + multiples[Math.round(factor.score) as keyof typeof multiples], 0)).toFixed(1));
  };

  const calculateValuationAmount = () => {
    const totalMultiple = calculateTotalMultiple();
    return Number(result!.adjustedEBITDA) * totalMultiple;
  };

  const onDropRejected = useCallback(() => {
    showSnackbar({
      color: 'danger',
      message: 'Only image files are supported!',
    });
  }, [showSnackbar]);

  const handleSubmit = useCallback(() => {
    if (uploadedFileId) {
      getAdjustedEBITDAInfo(uploadedFileId);
    }
  }, [uploadedFileId, getAdjustedEBITDAInfo]);

  const handleRemoveImage = useCallback(() => {
    setUploadedImage('');
    setUploadedFileId('');
  }, []);

  const fetchData = useCallback(async () => {
    const res = await api.clients.getProject(id!);
    setProject(res.data);
  }, [api, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <IconButton onClick={() => navigate(`/clients/${id}`)}>
          <ChevronLeft />
        </IconButton>
        <div className={classes.headerItem}>
          <Typography level="h3">Valuation Calculator</Typography>
          <Typography level="body-md">
            Calculate Adjusted EBITDA and valuation estimate for {project?.name}
          </Typography>
        </div>
      </div>
      <LoadingContainer isLoading={isLoading}>
        <>
          {!adjustedEBITDAInfo && (
            <Card variant="plain" className={classes.card}>
              <div className={classes.startScreen}>
                <Typography level="body-lg">Upload a screenshot of the client's income statement</Typography>
                <Dropzone
                  accept={{ 'image/*': [] }}
                  onDropAccepted={onDropAccepted}
                  onDropRejected={onDropRejected}
                  multiple={false}
                  disabled={isLoading}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className={classes.uploadZone}>
                      <input {...getInputProps()} />
                      {uploadedImage ? (
                        <>
                          <img
                            src={uploadedImage}
                            alt="Uploaded image"
                            className={classes.uploadedImage}
                          />
                          <IconButton
                            className={classes.removeImageButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveImage();
                            }}
                            size="sm"
                          >
                            <CloseIcon />
                          </IconButton>
                        </>
                      ) : (
                        <div className={classes.emptyState}>
                          <Typography level='body-md'>
                            {isUploading ? 'Uploading...' : 'Drop an image here or click to upload'}
                          </Typography>
                        </div>
                      )}
                    </div>
                  )}
                </Dropzone>
                <Button
                  size="lg"
                  onClick={handleSubmit}
                  disabled={!uploadedFileId || isLoading}
                >
                  Submit
                </Button>
              </div>
            </Card>
          )}
          {adjustedEBITDAInfo && !result && (
            <Card variant="plain" className={classes.card}>
              <Typography level="h3">Possible EBITDA Adjustments</Typography>
              <Typography level="body-md">
                Your EBITDA was calculated to be <b>{formatCurrency(adjustedEBITDAInfo.ebitda)}
                </b>. There were up to <b>{formatCurrency(adjustedEBITDAInfo.totalPossibleAddBacks)}
                </b> in expenses that could be added back for your adjusted EBITDA.
              </Typography>
              <Typography level="body-sm" className={classes.explanation}>
                {adjustedEBITDAInfo.ebitdaExplanation}
              </Typography>
              {adjustedEBITDAInfo.possibleAddBacks.map((a) => (
                <div key={a.expenseName} className={classes.expense}>
                  <div className={classes.row}>
                    <Typography level="body-md">
                      <b>{a.expenseName}: {formatCurrency(a.amount)}</b>
                    </Typography>
                  </div>
                  {a.questions.map((question) => (
                    <React.Fragment key={question}>
                      <Typography level="body-md">{question}</Typography>
                      <Input
                        value={addBackAnswers[a.expenseName]?.[question] || ''}
                        onChange={(e) =>
                          handleAddBackChange(a.expenseName, question, e.target.value)}
                        className={classes.addBackInput}
                        fullWidth
                      />
                    </React.Fragment>
                  ))}
                </div>
              ))}
              <Button onClick={calculateValuation}>Calculate Valuation</Button>
            </Card>
          )}
          {result && (
            <>
              <Card variant="plain" className={classes.card}>
                <div className={classes.headerRow}>
                  <div className={classes.headerItem}>
                    <Typography level="body-lg">
                      <b>{formatCurrency(calculateValuationAmount())}</b>
                    </Typography>
                    <Typography level="body-sm">Valuation</Typography>
                  </div>
                  <div className={classes.headerItem}>
                    <Typography level="body-lg">
                      <b>{formatCurrency(result!.adjustedEBITDA)}</b>
                    </Typography>
                    <Typography level="body-sm">Adjusted EBITDA</Typography>
                  </div>
                  <div className={classes.headerItem}>
                    <Typography level="body-lg">
                      <b>{startEBITDAMultiple}x</b>
                    </Typography>
                    <Typography level="body-sm">Base EBITDA Multiple</Typography>
                  </div>
                  <div className={classes.headerItem}>
                    <Typography level="body-lg">
                      <b>{calculateTotalMultiple()}x</b>
                    </Typography>
                    <Typography level="body-sm">Final EBITDA Multiple</Typography>
                  </div>
                  <Tooltip title="Adjust Multiples">
                    <IconButton
                      variant="outlined"
                      onClick={() => setIsMultipleModalOpen(true)}
                    >
                      <Tune />
                    </IconButton>
                  </Tooltip>
                </div>
              </Card>
              <Card variant="plain" className={classes.card}>
                <Table className={classes.resultTable}>
                  <thead>
                    <tr>
                      <th className={classes.factorCell}>Factor</th>
                      <th className={classes.scoreCell}>Multiple</th>
                      <th className={classes.observationCell}>Observation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(result!.multipleAdjustments)
                      .map(([key, value]: [string, any]) => (
                        <tr key={key}>
                          <td className={classes.factorCell}><b>{factorNameMap[key] || key}</b></td>
                          <td className={classes.scoreCell}>
                            <Typography
                              level="body-md"
                              sx={{ color: getScoreColor(value.score) }}
                              fontWeight="bold"
                            >
                              {multiples[Math.round(value.score) as keyof typeof multiples]}x
                            </Typography>
                          </td>
                          <td className={classes.observationCell}>{value.observation}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card>
              <Card variant="plain" className={classes.card}>
                <Typography level="h4">Expense Adjustments</Typography>
                <Typography level="body-sm" className={classes.explanation}>
                  {result!.adjustedEbitdaExplanation}
                </Typography>
                {result!.expenseAdjustments
                  .filter((a) => a.adjustedAmount !== a.originalAmount)
                  .map((adjustment, index) => (
                    <div key={index} className={classes.adjustedExpense}>
                      <Typography level="body-md" fontWeight="bold">
                      {adjustment.expenseName}
                    </Typography>
                    <div className={classes.adjustmentAmount}>
                      <Typography level="body-md" className={classes.strikethrough}>
                        {formatCurrency(adjustment.originalAmount)}
                      </Typography>
                      <Typography level="body-md">
                        {formatCurrency(adjustment.adjustedAmount)}
                      </Typography>
                    </div>
                    <Typography level="body-md">
                      {adjustment.explanation}
                    </Typography>
                  </div>
                  ))}
              </Card>
            </>
          )}
        </>
      </LoadingContainer>
      <Modal
        open={isMultipleModalOpen}
        onClose={() => setIsMultipleModalOpen(false)}
      >
        <ModalDialog>
          <ModalClose />
          <Typography level="h4" component="h2" sx={{ mb: 2 }}>
            Adjust Multiples
          </Typography>
          <Table className={classes.multiplesTable}>
            <thead>
              <tr>
                <th className={classes.factorCell}>Factor</th>
                <th>---</th>
                <th>--</th>
                <th>-</th>
                <th>0</th>
                <th>+</th>
                <th>++</th>
                <th>+++</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(factorNameMap).map((factor) => (
                <tr key={factor}>
                  <td>{factorNameMap[factor]}</td>
                  {[-3, -2, -1, 0, 1, 2, 3].map((score) => (
                    <td key={score}>
                      <Input
                        type="number"
                        value={multiples[score as keyof typeof multiples]}
                        onChange={(e) => setMultiples((prev) => ({
                          ...prev,
                          [score]: parseFloat(e.target.value),
                        }))}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <Button onClick={() => setIsMultipleModalOpen(false)} sx={{ mt: 2 }}>
            Submit
          </Button>
        </ModalDialog>
      </Modal>
    </div>
  );
}
