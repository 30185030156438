import React from 'react';
import { tss } from 'tss-react';
import {
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { GetListResponse } from 'src/generated/api';
import { Language } from '@mui/icons-material';
import normalizeUrl from 'normalize-url';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    companyInfoRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      alignItems: 'center',
      height: '100%',
      width: '100%',
      cursor: 'pointer',
    },
    companyTitle: {
      fontWeight: 600,
    },
    companyInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    actions: {
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    icon: {
      width: 18,
      height: 18,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 2,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }));

type Props = {
  business: GetListResponse['businesses'][number];
  setDrawerId: (id: string) => void;
};

export default function AboutCell({
  business,
  setDrawerId,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  return (
    <div
      onClick={() => setDrawerId(business.id)}
      className={classes.companyInfoRow}
    >
      <div
        className={classes.companyInfo}
      >
        <Typography
          level='body-md'
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
          }}
        >
          <b>
            {business.name}
          </b>
        </Typography>
        <div
          className={classes.row}
          onClick={(e) => {
            window.open(normalizeUrl(business.domain));
            e.stopPropagation();
          }}
        >
          <Language className={classes.icon} />
          <Typography level='body-sm'>
            { business.domain }
          </Typography>
        </div>
      </div>
    </div>
  );
}
