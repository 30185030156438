import React, { useState } from 'react';
import { tss } from 'tss-react';
import {
  Typography,
  Theme,
  useTheme,
} from '@mui/joy';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    cell: {
      wordBreak: 'break-word',
    },
    clickableCell: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      alignItems: 'center',
      gap: 2,
      wordBreak: 'break-word',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.neutral.plainHoverBg,
      },
    },
  }));

type EnrichmentSimpleValueProps = {
  value: string | null;
};

export default function SimpleCell({
  value,
}: EnrichmentSimpleValueProps) {
  const [showAll, setShowAll] = useState(false);
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  if (!value || value.length < 120) {
    return (
      <div className={classes.cell}>
        <Typography
          level='body-sm'
          textColor='text.primary'
        >
          {value}
        </Typography>
      </div>
    );
  }
  return (
    <div
      className={classes.clickableCell}
      onClick={() => setShowAll(!showAll)}
    >
      <Typography
        level='body-sm'
        textColor='text.primary'
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: showAll ? undefined : 2,
        }}
      >
          {value}
      </Typography>
    </div>
  );
}
