import React, { useCallback, useRef, useState } from 'react';
import { tss } from 'tss-react';
import {
  Button,
  IconButton,
  Sheet,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {
  GRID_CHECKBOX_SELECTION_FIELD,
  GridPagination,
  useGridApiContext,
  GridSlotsComponentsProps,
  GridPreferencePanelsValue,
} from '@mui/x-data-grid-pro';
import {
  FilterList,
  ViewWeek,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { Popper } from '@mui/material';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      width: '100%',
    },
    columnsButton: {
      margin: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    popper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      boxShadow: theme.shadow.lg,
      height: 400,
      overflowY: 'scroll',
      padding: theme.spacing(1),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    icon: {
      width: 20,
      height: 20,
    },
    paginationContainer: {
      marginLeft: 'auto',
    },
  }));

export default function DataGridFooter({
  setFilterButtonEl,
}: GridSlotsComponentsProps['footer'] & { setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>> }) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const [popperOpen, setPopperOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  // Super hacky shit because this API is shit
  const apiRef = useGridApiContext();
  const [visibleCells, setVisibleCells] = useState<string[]>([]);
  const updateVisibleCells = useCallback(() => {
    setTimeout(() => {
      setVisibleCells(
        () => apiRef.current.getVisibleColumns().map((col) => col.field),
      );
    }, 0);
  }, [apiRef]);
  return (
    <div className={classes.container}>
      <Button
        variant='outlined'
        color='primary'
        className={classes.columnsButton}
        ref={anchorRef}
        onClick={() => {
          updateVisibleCells();
          if (popperOpen) {
            setPopperOpen(false);
          } else {
            setPopperOpen(true);
          }
        }}
      >
        <ViewWeek />
        Edit View
      </Button>
      <Popper
        open={popperOpen}
        anchorEl={anchorRef.current}
        placement='top-start'
      >
        <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
          <Sheet className={classes.popper}>
            <>
              {
                apiRef.current.getAllColumns().filter(
                  (col) => col.field !== GRID_CHECKBOX_SELECTION_FIELD &&
                    col.field !== 'about',
                ).map((col) => (
                  <div className={classes.row} key={col.field}>
                    <Typography level='body-sm'>
                      { col.headerName }
                    </Typography>
                    {
                      (visibleCells.includes(col.field)) ?
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            apiRef.current?.setColumnVisibility(
                              col.field,
                              false,
                            );
                            updateVisibleCells();
                          }}
                        >
                          <Visibility className={classes.icon} />
                        </IconButton> :
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            apiRef.current?.setColumnVisibility(
                              col.field,
                              true,
                            );
                            updateVisibleCells();
                          }}
                        >
                          <VisibilityOff className={classes.icon} />
                        </IconButton>
                    }
                  </div>
                ))
              }
            </>
          </Sheet>
        </ClickAwayListener>
      </Popper>
      <Button
        variant='outlined'
        color='primary'
        className={classes.columnsButton}
        ref={setFilterButtonEl}
        onClick={() => {
          apiRef.current.showPreferences(GridPreferencePanelsValue.filters);
        }}
      >
        <FilterList />
        Filters
      </Button>
      <div className={classes.paginationContainer}>
        <GridPagination />
      </div>
    </div>
  );
}
