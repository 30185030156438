import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Chip,
  List,
  ListItem,
  Textarea,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';
import dayjs, { Dayjs } from 'dayjs';
import { tss } from 'tss-react';
import {
  Drafts,
  Edit,
  Email,
  Reply,
  Save,
  Send,
} from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { getAPI } from 'src/api';
import { GetAutopilotBusinessViewResponse } from 'src/generated/api';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    email: {
      wordBreak: 'break-word',
      whiteSpace: 'pre-line',
    },
    emailTitleRow: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: 13,
      paddingRight: 13,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    emailActions: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    emailTitleText: {
      display: 'flex',
      flexDirection: 'column',
    },
    readContent: {
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 13,
      paddingRight: 13,
    },
    actionButton: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    clickableChip: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.neutral.plainHoverBg,
      },
    },
    chip: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 2,
    },
    emailTitleTextRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
  }));

type Props = {
  campaignId: string;
  businessId: string;
  isFollowUp: boolean;
  recipientReplied: boolean
  approved: boolean;
  subject: string;
  contactInfo: {
    firstName: string | null;
    lastName: string | null;
    emailAddress: string | null;
  };
  email: GetAutopilotBusinessViewResponse['sequence']['emails'][number];
  refetchData: () => void;
};

export default function EngageEmail({
  campaignId,
  businessId,
  isFollowUp,
  recipientReplied,
  refetchData,
  approved,
  subject,
  contactInfo,
  email,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const [isEditing, setIsEditing] = useState(false);
  const [sendTime, setSentTime] = useState<Dayjs | null>(dayjs(email.sendTime));
  const [content, setContent] = useState(email.value);
  const [isSending, setIsSending] = useState(false);
  const saveEdit = useCallback(async () => {
    const api = getAPI();
    setIsEditing(false);
    await api.autopilot.updateEmail(campaignId, businessId, email.colId, {
      content,
      sendTime: sendTime?.toDate().toISOString() ?? null,
    });
    refetchData();
  }, [refetchData, campaignId, businessId, email, content, sendTime]);
  const sendEmail = useCallback(async () => {
    setIsSending(true);
    const api = getAPI();
    await api.autopilot.sendEmail(campaignId, {
      colId: email.colId,
      businessId,
    });
    await refetchData();
    setIsSending(false);
  }, [email, businessId, campaignId, refetchData]);

  let sentChip = (
    <Chip
      variant='outlined'
      size='lg'
      slotProps={{
        label: {
          className: classes.chip,
        },
      }}
    >
      <Email />
      Sent
    </Chip>
  );
  if (recipientReplied) {
    sentChip = (
      <Chip
        variant='outlined'
        size='lg'
        slotProps={{
          label: {
            className: classes.chip,
          },
        }}
      >
        <Reply />
        Replied
      </Chip>
    );
  } else if (email.trackingEvents.length > 0) {
    sentChip = (
      <Chip
        variant='outlined'
        size='lg'
        slotProps={{
          label: {
            className: classes.chip,
          },
        }}
      >
        <Drafts />
        Opened
      </Chip>
    );
  }

  // Its possible that no opens were tracked, but has replied if theres a blocker
  if (email.trackingEvents.length > 0) {
    sentChip = (
      <Tooltip
        variant='outlined'
        title={
          <Box>
            <List>
              {
                email.trackingEvents.map((t) => (
                  <ListItem>
                    <Typography level='body-sm'>
                      {(new Date(t.eventTime)).toLocaleDateString('en-us', {
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: '2-digit',
                      })}
                    </Typography>
                  </ListItem>
                ))
              }
            </List>
          </Box>
        }
      >
        {sentChip}
      </Tooltip>
    );
  }
  return (
    <Card className={classes.email}>
      <div className={classes.emailTitleRow}>
        <div className={classes.emailTitleText}>
          <div className={classes.emailTitleTextRow}>
            <Typography level='body-md'>
              <b>{ isFollowUp ? 'Re:' : '' }{ subject }</b>
            </Typography>
            {
              approved && sendTime && !email.sent && !isEditing && !recipientReplied &&
                <Chip variant='outlined'>
                  {
                    sendTime.toDate().toLocaleDateString('en-us', {
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    })
                  }
                </Chip>
            }
          </div>
          <Typography level='body-md'>
            <i>
              To: { contactInfo.firstName } { contactInfo.lastName } &lt;{
                contactInfo.emailAddress }&gt;
            </i>
          </Typography>
        </div>
        {
          email.sent ? (
            sentChip
          ) :
            <div className={classes.emailActions}>
              {
                isEditing ?
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label='Send Time'
                        value={sendTime}
                        onChange={(val) => setSentTime(val)}
                      />
                    </LocalizationProvider>
                    <Button
                      size='md'
                      color='success'
                      variant='solid'
                      className={classes.actionButton}
                      onClick={saveEdit}
                    >
                      <Save />
                      Save
                    </Button>
                  </> :
                  <>
                    <Button
                      size='md'
                      color='primary'
                      variant='outlined'
                      onClick={() => setIsEditing(true)}
                      className={classes.actionButton}
                    >
                      <Edit />
                      Edit
                    </Button>
                    <Button
                      size='md'
                      color='success'
                      variant='outlined'
                      className={classes.actionButton}
                      onClick={sendEmail}
                      loading={isSending}
                    >
                      <Send />
                      Send Now
                    </Button>
                  </>
              }
            </div>
        }
      </div>
      {
        isEditing ?
          <Textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            minRows={3}
            size='md'
          /> :
          <Typography level='body-md' className={classes.readContent}>
            { content }
          </Typography>
      }
    </Card>
  );
}
