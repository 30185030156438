import { CircularProgress, Theme, useTheme } from '@mui/joy';
import React from 'react';
import { tss } from 'tss-react';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    loadingContainer: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(8),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }));

type Props = {
  isLoading: boolean;
  children: JSX.Element | null;
};

export default function LoadingContainer({
  isLoading,
  children,
}: Props): JSX.Element | null {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  if (!isLoading) {
    return children;
  }
  return (
    <div className={classes.loadingContainer}>
      <CircularProgress />
    </div>
  );
}
