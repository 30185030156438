import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import { AuthContextProvider } from 'src/contexts/auth';
import { SnackbarContextProvider } from 'src/contexts/snackbar';
import Router from './router';

export default function App() {
  return (
    <AuthContextProvider>
      <SnackbarContextProvider>
        <Router />
      </SnackbarContextProvider>
    </AuthContextProvider>
  );
}

window.addEventListener('error', (e) => {
  if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
    const resizeObserverErrDiv = document.getElementById(
      'webpack-dev-server-client-overlay-div',
    );
    const resizeObserverErr = document.getElementById(
      'webpack-dev-server-client-overlay',
    );
    if (resizeObserverErr) {
      resizeObserverErr.setAttribute('style', 'display: none');
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute('style', 'display: none');
    }
  }
});

Sentry.init({
  dsn: 'https://84fce756eabf172f7f25f077b9b95906@o4506756024631296.ingest.sentry.io/4506756182376448',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/api\.offdeal\.io/],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<App />);
