import React from 'react';
import { tss } from 'tss-react';
import {
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { Cancel, CheckCircle, Info } from '@mui/icons-material';
import { GetAutopilotManageResearchResponse } from 'src/generated/api';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    cell: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
      height: '100%',
      width: '100%',
      cursor: 'pointer',
    },
    booleanIcon: {
      width: 16,
      height: 16,
    },
  }));

type Props = {
  value?: GetAutopilotManageResearchResponse['businesses'][number]['requirements'][number];
  onClick: () => void;
};

export default function RequirementResultCell({
  value,
  onClick,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  if (value == null) {
    return <div className={classes.cell} />;
  }
  if (value.error) {
    return (
      <div className={classes.cell}>
        <Info
          color='error'
        />
        <Typography level='body-sm' color='danger'>
          <i>
            { value.error }
          </i>
        </Typography>
      </div>
    );
  }
  return (
    <div className={classes.cell} onClick={onClick}>
      {
        value.value ?
          <CheckCircle
            color='success'
          /> :
          <Cancel
            color='error'
          />
      }
      <Typography level='body-sm' textColor='text.primary'>
        { value.explanation }
      </Typography>
    </div>
  );
}
