import React, {
  useRef,
  useState,
} from 'react';
import {
  Edit,
  Delete,
  MoreVert,
} from '@mui/icons-material';
import {
  Typography,
  Theme,
  useTheme,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/joy';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { tss } from 'tss-react';

export type Props = {
  title: string;
  showEditModal: () => void;
  onDelete?: () => void;
};

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    header: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'flex-end',
    },
    btn: {
      width: 24,
      height: 24,
      minHeight: 0,
      minWidth: 0,
      padding: 0,
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    icon: {
      width: 20,
      height: 20,
    },
    colHeaderText: {
      textWrap: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  }));

export default function ColHeader({
  title,
  showEditModal,
  onDelete,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const moreEl = useRef<HTMLButtonElement>(null);
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  return (
    <div className={classes.header}>
      <Typography level='body-md' className={classes.colHeaderText}>
        <b>
          {title}
        </b>
      </Typography>
      <div className={classes.actions}>
        <IconButton
          color='primary'
          className={classes.btn}
          onClick={(e) => {
            setShowMoreMenu(!showMoreMenu);
            e.stopPropagation();
          }}
          ref={moreEl}
          variant='outlined'
        >
          <MoreVert className={classes.icon} />
        </IconButton>
        <ClickAwayListener onClickAway={() => setShowMoreMenu(false)}>
          <Menu
            placement='bottom-end'
            open={showMoreMenu}
            onClose={() => setShowMoreMenu(false)}
            anchorEl={moreEl.current}
          >
            <MenuItem onClick={() => {
              setShowMoreMenu(false);
              showEditModal();
            }}>
              <Edit />
              Edit
            </MenuItem>
            {
              onDelete &&
                <MenuItem
                  color='danger'
                  onClick={async () => {
                    onDelete();
                    setShowMoreMenu(false);
                  }}
                >
                  <Delete />
                  Delete
                </MenuItem>
            }
          </Menu>
        </ClickAwayListener>
      </div>
    </div>
  );
}
