import React, { useCallback, useContext, useState } from 'react';
import { tss } from 'tss-react';

import {
  Sheet,
  Theme,
  Input,
  Typography,
  useTheme,
  FormLabel,
  Button,
  CircularProgress,
  Card,
  Chip,
} from '@mui/joy';
import { AuthContext } from 'src/contexts/auth';
import LoadingContainer from 'src/components/LoadingContainer';
import { getAPI } from 'src/api';
import { PrepMeetingResponse } from 'src/generated/api';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: theme.spacing(4),
    },
    content: {
      width: 800,
      display: 'flex',
      flexDirection: 'column',
    },
    sheet: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(4),
      boxShadow: theme.shadow.md,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    chips: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));

export default function Prep() {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const { user, org } = useContext(AuthContext);
  const [domain, setDomain] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PrepMeetingResponse | null>(null);
  const onSubmit = useCallback(async () => {
    const api = getAPI();
    setLoading(true);
    const res = await api.internal.prepMeeting({
      domain,
    });
    setLoading(false);
    setData(res.data);
  }, [domain]);
  return (
    <LoadingContainer isLoading={!org || !user}>
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography level="h3" className={classes.title}>
            Meeting Prep
          </Typography>
          <Sheet className={classes.sheet}>
            {
              loading ?
                <CircularProgress /> :
                data ?
                  <>
                    <Typography level='h4'>
                      Business Overview - {data.name}
                    </Typography>
                    {
                      data.foundingYear &&
                        <Typography level='body-md'>
                          <i>Founded <b>{data.foundingYear}</b></i>
                        </Typography>
                    }
                    <Typography level='body-md'>
                      { data.description }
                    </Typography>
                    {
                      data.locations &&
                        <div className={classes.chips}>
                          {
                            data.locations.map((l) =>
                              <Chip variant='outlined' key={l}>
                                {l}
                              </Chip>)
                          }
                        </div>
                    }
                    <Typography level='body-lg'>
                      <b>Key Services</b>
                    </Typography>
                    {
                      data.keyServices.map((ks) => (
                        <Card variant='outlined'>
                          <Typography level='body-md'>
                            <b>{ks.name}</b>
                          </Typography>
                          <Typography level='body-sm'>
                            {ks.description}
                          </Typography>
                          <Typography level='body-sm'>
                            <b>Target Audience</b> - {ks.targetAudience}
                          </Typography>
                          <Typography level='body-sm'>
                            <b>Business Model</b> - {ks.businessModel}
                          </Typography>
                          <Typography level='body-sm'>
                            <b>Typical Margin</b> - {ks.typicalMargin}
                          </Typography>
                          <Typography level='body-sm'>
                            <b>Revenue</b>: {ks.revenueFormula}
                          </Typography>
                          <Typography level='body-sm'>
                            <b>Costs</b>: {ks.costFormula}
                          </Typography>
                        </Card>
                      ))
                    }
                    <Typography level='body-lg'>
                      <b>Typical End Customers</b>
                    </Typography>
                    {
                      data.typicalEndCustomers.map((c) =>
                        <Card key={c.name}>
                          <Typography level='body-md'>
                            <b>{c.name}</b>
                          </Typography>
                          <Typography level='body-sm'>
                            {c.description}
                          </Typography>
                        </Card>)
                    }
                  </> :
                  <>
                    <FormLabel>
                      Website
                    </FormLabel>
                    <Input
                      value={domain}
                      onChange={(e) => setDomain(e.target.value)}
                    />
                    <Button
                      onClick={onSubmit}
                    >
                      Submit
                    </Button>
                  </>
            }
          </Sheet>
        </div>
      </div>
    </LoadingContainer>
  );
}
