import { PlayArrow } from '@mui/icons-material';
import {
  Typography,
  Theme,
  useTheme,
  IconButton,
} from '@mui/joy';
import React, { useCallback, useState } from 'react';
import { GetListResponse } from 'src/generated/api';
import { getAPI } from 'src/api';
import { tss } from 'tss-react';
import { EnrichmentColProps } from './Header';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .withNestedSelectors<'buttons'>()
  .create(({ theme, classes }) => ({
    cellContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      gap: 0,
      [`&:hover .${classes.buttons}`]: {
        display: 'flex',
      },
    },
    clickableCellContainer: {
      cursor: 'pointer',
    },
    cell: {
      wordBreak: 'break-word',
    },
    clickableCell: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      alignItems: 'center',
      gap: 2,
      wordBreak: 'break-word',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.neutral.plainHoverBg,
      },
    },
    enrichmentHeader: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'flex-end',
    },
    enrichmentButton: {
      width: 28,
      height: 28,
      minHeight: 0,
      minWidth: 0,
      padding: 0,
    },
    enrichActions: {
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    enrichmentIcon: {
      width: 20,
      height: 20,
    },
    colHeaderText: {
      textWrap: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    buttons: {
      display: 'none',
      marginLeft: 'auto',
      gap: 2,
    },
    button: {
      width: 24,
      height: 24,
      minHeight: 24,
      minWidth: 24,
      padding: 0,
    },
    buttonIcon: {
      width: 20,
      height: 20,
    },
  }));

export type Props = {
  enrichmentColProps: EnrichmentColProps;
  businessId: string;
  children: JSX.Element | null;
  value?: GetListResponse['businesses'][number]['enrichmentValues'][number];
  onInfoClick?: () => void;
};

export default function EnrichmentCellWrapper({
  enrichmentColProps,
  businessId,
  value,
  children,
  onInfoClick,
}: Props) {
  const theme = useTheme();
  const { classes, cx } = useStyles({ theme });
  const [showAll, setShowAll] = useState(false);
  const runEnrichment = useCallback(async () => {
    const api = getAPI();
    await api.lists.runSpecificEnrichments(
      enrichmentColProps.listId,
      {
        colIds: [enrichmentColProps.col.id],
        businessIds: [businessId],
      },
    );
    setTimeout(() => {
      enrichmentColProps.refetch();
    }, 1000);
  }, [businessId, enrichmentColProps]);
  const playButton = (
    <IconButton
      className={classes.button}
      color='success'
      variant='outlined'
      size='sm'
      onClick={(e) => {
        e.stopPropagation();
        runEnrichment();
      }}
    >
      <PlayArrow className={classes.buttonIcon} />
    </IconButton>
  );

  if (value) {
    if (value.status === 'QUEUED') {
      return (
        <div className={classes.cell}>
          <Typography level='body-sm' textColor='tertiary'>
            <i>
              Queued
            </i>
          </Typography>
        </div>
      );
    }
    if (value.status === 'RUNNING') {
      return (
        <div className={classes.cell}>
          <Typography
            level='body-sm'
            color='primary'
          >
            <i>
              Running...
            </i>
          </Typography>
        </div>
      );
    }
    if (value.status === 'ERROR') {
      if (value.error && value.error!.length < 120) {
        return (
          <div className={classes.cellContainer}>
            <div className={classes.cell}>
              <Typography level='body-sm' sx={{ wordBreak: 'break-word' }}>
                <i>{value.error}</i>
              </Typography>
            </div>
            <div className={classes.buttons}>
              { playButton }
            </div>
          </div>
        );
      }
      return (
        <div className={classes.cellContainer}>
          <div
            className={classes.clickableCell}
            onClick={() => setShowAll(!showAll)}
          >
            <Typography
              level='body-sm'
              color='danger'
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: showAll ? undefined : 2,
              }}
            >
              <i>
                {value.error}
              </i>
            </Typography>
          </div>
          <div className={classes.buttons}>
            { playButton }
          </div>
        </div>
      );
    }
    if (value.status === 'COMPLETE') {
      if (onInfoClick) {
        return (
          <div
            className={cx(classes.cellContainer, classes.clickableCellContainer)}
            onClick={onInfoClick}
          >
            { children }
            <div className={classes.buttons}>
              { playButton }
            </div>
          </div>
        );
      }
      return (
        <div className={classes.cellContainer}>
          { children }
          <div className={classes.buttons}>
            { playButton }
          </div>
        </div>
      );
    }
  }
  return (
    <div className={classes.cellContainer}>
      <div className={classes.cell} />
      <div className={classes.buttons}>
        { playButton }
      </div>
    </div>
  );
}
