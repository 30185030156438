import React, { useCallback, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import {
  Button,
  ButtonGroup,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  MenuList,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import {
  ChevronLeft,
  RocketLaunch,
  Settings,
} from '@mui/icons-material';
import { tss } from 'tss-react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingContainer from 'src/components/LoadingContainer';
import { GetAutopilotViewResponse } from 'src/generated/api';
import { getAPI } from 'src/api';
import EngageBusinessView from './BusinessView';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      padding: theme.spacing(2),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    contents: {
      display: 'flex',
      flexDirection: 'row',
      height: '80vh',
      width: '100%',
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    businessList: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      maxWidth: 300,
      width: 300,
      height: '100%',
      boxShadow: theme.shadow.lg,
      borderRadius: theme.spacing(1),
    },
    listItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: theme.spacing(1),
      cursor: 'pointer',
      gap: 0,
    },
    listItemTitle: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    listItemTitleText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    chip: {
      marginLeft: 'auto',
      height: 24,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    headerLeft: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    buttons: {
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      height: 48,
    },
    businessView: {
      boxShadow: theme.shadow.lg,
      flex: 1,
      overflowY: 'auto',
    },
    noneSelectedView: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      marginTop: theme.spacing(4),
      gap: theme.spacing(1),
    },
    configureButton: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    emptyState: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(4),
      gap: theme.spacing(1),
      width: '100%',
    },
    contactSupport: {
      marginTop: theme.spacing(1),
    },
  }));

export default function AutopilotView() {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const { id } = useParams();
  const navigate = useNavigate();
  const api = getAPI();
  const intercom = useIntercom();
  const [showFailed, setShowFailed] = useState(false);
  const [selectedBusinessId, setSelectedBusinessId] = useState<string | null>(null);
  const [data, setData] = useState<GetAutopilotViewResponse | null>(null);
  const fetchData = useCallback(async (resetSelected = false) => {
    const res = await api.autopilot.getAutopilot(id!, { showFailed });
    setData(res.data);
    if (resetSelected) {
      setSelectedBusinessId(null);
    }
  }, [api, id, showFailed]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    // Hacky workaround until websockets are added
    const handle = setInterval(() => {
      if (
        data && data.businesses.some((b) => b.status === 'PROCESSING')
      ) {
        fetchData();
      }
    }, 3000);
    return () => {
      clearInterval(handle);
    };
  }, [fetchData, data]);
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <IconButton onClick={() => navigate('/autopilot')}>
          <ChevronLeft />
        </IconButton>
        <div className={classes.headerLeft}>
          <Typography level="h3" className={classes.title}>
            <RocketLaunch />
            { data?.name }
          </Typography>
          <Typography level='body-lg'>
            Completely automate your deal sourcing with our AI broker
          </Typography>
        </div>
        <div className={classes.buttons}>
          <ButtonGroup>
            <Button
              variant={showFailed ? 'outlined' : 'solid'}
              onClick={() => setShowFailed(false)}
              color='primary'
            >
              Qualified Only
            </Button>
            <Button
              variant={showFailed ? 'solid' : 'outlined'}
              color='primary'
              onClick={() => setShowFailed(true)}
            >
              All Researched
            </Button>
          </ButtonGroup>
          <Button
            variant='outlined'
            color='neutral'
            onClick={() => navigate(`/autopilot/${id}/manage`)}
            className={classes.configureButton}
          >
            <Settings />
            Manage
          </Button>
        </div>
      </div>
      <LoadingContainer isLoading={data?.businesses == null}>
        <div className={classes.contents}>
          {
            data?.businesses.length === 0 ?
              <div className={classes.emptyState}>
                <Typography level='h3'>
                  This campaign needs to be set up
                </Typography>
                <Typography level='body-lg'>
                  Reach out to your OffDeal representative to set up this Autopilot Campaign
                </Typography>
                <Button
                  size='lg'
                  className={classes.contactSupport}
                  onClick={() => intercom.show()}
                >
                  Contact Support
                </Button>
              </div> :
              <>
                <MenuList
                  className={classes.businessList}
                  variant='plain'
                >
                  <List>
                    {
                      data?.businesses.map((b) => (
                        <ListItem key={b.id}>
                          <ListItemButton
                            color={selectedBusinessId === b.id ? 'primary' : 'neutral'}
                            variant={selectedBusinessId === b.id ? 'soft' : 'plain'}
                            className={classes.listItem}
                            onClick={() => setSelectedBusinessId(b.id)}
                          >
                            <div className={classes.listItemTitle}>
                              <Typography level='body-md' className={classes.listItemTitleText}>
                                <b>
                                  {b.name}
                                </b>
                              </Typography>
                              {
                                b.status === 'WAITING_FOR_APPROVAL' &&
                                  <Chip color='warning' variant='outlined' className={classes.chip}>
                                    Pending Approval
                                  </Chip>
                              }
                              {
                                b.status === 'APPROVED' &&
                                  <Chip color='success' variant='outlined' className={classes.chip}>
                                    Scheduled
                                  </Chip>
                              }
                              {
                                b.status === 'SEQUENCE_STARTED' &&
                                  <Chip color='primary' variant='outlined' className={classes.chip}>
                                    Running
                                  </Chip>
                              }
                              {
                                b.status === 'PROCESSING' &&
                                  <Chip color='primary' variant='outlined' className={classes.chip}>
                                    Processing
                                  </Chip>
                              }
                              {
                                b.status === 'SEQUENCE_COMPLETED' &&
                                  <Chip color='neutral' variant='outlined' className={classes.chip}>
                                    Completed
                                  </Chip>
                              }
                              {
                                b.status === 'FAILED_REQ' &&
                                  <Chip color='danger' variant='outlined' className={classes.chip}>
                                    Failed
                                  </Chip>
                              }
                              {
                                b.status === 'NO_CONTACT_INFO' &&
                                  <Chip color='danger' variant='outlined' className={classes.chip}>
                                    No Contact
                                  </Chip>
                              }
                              {
                                b.status === 'REPLIED' &&
                                  <Chip color='success' variant='solid' className={classes.chip}>
                                    Replied
                                  </Chip>
                              }
                            </div>
                            <Typography level='body-sm'>
                              {b.domain}
                            </Typography>
                          </ListItemButton>
                        </ListItem>
                      ))
                    }
                  </List>
                </MenuList>
                {
                  selectedBusinessId ?
                    <div
                      className={classes.businessView}
                    >
                      <EngageBusinessView
                        campaignId={id!}
                        businessId={selectedBusinessId}
                        refetchList={fetchData}
                      />
                    </div> :
                    <div
                      className={classes.businessView}
                    >
                      <div className={classes.noneSelectedView}>
                        <Typography level='h3'>
                          Select a Business to View
                        </Typography>
                        <Typography level='body-lg'>
                          Choose from our AI-curated list of businesses to view details, approve or
                          remove them, and more
                        </Typography>
                      </div>
                    </div>
                }
              </>
          }
        </div>
      </LoadingContainer>
    </div>
  );
}
