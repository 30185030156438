import { GridColDef } from '@mui/x-data-grid-pro';
import { getAPI } from 'src/api';
import { GetListResponse, Type24EnumsEnrichColType } from 'src/generated/api';
import { getColumns as getContactInfoColumns } from './ContactInfo';
import { getColumns as getScrapeColumns } from './Scrape';
import { getColumns as getSimpleColumns } from './Simple';
import { getColumns as getPitchbookColumns } from './Pitchbook';
import { getColumns as getLinkedInColumns } from './LinkedIn';
import { getColumns as getBooleanColumns } from './Boolean';
import { EnrichmentColProps } from './Header';

export function getColumnsForEnrichment(props: EnrichmentColProps): GridColDef<GetListResponse['businesses'][number]>[] {
  if (props.col.type === Type24EnumsEnrichColType.CONTACT_INFO) {
    return getContactInfoColumns(props);
  }
  if (props.col.type === Type24EnumsEnrichColType.SCRAPE) {
    return getScrapeColumns(props);
  }
  if (props.col.type === Type24EnumsEnrichColType.PERSONALIZED_MESSAGE) {
    return getSimpleColumns(props, (vals) => vals?.personalizedMessage?.result ?? null);
  }
  if (props.col.type === Type24EnumsEnrichColType.SIMPLIFIED_NAME) {
    return getSimpleColumns(props, (vals) => vals?.simplifiedName?.result ?? null);
  }
  if (props.col.type === Type24EnumsEnrichColType.PITCHBOOK) {
    return getPitchbookColumns(props);
  }
  if (props.col.type === Type24EnumsEnrichColType.LINKEDIN) {
    return getLinkedInColumns(props);
  }
  if (props.col.type === Type24EnumsEnrichColType.IS_BOOTSTRAPPED) {
    return getBooleanColumns(
      props,
      (val) => val?.isBootstrapped?.value ?? null,
      props.onInfoClick ?
        async (val, businessId) => {
          const api = getAPI();
          const enrichmentDetails = await api.lists.getEnrichmentDetails(
            val.colId,
            businessId,
          );
          props.onInfoClick!({
            prompt: 'Is this business bootstrapped?',
            value: val.isBootstrapped!.value.toString(),
            explanation: enrichmentDetails.data.isBootstrapped!.explanation,
            steps: enrichmentDetails.data.isBootstrapped!.steps,
          });
        } : undefined,
    );
  }
  if (props.col.type === Type24EnumsEnrichColType.IS_VALID) {
    return getBooleanColumns(
      props,
      (val) => val?.isValid?.value ?? null,
      props.onInfoClick ?
        async (val, businessId) => {
          const api = getAPI();
          const enrichmentDetails = await api.lists.getEnrichmentDetails(
            val.colId,
            businessId,
          );
          props.onInfoClick!({
            prompt: 'Is this business still in operation?',
            value: val.isValid!.value.toString(),
            explanation: enrichmentDetails.data.isValid!.explanation,
            steps: [],
          });
        } : undefined,
    );
  }
  throw Error(`Unsupported column type ${props.col.type}`);
}
