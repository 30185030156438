import React, { useState } from 'react';
import { tss } from 'tss-react';
import {
  Chip,
  Theme,
  useTheme,
} from '@mui/joy';
import { GetListResponse } from 'src/generated/api';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    chips: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    chipsSection: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    tooltip: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 400,
      padding: theme.spacing(1),
    },
    chip: {
      '&:hover': {
        filter: 'brightness(110%)',
        cursor: 'pointer',
      },
      zIndex: 0,
    },
  }));

type Props = {
  business: GetListResponse['businesses'][number];
};

export default function ServiceAreasCell({
  business,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const [showMoreServiceAreas, setShowMoreServiceAreas] = useState(false);
  return (
    <div className={classes.chips}>
      <div className={classes.chipsSection}>
        {
          business.serviceAreaStates.length > 0 &&
          business.serviceAreaStates
            .slice(0, showMoreServiceAreas ? undefined : 3)
            .map((s) => (
              <Chip color="primary" key={`${business.id}-${s}`} className={classes.chip}>
                {s}
              </Chip>
            ))
        }
        {
          !showMoreServiceAreas &&
          business.serviceAreaStates.length > 3 &&
            <Chip
              variant='outlined'
              className={classes.chip}
              onClick={(e) => {
                setShowMoreServiceAreas(true);
                e.stopPropagation();
              }}
            >
              +{business.serviceAreaStates.length - 3}
            </Chip>
        }
      </div>
    </div>
  );
}
