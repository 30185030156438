import React from 'react';
import { tss } from 'tss-react';
import {
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { GetAutopilotManageResearchResponse } from 'src/generated/api';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
      height: '100%',
      width: '100%',
      cursor: 'pointer',
      wordBreak: 'break-word',
    },
    booleanIcon: {
      width: 16,
      height: 16,
    },
  }));

type Props = {
  value?: GetAutopilotManageResearchResponse['businesses'][number]['contactInfo'];
};

export default function ContactInfoCell({
  value,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  return (
    <div
      className={classes.row}
    >
      {
        value != null &&
          <>
            {
              value.emailAddress ?
                <>
                  <CheckCircle
                    color='success'
                  />
                  <div>
                    <Typography level='body-sm' textColor='text.primary'>
                      { value.firstName } { value.lastName }
                    </Typography>
                    <Typography level='body-sm' textColor='text.primary'>
                      <i>
                        { value.emailAddress }
                      </i>
                    </Typography>
                  </div>
                </> :
                <>
                  <Cancel
                    color='error'
                  />
                  <Typography level='body-sm' textColor='text.primary'>
                    <i>
                      Not Available
                    </i>
                  </Typography>
                </>
            }
          </>
      }
    </div>
  );
}
