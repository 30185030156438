import React from 'react';
import {
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { tss } from 'tss-react';
import { GetListResponse } from 'src/generated/api';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { EnrichmentColProps } from './Header';
import EnrichmentCellWrapper from './Wrapper';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    cell: {
      wordBreak: 'break-word',
    },
    clickableCell: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      alignItems: 'center',
      gap: 2,
      wordBreak: 'break-word',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.neutral.plainHoverBg,
      },
    },
    enrichmentHeader: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'flex-end',
    },
    colHeaderText: {
      textWrap: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  }));

type ValueProps = {
  value: string | null;
  isUrl?: boolean;
};

function Value({
  value,
  isUrl = false,
}: ValueProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  if (!value) {
    return (
      <div className={classes.cell}>
        <Typography level='body-sm' textColor='tertiary'>
          <i>
            Not Available
          </i>
        </Typography>
      </div>
    );
  }
  if (isUrl) {
    return (
      <div
        className={classes.clickableCell}
        onClick={() => window.open(value)}
      >
        <Typography level='body-sm' textColor='text.primary'>
          {value}
        </Typography>
      </div>
    );
  }
  return (
    <div className={classes.cell}>
      <Typography level='body-sm' textColor='text.primary'>
        {value}
      </Typography>
    </div>
  );
}

type ColumnHeaderProps = {
  title: string;
};

function ColumnHeader({
  title,
}: ColumnHeaderProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  return (
    <div className={classes.enrichmentHeader}>
      <Typography level='body-md' className={classes.colHeaderText}>
        <b>
          {title}
        </b>
      </Typography>
    </div>
  );
}

export function getColumns(
  enrichmentColProps: EnrichmentColProps,
) {
  return [{
    field: `${enrichmentColProps.col.id}-url`,
    width: enrichmentColProps.columnWidths[`${enrichmentColProps.col.id}-url`] ?? 200,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (data: GridRenderCellParams<GetListResponse['businesses'][number]>) => {
      const enrichVal = data.row.enrichmentValues.find((v) =>
        v.colId === enrichmentColProps.col.id);
      const strVal = enrichVal?.pitchbook?.url;
      return (
        <EnrichmentCellWrapper
          value={enrichVal}
          enrichmentColProps={enrichmentColProps}
          businessId={data.row.id}
        >
          <Value value={strVal ?? null} isUrl />
        </EnrichmentCellWrapper>
      );
    },
    renderHeader: () => (
      <ColumnHeader
        title='Pitchbook URL'
      />
    ),
  }, {
    field: `${enrichmentColProps.col.id}-employee-count`,
    width: enrichmentColProps.columnWidths[`${enrichmentColProps.col.id}-employee-count`] ?? 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (data: GridRenderCellParams<GetListResponse['businesses'][number]>) => {
      const enrichVal = data.row.enrichmentValues.find((v) =>
        v.colId === enrichmentColProps.col.id);
      const val = enrichVal?.pitchbook?.employeeCount?.toString() ?? null;
      return (
        <EnrichmentCellWrapper
          value={enrichVal}
          enrichmentColProps={enrichmentColProps}
          businessId={data.row.id}
        >
          <Value value={val} />
        </EnrichmentCellWrapper>
      );
    },
    renderHeader: () => (
      <ColumnHeader
        title='# Employees'
      />
    ),
  }, {
    field: `${enrichmentColProps.col.id}-financing`,
    width: enrichmentColProps.columnWidths[`${enrichmentColProps.col.id}-financing`] ?? 250,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (data: GridRenderCellParams<GetListResponse['businesses'][number]>) => {
      const enrichVal = data.row.enrichmentValues.find((v) =>
        v.colId === enrichmentColProps.col.id);
      const strVal = enrichVal?.pitchbook?.financingStatus;
      return (
        <EnrichmentCellWrapper
          value={enrichVal}
          enrichmentColProps={enrichmentColProps}
          businessId={data.row.id}
        >
          <Value value={strVal ?? null} />
        </EnrichmentCellWrapper>
      );
    },
    renderHeader: () => (
      <ColumnHeader
        title='Financing Status'
      />
    ),
  }, {
    field: `${enrichmentColProps.col.id}-deal`,
    width: enrichmentColProps.columnWidths[`${enrichmentColProps.col.id}-deal`] ?? 200,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (data: GridRenderCellParams<GetListResponse['businesses'][number]>) => {
      const enrichVal = data.row.enrichmentValues.find((v) =>
        v.colId === enrichmentColProps.col.id);
      const strVal = enrichVal?.pitchbook?.latestDealType;
      return (
        <EnrichmentCellWrapper
          value={enrichVal}
          enrichmentColProps={enrichmentColProps}
          businessId={data.row.id}
        >
          <Value value={strVal ?? null} />
        </EnrichmentCellWrapper>
      );
    },
    renderHeader: () => (
      <ColumnHeader
        title='Latest Deal Type'
      />
    ),
  }, {
    field: `${enrichmentColProps.col.id}-ownership`,
    width: enrichmentColProps.columnWidths[`${enrichmentColProps.col.id}-ownership`] ?? 200,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (data: GridRenderCellParams<GetListResponse['businesses'][number]>) => {
      const enrichVal = data.row.enrichmentValues.find((v) =>
        v.colId === enrichmentColProps.col.id);
      const strVal = enrichVal?.pitchbook?.ownershipStatus;
      return (
        <EnrichmentCellWrapper
          value={enrichVal}
          enrichmentColProps={enrichmentColProps}
          businessId={data.row.id}
        >
          <Value value={strVal ?? null} />
        </EnrichmentCellWrapper>
      );
    },
    renderHeader: () => (
      <ColumnHeader
        title='Ownership Status'
      />
    ),
  }, {
    field: `${enrichmentColProps.col.id}-acquirer`,
    width: enrichmentColProps.columnWidths[`${enrichmentColProps.col.id}-acquirer`] ?? 200,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (data: GridRenderCellParams<GetListResponse['businesses'][number]>) => {
      const enrichVal = data.row.enrichmentValues.find((v) =>
        v.colId === enrichmentColProps.col.id);
      const strVal = enrichVal?.pitchbook?.acquirer;
      return (
        <EnrichmentCellWrapper
          value={enrichVal}
          enrichmentColProps={enrichmentColProps}
          businessId={data.row.id}
        >
          <Value value={strVal ?? null} />
        </EnrichmentCellWrapper>
      );
    },
    renderHeader: () => (
      <ColumnHeader
        title='Acquirer'
      />
    ),
  }];
}
