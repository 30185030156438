import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Theme,
  IconButton,
  useTheme,
  Divider,
  Typography,
  Tooltip,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Autocomplete,
  Chip,
  Table,
  Card,
  Switch,
} from '@mui/joy';
import { tss } from 'tss-react';
import {
  useForm,
  useFieldArray,
} from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  HeadcountByRole,
  FinancialInfo,
  RevenueMix,
  RecurringRevenue,
  GetProjectResponse,
} from 'src/generated/api';
import { getAPI } from 'src/api';
import { getConfig } from 'src/config';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingContainer from 'src/components/LoadingContainer';
import { SnackbarContext } from 'src/contexts/snackbar';
import {
  ArrowBack,
  Download,
  OpenInNew,
  Save,
  CheckCircle,
  LockOpen,
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import PDFMultiPageViewer from 'src/components/PDFMultiPageViewer';
import CurrencyInput from 'src/components/CurrencyInput';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { uploadedLink } from 'src/utils';

type BlindProfileFormData = {
  name: string;
  subtitle: string;
  description: string;
  numCustomers: string;
  topCustomersByRevenue: string[];
  hq: string;
  headcountByRole: HeadcountByRole;
  primaryProductServices: { value: string }[];
  secondaryProductServices: { value: string }[];
  financialInfo: FinancialInfo;
  revenueMix: RevenueMix;
  recurringRevenue: RecurringRevenue;
  investmentConsiderations: { headline: string; explanation: string }[];
  verticals: { value: string }[];
  vendorsAndPartners: { value: string }[];
  certifications: { value: string }[];
  revenueGeoDistribution: { name: string; percentage: string }[];
  headerLogoUrl: string;
};

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      maxWidth: 1400,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(4),
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      alignItems: 'center',
      width: '100%',
      padding: theme.spacing(2),
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.background.body,
      zIndex: 3,
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 'auto',
      gap: theme.spacing(1),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      padding: theme.spacing(2),
      flex: 1,
      width: 800,
      backgroundColor: theme.palette.background.body,
      zIndex: 1,
    },
    pdf: {
      display: 'flex',
      width: 500,
      flex: 1,
      position: 'sticky',
      top: theme.spacing(1),
      height: '100vh',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      alignItems: 'flex-start',
    },
    fullWidth: {
      width: '100%',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      flex: 1,
    },
    investmentConsiderations: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(6),
    },
    root: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      position: 'relative',
    },
    tabList: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    table: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      tableLayout: 'fixed',
    },
    revenueMixNameCell: {
      width: 'auto',
    },
    revenueMixPercentageCell: {
      width: 180,
    },
    revenueMixActionCell: {
      width: 96,
    },
    tableCell: {
      padding: theme.spacing(1),
      verticalAlign: 'bottom',
      fontSize: 18,
    },
    customersByRevenueItems: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      gap: theme.spacing(2),
    },
    customerByRevenueText: {
      width: 128,
    },
    customersByRevenueItem: {
      flexBasis: '45%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    customerByRevenueInput: {
      width: 128,
    },
    uploadZone: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 120,
      width: '100%',
      borderRadius: theme.spacing(1),
      borderWidth: 1,
      borderStyle: 'dashed',
      cursor: 'pointer',
      overflow: 'hidden',
      position: 'relative',
    },
    removeImageButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      zIndex: 1,
    },
    uploadedImage: {
      height: 96,
      width: '100%',
      objectFit: 'contain',
    },
    emptyState: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    logoField: {
      width: '100%',
    },
    sectionHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    baselineRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      gap: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    card: {
      boxShadow: theme.shadow.md,
    },
  }));

export function BlindProfile() {
  const { id } = useParams();
  const config = getConfig();
  const blindProfileUrl = `${config.hosts.api}/clients/${id}/download_blind_profile`;
  const [pageNum, setPageNum] = useState(0);
  const [project, setProject] = useState<GetProjectResponse | null>(null);
  const [pdfRefreshTrigger, setPdfRefreshTrigger] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const api = getAPI();
  const { showSnackbar } = useContext(SnackbarContext);

  const fetchData = useCallback(async () => {
    const res = await api.clients.getProject(id!);
    setProject(res.data);
    setIsCompleted(res.data.blindProfileData?.isCompleted || false);
  }, [api, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<BlindProfileFormData>();
  const {
    fields: primaryProductServicesFields,
  } = useFieldArray({
    control,
    name: 'primaryProductServices',
  });
  const {
    fields: investmentConsiderationsFields,
    append: appendInvestmentConsiderations,
    remove: removeInvestmentConsiderations,
  } = useFieldArray({
    control,
    name: 'investmentConsiderations',
  });
  const {
    fields: verticalsFields,
  } = useFieldArray({
    control,
    name: 'verticals',
  });
  const {
    fields: vendorsAndPartnersFields,
  } = useFieldArray({
    control,
    name: 'vendorsAndPartners',
  });
  const {
    fields: revenueMixFields,
    append: appendRevenueMix,
    remove: removeRevenueMix,
  } = useFieldArray({
    control,
    name: 'revenueMix',
  });
  const {
    fields: geoFields,
    append: appendGeo,
    remove: removeGeo,
  } = useFieldArray({
    control,
    name: 'revenueGeoDistribution',
  });

  const { fields: headcountByRoleFields } = useFieldArray({
    control,
    name: 'headcountByRole',
  });

  const [sectionEnableState, setSectionEnableState] = useState({
    showRevenueGeo: true,
    showCustomerConcentration: true,
    showVendorsAndPartners: true,
    showRevenueMix: true,
    showRecurringRevenue: true,
    showAwards: true,
  });

  useEffect(() => {
    if (project?.blindProfileData) {
      reset({
        name: project?.blindProfileData?.name,
        subtitle: project?.blindProfileData?.subtitle,
        description: project?.blindProfileData?.description,
        primaryProductServices: project?.blindProfileData
          ?.primaryProductServices.map((value) => ({ value })),
        investmentConsiderations: Array.isArray(
          project?.blindProfileData?.investmentConsiderations,
        ) ?
          project?.blindProfileData?.investmentConsiderations :
          [],
        verticals: project?.blindProfileData?.verticals
          .map((value) => ({ value })),
        vendorsAndPartners: project?.blindProfileData?.vendorsAndPartners
          .map((value) => ({ value })),
        certifications: project?.blindProfileData?.certifications
          .map((value) => ({ value })),
        revenueMix: project?.intakeData?.revenueMix,
        numCustomers: project?.intakeData?.numCustomers?.toString() || '',
        revenueGeoDistribution: project?.intakeData?.revenueGeoDistribution?.map((item) => ({
          name: item.name,
          percentage: item.percentage.toString(),
        })),
        financialInfo: project?.intakeData?.financialInfo,
        topCustomersByRevenue: project?.intakeData?.topCustomersByRevenue
          ?.map((v) => v?.toString() ?? '0') || [],
        recurringRevenue: project?.intakeData?.recurringRevenue,
        headcountByRole: project?.intakeData?.headcountByRole,
        headerLogoUrl: project?.blindProfileData?.headerLogoUrl || '',
      });
      setSectionEnableState({
        showRevenueGeo: project?.intakeData?.showRevenueGeo || true,
        showCustomerConcentration: project?.intakeData?.showCustomerConcentration || true,
        showVendorsAndPartners: project?.intakeData?.showVendorsAndPartners || true,
        showRevenueMix: project?.intakeData?.showRevenueMix || true,
        showRecurringRevenue: project?.intakeData?.showRecurringRevenue || true,
        showAwards: project?.intakeData?.showAwards || true,
      });
    }
  }, [project, reset]);
  const onSubmit = async (data: BlindProfileFormData) => {
    const transformedData = {
      ...data,
      primaryProductServices: data.primaryProductServices.map((item) => item.value),
      verticals: data.verticals.map((item) => item.value),
      vendorsAndPartners: data.vendorsAndPartners.map((item) => item.value),
      certifications: data.certifications.map((item) => item.value),
      topCustomersByRevenue: data.topCustomersByRevenue.map((item) => Number(item) || 0),
      revenueGeoDistribution: data.revenueGeoDistribution.map((item) => ({
        name: item.name,
        percentage: Number(item.percentage),
      })),
      numCustomers: Number(data.numCustomers),
      showRevenueGeo: sectionEnableState.showRevenueGeo,
      showCustomerConcentration: sectionEnableState.showCustomerConcentration,
      showVendorsAndPartners: sectionEnableState.showVendorsAndPartners,
      showRevenueMix: sectionEnableState.showRevenueMix,
      showRecurringRevenue: sectionEnableState.showRecurringRevenue,
      showAwards: sectionEnableState.showAwards,
    };
    await api.clients.updateBlindProfile(id!, transformedData);
    setPdfRefreshTrigger((prev) => prev + 1);
  };

  const onSave = async () => {
    // Does not validate on save
    await onSubmit(getValues());
    showSnackbar({
      message: 'Changes saved successfully',
      color: 'success',
    });
  };

  const onFinalize = async () => {
    await handleSubmit(async (data) => {
      await onSubmit(data);
      await api.clients.lockBlindProfile(id!);
      await fetchData();
      showSnackbar({
        message: 'Finalized Blind Profile!',
        color: 'success',
      });
    }, () => {
      showSnackbar({
        message: 'Some required info is missing!',
        color: 'danger',
      });
    })();
  };

  const onUnlock = async () => {
    await api.clients.unlockBlindProfile(id!);
    fetchData();
  };

  const navigate = useNavigate();

  const onDropAccepted = useCallback(async (acceptedFiles: File[]) => {
    try {
      const signedUrlRes = await api.files.createSignedUrl();
      await axios.put(
        signedUrlRes.data.url.replace(/"/g, ''),
        acceptedFiles[0],
        {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        },
      );
      setValue('headerLogoUrl', signedUrlRes.data.fileId);
      showSnackbar({
        message: 'Logo uploaded successfully!',
        color: 'success',
      });
    } catch (error) {
      showSnackbar({
        message: 'Failed to upload logo. Please try again.',
        color: 'danger',
      });
    }
  }, [api.files, setValue, showSnackbar]);

  const onDropRejected = useCallback(() => {
    showSnackbar({
      color: 'danger',
      message: 'Only image files are supported!',
    });
  }, [showSnackbar]);

  const handleRemoveLogo = useCallback(() => {
    setValue('headerLogoUrl', '');
  }, [setValue]);

  return (
    <div className={classes.container}>
      <LoadingContainer isLoading={!project}>
        <div className={classes.root}>
          <Tabs value={pageNum} onChange={(_, value) => setPageNum(value as number)}>
            <div className={classes.header}>
              <IconButton
                onClick={() => navigate(`/clients/${id}`)}
                color='neutral'
                size='lg'
              >
                <ArrowBack />
              </IconButton>
              <Typography level='h3'>Blind Profile</Typography>
              <TabList
                size='lg'
                className={classes.tabList}
              >
                <Tab value={0}>Page 1</Tab>
                <Tab value={1}>Page 2</Tab>
              </TabList>
              <div className={classes.buttons}>
                <Tooltip title="Open Intake">
                  <IconButton
                    onClick={() => window.open(`${config.hosts.client}/${id}/intake`)}
                    color="primary"
                    variant='outlined'
                    size="lg"
                  >
                    <OpenInNew />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download PDF">
                  <IconButton
                    onClick={() => window.open(blindProfileUrl, '_blank')}
                    color="neutral"
                    variant='outlined'
                    size="lg"
                  >
                    <Download />
                  </IconButton>
                </Tooltip>
                {isCompleted ? (
                  <Tooltip title="Unlock for Editing">
                    <IconButton
                      onClick={onUnlock}
                      color="warning"
                      variant='solid'
                      size="lg"
                    >
                      <LockOpen />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <>
                    <Tooltip title="Save Changes">
                      <IconButton
                        onClick={onSave}
                        color="success"
                        variant='outlined'
                        size="lg"
                      >
                        <Save />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Finalize">
                      <IconButton
                        onClick={onFinalize}
                        color="success"
                        variant='solid'
                        size="lg"
                      >
                        <CheckCircle />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
            <TabPanel value={0}>
              <div className={classes.form}>
                <div className={classes.row}>
                  <div className={classes.column}>
                    <ControlledInput
                      name="name"
                      label="Name"
                      control={control}
                      required
                      error={errors.name}
                      inputProps={{ disabled: isCompleted }}
                    />
                    <ControlledInput
                      name="subtitle"
                      label="Subtitle"
                      control={control}
                      required
                      error={errors.subtitle}
                      inputProps={{ disabled: isCompleted }}
                    />
                  </div>
                  <div className={classes.column}>
                    <div className={classes.logoField}>
                      <Typography level="body-sm" mb={1}>Header Logo</Typography>
                      <Dropzone
                        accept={{ 'image/*': [] }}
                        onDropAccepted={onDropAccepted}
                        onDropRejected={onDropRejected}
                        multiple={false}
                        disabled={isCompleted}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className={classes.uploadZone}>
                            <input {...getInputProps()} />
                            {getValues('headerLogoUrl') ? (
                              <>
                                <img
                                  src={uploadedLink(getValues('headerLogoUrl'))}
                                  alt="Header Logo"
                                  className={classes.uploadedImage}
                                />
                                <IconButton
                                  className={classes.removeImageButton}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveLogo();
                                  }}
                                  size="sm"
                                  color="danger"
                                  variant="outlined"
                                  disabled={isCompleted}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </>
                            ) : (
                              <div className={classes.emptyState}>
                                <Typography level='body-md'>
                                  Drop logo here or click to upload
                                </Typography>
                              </div>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
                <ControlledInput
                  name="description"
                  label="Description"
                  control={control}
                  required
                  error={errors.description}
                  textArea
                  inputProps={{ disabled: isCompleted }}
                />
                <Divider>Primary Products / Services</Divider>
                <Autocomplete
                  multiple
                  freeSolo
                  disabled={isCompleted}
                  options={[]}
                  value={primaryProductServicesFields.map((field) => field.value)}
                  onChange={(_, newValue) => {
                    const newFields = newValue.map((value) => ({ value }));
                    setValue('primaryProductServices', newFields);
                  }}
                  size='lg'
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="soft"
                        color="primary"
                        {...getTagProps({ index })}
                        key={option}
                        endDecorator={
                          <IconButton
                            size="sm"
                            variant="plain"
                            color="neutral"
                            onClick={() => {
                              const newValue = primaryProductServicesFields
                                .filter((_, i) => i !== index);
                              setValue('primaryProductServices', newValue);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        }
                      >
                        {option}
                      </Chip>
                    ))
                  }
                />
                <Divider>Verticals</Divider>
                <Autocomplete
                  multiple
                  freeSolo
                  className={classes.fullWidth}
                  disabled={isCompleted}
                  options={[]}
                  value={verticalsFields.map((field) => field.value)}
                  size='lg'
                  onChange={(_, newValue) => {
                    const newFields = newValue.map((value) => ({ value }));
                    setValue('verticals', newFields);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="soft"
                        color='success'
                        {...getTagProps({ index })}
                        key={option}
                        endDecorator={
                          <IconButton
                            size="sm"
                            variant="plain"
                            color="neutral"
                            onClick={() => {
                              const newValue = verticalsFields.filter((_, i) => i !== index);
                              setValue('verticals', newValue);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        }
                      >
                        {option}
                      </Chip>
                    ))
                  }
                />

                <Card variant='plain' className={classes.card}>
                  <div className={classes.sectionHeader}>
                    <Typography level='h4'>Revenue Mix</Typography>
                    <Switch
                      checked={sectionEnableState.showRevenueMix}
                      startDecorator={sectionEnableState.showRevenueMix ? 'Show' : 'Hide'}
                      onChange={(e) => setSectionEnableState({
                        ...sectionEnableState,
                        showRevenueMix: e.target.checked,
                      })}
                      disabled={isCompleted}
                    />
                  </div>
                  {
                    sectionEnableState.showRevenueMix && (
                      <>
                        <Table className={classes.table}>
                          <thead>
                            <tr>
                              <th className={classes.revenueMixNameCell}>
                                Name
                              </th>
                              <th className={classes.revenueMixPercentageCell}>
                                % of Total Revenue
                              </th>
                              <th className={classes.revenueMixActionCell}>
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {revenueMixFields.map((field, index) => (
                              <tr key={field.id}>
                                <td className={`${classes.tableCell} ${classes.revenueMixNameCell}`}>
                                  <ControlledInput
                                    name={`revenueMix.${index}.name`}
                                    control={control}
                                    required
                                    fullWidth
                                    inputProps={{ disabled: isCompleted }}
                                  />
                                </td>
                                <td className={`${classes.tableCell} ${classes.revenueMixPercentageCell}`}>
                                  <ControlledInput
                                    name={`revenueMix.${index}.percentage`}
                                    control={control}
                                    inputProps={{
                                      type: 'number',
                                      endDecorator: '%',
                                      disabled: isCompleted,
                                    }}
                                    required
                                  />
                                </td>
                                <td className={`${classes.tableCell} ${classes.revenueMixActionCell}`}>
                                  <IconButton
                                    onClick={() => removeRevenueMix(index)}
                                    color='danger'
                                    variant='outlined'
                                    size='lg'
                                    disabled={isCompleted}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Button
                          startDecorator={<AddIcon />}
                          color='success'
                          variant='outlined'
                          onClick={() => appendRevenueMix({
                            name: '',
                            percentage: 0,
                          })}
                          disabled={isCompleted}
                        >
                          Add Revenue Mix
                        </Button>
                      </>
                    )
                  }
                </Card>
                <Card variant='plain' className={classes.card}>
                  <div className={classes.sectionHeader}>
                    <Typography level='h4'>Revenue by Geography</Typography>
                    <Switch
                      checked={sectionEnableState.showRevenueGeo}
                      startDecorator={sectionEnableState.showRevenueGeo ? 'Show' : 'Hide'}
                      onChange={(e) => setSectionEnableState({
                        ...sectionEnableState,
                        showRevenueGeo: e.target.checked,
                      })}
                      disabled={isCompleted}
                    />
                  </div>
                  {
                    sectionEnableState.showRevenueGeo && (
                      <>
                        <ControlledInput
                          name="numCustomers"
                          label="Number of Customers"
                          control={control}
                          required
                          error={errors.numCustomers}
                          inputProps={{ type: 'number', disabled: isCompleted }}
                        />
                        <Table>
                          <thead>
                            <tr>
                              <th>Location</th>
                              <th>Percentage</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {geoFields.map((field, index) => (
                              <tr key={field.id}>
                                <td>
                                  <ControlledInput
                                    name={`revenueGeoDistribution.${index}.name`}
                                    control={control}
                                    inputProps={{
                                      placeholder: 'Midwest',
                                      disabled: isCompleted,
                                    }}
                                    required
                                  />
                                </td>
                                <td>
                                  <ControlledInput
                                    name={`revenueGeoDistribution.${index}.percentage`}
                                    control={control}
                                    inputProps={{ type: 'text', disabled: isCompleted }}
                                    required
                                  />
                                </td>
                                <td>
                                  <IconButton
                                    onClick={() => removeGeo(index)}
                                    size='lg'
                                    color='danger'
                                    variant='outlined'
                                    disabled={isCompleted}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Button
                          color='success'
                          variant='outlined'
                          startDecorator={<AddIcon />}
                          onClick={() => appendGeo({ name: '', percentage: '0' })}
                          disabled={isCompleted}
                        >
                          Add Geography
                        </Button>
                      </>
                    )
                  }
                </Card>
                <Card variant='plain' className={classes.card}>
                  <div className={classes.sectionHeader}>
                    <Typography level='h4'>Vendors and Partners</Typography>
                    <Switch
                      checked={sectionEnableState.showVendorsAndPartners}
                      startDecorator={sectionEnableState.showVendorsAndPartners ? 'Show' : 'Hide'}
                      onChange={(e) => setSectionEnableState({
                        ...sectionEnableState,
                        showVendorsAndPartners: e.target.checked,
                      })}
                      disabled={isCompleted}
                    />
                  </div>
                  {
                    sectionEnableState.showVendorsAndPartners && (
                      <Autocomplete
                        multiple
                        freeSolo
                        className={classes.fullWidth}
                        disabled={isCompleted}
                        options={[]}
                        size='lg'
                        value={vendorsAndPartnersFields.map((field) => field.value)}
                        onChange={(_, newValue) => {
                          const newFields = newValue.map((value) => ({ value }));
                          setValue('vendorsAndPartners', newFields);
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="soft"
                              color="warning"
                              {...getTagProps({ index })}
                              key={option}
                              endDecorator={
                                <IconButton
                                  size="sm"
                                  variant="plain"
                                  color="neutral"
                                  onClick={() => {
                                    const newValue = vendorsAndPartnersFields
                                      .filter((_, i) => i !== index);
                                    setValue('vendorsAndPartners', newValue);
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              }
                            >
                              {option}
                            </Chip>
                          ))
                        }
                      />
                    )
                  }
                </Card>
              </div>
            </TabPanel>
            <TabPanel value={1}>
              <div className={classes.form}>
                <Card variant='plain' className={classes.card}>
                  <Typography level='h4'>Financial Information</Typography>
                  <Table className={classes.table}>
                    <thead>
                      <tr>
                        <th></th>
                        <th>2021</th>
                        <th>2022</th>
                        <th>2023</th>
                        <th>2024E</th>
                      </tr>
                    </thead>
                    <tbody>
                      {['revenue', 'grossProfit', 'adjEbitda'].map((metric) => (
                        <tr key={metric}>
                          <td className={classes.tableCell}>
                            {metric === 'revenue' ? 'Revenue' :
                              metric === 'grossProfit' ? 'Gross Profit' :
                                'Adjusted EBITDA'}
                          </td>
                          {[0, 1, 2, 3].map((index) => (
                            <td key={index} className={classes.tableCell}>
                              <CurrencyInput
                                // @ts-expect-error Dynamic string is not assignable
                                name={`financialInfo.${index}.${metric}`}
                                control={control}
                                required
                                inputProps={{ disabled: isCompleted }}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
                <Card variant='plain' className={classes.card}>
                  <div className={classes.sectionHeader}>
                    <Typography level='h4'>Customer Concentration</Typography>
                    <Switch
                      checked={sectionEnableState.showCustomerConcentration}
                      startDecorator={sectionEnableState.showCustomerConcentration ? 'Show' : 'Hide'}
                      onChange={(e) => setSectionEnableState({
                        ...sectionEnableState,
                        showCustomerConcentration: e.target.checked,
                      })}
                      disabled={isCompleted}
                    />
                  </div>
                  {
                    sectionEnableState.showCustomerConcentration && (
                      <div className={classes.customersByRevenueItems}>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
                          <div className={classes.customersByRevenueItem} key={index}>
                            <Typography level='body-lg' className={classes.customerByRevenueText}>
                              Customer {index + 1}
                            </Typography>
                            <ControlledInput
                              key={index}
                              name={`topCustomersByRevenue.${index}`}
                              control={control}
                              inputProps={{ type: 'number', endDecorator: '%', disabled: isCompleted }}
                              className={classes.customerByRevenueInput}
                              required
                            />
                          </div>
                        ))}
                      </div>
                    )
                  }
                </Card>
                <Card variant='plain' className={classes.card}>
                  <Typography level='h4'>Headcount by Role</Typography>
                  <Table>
                    <thead>
                      <tr>
                        <th className={classes.tableCell}>Role</th>
                        <th className={classes.tableCell}>Full Time</th>
                        <th className={classes.tableCell}>Part Time</th>
                        <th className={classes.tableCell}>Contractors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {headcountByRoleFields.map((field, index) => (
                        <tr key={field.id}>
                          <td className={classes.tableCell}>{field.role}</td>
                          <td>
                            <ControlledInput
                              name={`headcountByRole.${index}.numFTEs`}
                              control={control}
                              inputProps={{
                                type: 'number',
                                disabled: isCompleted,
                              }}
                            />
                          </td>
                          <td>
                            <ControlledInput
                              name={`headcountByRole.${index}.numPTEs`}
                              control={control}
                              inputProps={{
                                type: 'number',
                                disabled: isCompleted,
                              }}
                            />
                          </td>
                          <td>
                            <ControlledInput
                            name={`headcountByRole.${index}.numContractors`}
                            control={control}
                            inputProps={{
                              type: 'number',
                              disabled: isCompleted,
                            }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
                <Card variant='plain' className={classes.card}>
                  <div className={classes.sectionHeader}>
                    <Typography level='h4'>Recurring Revenue</Typography>
                    <Switch
                      checked={sectionEnableState.showRecurringRevenue}
                      startDecorator={sectionEnableState.showRecurringRevenue ? 'Show' : 'Hide'}
                      onChange={(e) => setSectionEnableState({
                        ...sectionEnableState,
                        showRecurringRevenue: e.target.checked,
                      })}
                      disabled={isCompleted}
                    />
                  </div>
                  {
                    sectionEnableState.showRecurringRevenue && (
                      <Table className={classes.table}>
                      <thead>
                        <tr>
                          <th>Period</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {['2021', '2022', '2023', '2024E'].map((period, index) => (
                            <tr key={period}>
                              <td className={classes.tableCell}>{period}</td>
                              <td className={classes.tableCell}>
                                <CurrencyInput
                                  name={`recurringRevenue.${index}.amount`}
                                  control={control}
                                  required
                                  inputProps={{ disabled: isCompleted }}
                                />
                              </td>
                            </tr>
                        ))}
                        </tbody>
                      </Table>
                    )
                  }
                </Card>
                <Typography level='h4'>Investment Considerations</Typography>
                <div className={classes.investmentConsiderations}>
                  {investmentConsiderationsFields.map((field, index) => (
                    <Card key={field.id} className={classes.card} variant='plain'>
                      <div className={classes.fullWidth}>
                        <div className={classes.baselineRow}>
                          <ControlledInput
                            name={`investmentConsiderations.${index}.headline`}
                            label="Headline"
                            control={control}
                            className={classes.fullWidth}
                            required
                            error={errors.investmentConsiderations?.[index]?.headline}
                            inputProps={{ disabled: isCompleted }}
                          />
                          <IconButton
                            onClick={() => removeInvestmentConsiderations(index)}
                            color='danger'
                            variant='outlined'
                            size='lg'
                            disabled={isCompleted}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                        <ControlledInput
                          name={`investmentConsiderations.${index}.explanation`}
                          label="Explanation"
                          control={control}
                          required
                          error={errors.investmentConsiderations?.[index]?.explanation}
                          textArea
                          inputProps={{ disabled: isCompleted }}
                        />
                      </div>
                    </Card>
                  ))}
                </div>
                <Button
                  variant='outlined'
                  color='success'
                  onClick={() => appendInvestmentConsiderations({ headline: '', explanation: '' })}
                  disabled={isCompleted}
                >
                  Add Investment Consideration
                </Button>
              </div>
            </TabPanel>
          </Tabs>
          <div className={classes.pdf}>
            <PDFMultiPageViewer
              url={blindProfileUrl}
              widthPerPage={600}
              showPages={[pageNum + 1]}
              refreshTrigger={pdfRefreshTrigger}
            />
          </div>
        </div>
      </LoadingContainer>
    </div>
  );
}
