import posthog from 'posthog-js';
import { getConfig } from './config';
import { Api } from './generated/api';

let api: Api<unknown>;
export const getAPI = (): Api<unknown> => {
  const sessionId = posthog.get_session_id();
  const posthogDistinctId = posthog.get_distinct_id();
  if (api) {
    return api;
  }
  const config = getConfig();
  api = new Api({
    baseUrl: config.hosts.api,
    baseApiParams: {
      credentials: 'include',
      headers: {
        'OffDeal-Session-Id': sessionId,
        'OffDeal-Posthog-Distinct-Id': posthogDistinctId,
      },
    },
  });
  return api;
};

export type ApiError = {
  status: number;
  error: {
    reason: string;
  };
};
