import React, { useState } from 'react';
import { tss } from 'tss-react';
import {
  Card,
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      width: 800,
      padding: theme.spacing(2),
      overflowY: 'auto',
    },
    step: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      gap: theme.spacing(1),
    },
    stepImg: {
      width: 700,
      borderRadius: theme.spacing(2),
      objectFit: 'cover',
      objectPosition: 'top',
    },
    collapsed: {
      height: 400,
    },
    collapsedOverlay: {
      position: 'absolute',
      height: 400,
      width: 700,
      bottom: theme.spacing(2),
      borderRadius: theme.spacing(2),
      backgroundImage: 'linear-gradient(to top, white 5%, transparent)',
    },
    imgContainer: {
      cursor: 'pointer',
      borderRadius: theme.spacing(2),
      '&:hover': {
        boxShadow: theme.shadow.xl,
      },
    },
  }));

type Props = {
  prompt: string;
  result: string;
  explanation: string;
  steps: {
    str: string;
    imgUrl: string | null;
  }[];
  onClose: () => void;
};

type ImageProps = {
  url: string;
};

function EnrichmentStepImage({
  url,
}: ImageProps) {
  const theme = useTheme();
  const { classes, cx } = useStyles({ theme });
  const [expanded, setExpanded] = useState(false);
  if (expanded) {
    return (
      <div className={classes.imgContainer}>
        <img
          src={url}
          className={classes.stepImg}
          onClick={() => setExpanded(false)}
        />
      </div>
    );
  }
  return (
    <Tooltip title='Expand Screenshot'>
      <div
        className={classes.imgContainer}
        onClick={() => setExpanded(true)}
      >
        <img
          src={url}
          className={cx(classes.stepImg, classes.collapsed)}
        />
        <div className={classes.collapsedOverlay} />
      </div>
    </Tooltip>
  );
}

export default function EnrichmentStepsModal({
  prompt,
  result,
  explanation,
  steps,
  onClose,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });

  return (
    <Modal
      open
      onClose={onClose}
    >
      <ModalDialog className={classes.container}>
        <ModalClose />
        <Typography level='h4'>
          {prompt}
        </Typography>
        <Card>
          <Typography level='body-lg' color='primary'>
            <b>
              { result?.toString() ?? 'Not Available' }
            </b>
          </Typography>
          <Typography level='body-sm'>
            <i>
            { explanation }
            </i>
          </Typography>
        </Card>
        {
          steps.length > 0 &&
          <>
            <Typography level='body-lg'>
              <b>
                Steps taken by AI
              </b>
            </Typography>
            {
              steps.map((s) => (
                <Card className={classes.step} key={s.imgUrl}>
                  <Typography level='body-md'>
                    <b>
                      { s.str }
                    </b>
                  </Typography>
                  {
                    s.imgUrl &&
                      <EnrichmentStepImage url={s.imgUrl} />
                  }
                </Card>
              ))
            }
          </>
        }
      </ModalDialog>
    </Modal>
  );
}
