import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { tss } from 'tss-react';

import {
  Button,
  Card,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { getAPI } from 'src/api';
import { GetSubscriptionResponse } from 'src/generated/api';
import LoadingContainer from 'src/components/LoadingContainer';
import { SnackbarContext } from 'src/contexts/snackbar';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      padding: theme.spacing(2),
    },
    contents: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    card: {
      padding: theme.spacing(4),
      maxWidth: 400,
    },
  }));

export default function Billing() {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const [subscription, setSubscription] = useState<GetSubscriptionResponse | null>(null);
  const [creditsLeft, setCreditsLeft] = useState<number | null>(null);
  const { showSnackbar } = useContext(SnackbarContext);
  const api = getAPI();
  const fetch = useCallback(async () => {
    const res = await api.billing.getSubscription();
    setSubscription(res.data);
    const numCreditsRes = await api.billing.getCreditsLeft();
    setCreditsLeft(numCreditsRes.data.creditsLeft);
  }, [api]);
  useEffect(() => {
    fetch();
  }, [fetch]);
  return (
    <div className={classes.container}>
      <Typography level='h4'>
        Billing
      </Typography>
      <LoadingContainer isLoading={!subscription}>
        <div className={classes.contents}>
          <Card className={classes.card}>
            <Typography level='body-lg'>
              You have <b>{creditsLeft}</b> credits left
            </Typography>
            {
              subscription?.subscription && !subscription.subscription.willCancel ?
                <>
                  <Typography level='body-lg'>
                    You&apos;re subscribed to our Beta plan for <b>
                      ${(subscription!.subscription.price / 100).toFixed(2)}
                    </b> per month
                  </Typography>
                  <Button
                    color='success'
                    onClick={async () => {
                      const res = await api.billing.createAdditionalCreditSession();
                      window.location.href = res.data.checkoutUrl;
                    }}
                  >
                    Get More Credits
                  </Button>
                  <Button
                    variant='outlined'
                    color='danger'
                    onClick={async () => {
                      await api.billing.cancelSubscription();
                      showSnackbar({
                        message: 'Your plan has been cancelled',
                        color: 'danger',
                      });
                      await fetch();
                    }}
                  >
                    Cancel Subscription
                  </Button>
                </> :
                <>
                  <Typography level='body-md'>
                    Join our Beta program for $149 / month to be able to export
                    your leads and get 1000 free monthly credits for enrichments.
                  </Typography>
                  <Button
                    color='success'
                    onClick={async () => {
                      const res = await api.billing.createCheckboutSessions();
                      window.location.href = res.data.checkoutUrl;
                    }}
                  >
                    Subscribe
                  </Button>
                </>
            }
          </Card>
        </div>
      </LoadingContainer>
    </div>
  );
}
