import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumericFormatComponent(props: CustomProps, ref: React.Ref<NumericFormatProps | null>) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=','
      decimalScale={0}
    />
  );
}

export const NumericFormatAdapter = React.forwardRef<NumericFormatProps, CustomProps>(
  NumericFormatComponent,
);
