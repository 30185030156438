import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Chip,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';
import normalizeUrl from 'normalize-url';
import { tss } from 'tss-react';
import {
  Cancel,
  Check,
  CheckCircle,
  Checklist,
  Close,
  ContactMail,
  Email,
  Facebook,
  Google,
  Instagram,
  Twitter,
  YouTube,
} from '@mui/icons-material';
import StepsModal from 'src/components/RequirementStepsModal';
import { assetLink, compactNumber } from 'src/utils';
import { GetAutopilotBusinessViewResponse } from 'src/generated/api';
import { getAPI } from 'src/api';
import LoadingContainer from 'src/components/LoadingContainer';
import EngageEmail from './Email';
import ContactInfoModal from './ContactInfoModal';
import SubjectModal from './SubjectModal';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      gap: theme.spacing(3),
    },
    titleRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    titleRowText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    requirementCards: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
      justifyContent: 'space-between',
    },
    requirementCard: {
      gap: 0,
      flexBasis: '45%',
      flexGrow: 1,
      maxWidth: '50%',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.plainHoverBg,
      },
    },
    requirementTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 2,
    },
    requirementIcon: {
      width: 24,
      height: 24,
    },
    requirementExplanation: {
      marginTop: theme.spacing(1),
    },
    headerRight: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      textAlign: 'end',
      marginLeft: 'auto',
      gap: 4,
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: theme.spacing(1),
    },
    approveButton: {
    },
    approveButtonIcon: {
      width: 20,
      height: 20,
    },
    infoButton: {
      display: 'flex',
      flexDirection: 'row',
      gap: 4,
    },
    emailSectionHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    linkIcon: {
      width: 24,
      height: 24,
    },
    figuresAndLinks: {
      display: 'flex',
      flexDirection: 'row',
    },
    linksSection: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      flex: 1,
    },
    linksSectionRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
    },
    figuresSection: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: theme.spacing(4),
      flex: 1,
    },
    figure: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    chipsAndContact: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(4),
    },
    chipsSection: {
      display: 'flex',
      flexDirection: 'column',
      flex: 2,
    },
    chips: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    chip: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&:hover': {
        filter: 'brightness(120%)',
        cursor: 'pointer',
      },
    },
    chipsSectionContent: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    contactCard: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'flex-start',
      gap: theme.spacing(1),
      flex: 1,
    },
    sectionTitleRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    sectionTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    contactInfoRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      textWrap: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      gap: theme.spacing(2),
    },
    contactInfoTitleRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  }));

type Props = {
  campaignId: string;
  businessId: string;
  refetchList: (resetSelection?: boolean) => void;
};

export default function EngageBusinessView({
  campaignId,
  businessId,
  refetchList,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const [showContactInfoModal, setShowContactInfoModal] = useState(false);
  const [showSubjectModal, setShowSubjectModal] = useState(false);
  const [data, setData] = useState<GetAutopilotBusinessViewResponse | null>(null);
  const [infoModalData, setInfoModalData] =
    useState<GetAutopilotBusinessViewResponse['requirementsResults'][number] | null>(null);
  const api = getAPI();
  const fetchData = useCallback(async () => {
    const res = await api.autopilot.getBusinessView(campaignId, businessId);
    setData(res.data);
    refetchList();
  }, [api, campaignId, businessId, refetchList]);
  const setApproved = useCallback(async (approved: boolean) => {
    await api.autopilot.approveSequence(campaignId, businessId, {
      approved,
    });
    const res = await api.autopilot.getBusinessView(campaignId, businessId);
    setData(res.data);
    refetchList();
  }, [api, campaignId, businessId, refetchList]);
  const remove = useCallback(async () => {
    await api.autopilot.removeFromAutopilot(campaignId, {
      businessIds: [businessId],
    });
    refetchList(true);
  }, [api, campaignId, businessId, refetchList]);
  useEffect(() => {
    setData(null);
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    // Hacky workaround until websockets are added
    const handle = setInterval(() => {
      if (data && data.status === 'PROCESSING') {
        fetchData();
      }
    }, 1000);
    return () => {
      clearInterval(handle);
    };
  }, [fetchData, data]);
  const sequenceStarted = (
    data?.status === 'SEQUENCE_STARTED' || data?.status === 'SEQUENCE_COMPLETED'
  );
  const shouldShowContactInfo = data?.status !== 'FAILED_REQ';
  return (
    <>
      <LoadingContainer isLoading={data == null || data.status === 'PROCESSING'}>
        <div className={classes.container}>
          <div className={classes.section}>
            <div className={classes.titleRow}>
              <div className={classes.titleRowText}>
                <Typography level='h3'>
                  { data?.name }
                </Typography>
                <Typography level='body-sm'>
                  { data?.details.city }, { data?.details.state }
                </Typography>
              </div>
              <div className={classes.headerRight}>
                <div className={classes.actions}>
                  {
                    data?.status === 'WAITING_FOR_APPROVAL' &&
                      <>
                        <Button
                          variant='outlined'
                          color='danger'
                          className={classes.approveButton}
                          onClick={(e) => {
                            e.stopPropagation();
                            remove();
                          }}
                        >
                          <Close className={classes.approveButtonIcon} />
                          Remove
                        </Button>
                        <Button
                          variant='solid'
                          color='success'
                          className={classes.approveButton}
                          onClick={(e) => {
                            setApproved(true);
                            e.stopPropagation();
                          }}
                        >
                          <Check className={classes.approveButtonIcon} />
                          Approve
                        </Button>
                      </>
                  }
                  {
                    data?.status === 'APPROVED' &&
                      <Button
                        variant='outlined'
                        color='danger'
                        className={classes.approveButton}
                        onClick={(e) => {
                          setApproved(false);
                          e.stopPropagation();
                        }}
                      >
                        <Close className={classes.approveButtonIcon} />
                        Unapprove
                      </Button>
                  }
                </div>
                {
                  data &&
                    <Typography level='body-xs'>
                      Processed: {
                        (new Date(data?.calculatedTime)).toLocaleDateString('en-us', {
                          month: 'long',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        })}
                    </Typography>
                }
              </div>
            </div>
            <Typography level='body-sm'>
              { data?.details.description }
            </Typography>
            <div className={classes.figuresAndLinks}>
              <div className={classes.figuresSection}>
                {
                  data?.details.revenue &&
                    <div className={classes.figure}>
                      <Typography level='h3'>
                        ${ compactNumber(data.details.revenue) }
                      </Typography>
                      <Typography level='body-sm'>
                        Revenue
                      </Typography>
                    </div>
                }
                {
                  data?.details.employees &&
                    <div className={classes.figure}>
                      <Typography level='h3'>
                        { data.details.employees }
                      </Typography>
                      <Typography level='body-sm'>
                        Employees
                      </Typography>
                    </div>
                }
                {
                  data?.details.foundedYear &&
                    <div className={classes.figure}>
                      <Typography level='h3'>
                        { data.details.foundedYear }
                      </Typography>
                      <Typography level='body-sm'>
                        Founded
                      </Typography>
                    </div>
                }
                {
                  data?.details.funding ?
                    <div className={classes.figure}>
                      <Typography level='h3'>
                        ${ compactNumber(data.details.funding) }
                      </Typography>
                      <Typography level='body-sm'>
                        Funding
                      </Typography>
                    </div> : null
                }
              </div>
              <div className={classes.linksSection}>
                <Typography level='body-sm'>
                  <b>
                    Links
                  </b>
                </Typography>
                <div className={classes.linksSectionRow}>
                  {
                    data?.details.website &&
                      <Button
                        variant='outlined'
                        size='sm'
                        color='neutral'
                        onClick={() => {
                          window.open(normalizeUrl(data.details.website!));
                        }}
                      >
                        Website
                      </Button>
                  }
                  {
                    data?.details.gmbUrl &&
                      <Tooltip title="Open Google Maps">
                        <IconButton
                          variant='plain'
                          onClick={() => {
                            window.open(normalizeUrl(data.details.gmbUrl!));
                          }}
                        >
                          <Google
                            className={classes.linkIcon}
                          />
                        </IconButton>
                      </Tooltip>
                  }
                  {
                    data?.details.facebookUrl &&
                      <Tooltip title="Open Facebook Page">
                        <IconButton
                          variant='plain'
                          onClick={() => {
                            window.open(normalizeUrl(data.details.facebookUrl!));
                          }}
                        >
                          <Facebook
                            className={classes.linkIcon}
                          />
                        </IconButton>
                      </Tooltip>
                  }
                  {
                    data?.details.twitterUrl &&
                      <Tooltip title="Open Twitter Profile">
                        <IconButton
                          variant='plain'
                          onClick={() => {
                            window.open(normalizeUrl(data.details.twitterUrl!));
                          }}
                        >
                          <Twitter
                            className={classes.linkIcon}
                          />
                        </IconButton>
                      </Tooltip>
                  }
                  {
                    data?.details.youtubeUrl &&
                      <Tooltip title="Open YouTube Profile">
                      <IconButton
                        variant='plain'
                        onClick={() => {
                          window.open(normalizeUrl(data.details.youtubeUrl!));
                        }}
                      >
                        <YouTube
                          className={classes.linkIcon}
                        />
                      </IconButton>
                      </Tooltip>
                  }
                  {
                    data?.details.instagramUrl &&
                      <Tooltip title="Open Instagram Profile">
                        <IconButton
                          variant='plain'
                          onClick={() => {
                            window.open(normalizeUrl(data.details.instagramUrl!));
                          }}
                        >
                          <Instagram
                            className={classes.linkIcon}
                          />
                        </IconButton>
                      </Tooltip>
                  }
                  {
                    data?.details.crunchbaseUrl &&
                      <Tooltip title="Open Crunchbase Profile">
                        <IconButton
                          variant='plain'
                          onClick={() => {
                            window.open(normalizeUrl(data.details.crunchbaseUrl!));
                          }}
                        >
                          <img
                            className={classes.linkIcon}
                            src={assetLink('crunchbase.png')}
                          />
                        </IconButton>
                      </Tooltip>
                  }
                </div>
              </div>
            </div>
            <div className={classes.chipsAndContact}>
              <div className={classes.chipsSection}>
                {
                  data && data.details.productAndServices.length > 0 &&
                    <div className={classes.chips}>
                      <Typography level='body-xs'>
                        Products and Services
                      </Typography>
                      <div className={classes.chipsSectionContent}>
                        {
                          data.details.productAndServices.map((ps) => (
                            <Tooltip
                              arrow
                              key={ps.keyPhrase}
                              title={ps.description}
                            >
                              <Chip
                                color='warning'
                                slotProps={{
                                  label: {
                                    className: classes.chip,
                                  },
                                }}
                              >
                                {ps.keyPhrase.toLowerCase()}
                              </Chip>
                            </Tooltip>
                          ))
                        }
                      </div>
                    </div>
                }
                {
                  data && data.details.customerSegments.length > 0 &&
                    <div className={classes.chips}>
                      <Typography level='body-xs'>
                        End Customers
                      </Typography>
                      <div className={classes.chipsSectionContent}>
                        {
                          data.details.customerSegments.map((cs) => (
                            <Tooltip
                              arrow
                              key={cs.keyPhrase}
                              title={cs.description}
                            >
                              <Chip
                                key={cs.keyPhrase}
                                color='success'
                                slotProps={{
                                  label: {
                                    className: classes.chip,
                                  },
                                }}
                              >
                                {cs.keyPhrase.toLowerCase()}
                              </Chip>
                            </Tooltip>
                          ))
                        }
                      </div>
                    </div>
                }
                {
                  data && data.details.serviceAreas.length > 0 &&
                    <div className={classes.chips}>
                      <Typography level='body-xs'>
                        Service Areas
                      </Typography>
                      <div className={classes.chipsSectionContent}>
                        {
                          data.details.serviceAreas.map((sa) => (
                            <Chip
                              key={sa}
                              color='primary'
                              slotProps={{
                                label: {
                                  className: classes.chip,
                                },
                              }}
                            >
                              {sa}
                            </Chip>
                          ))
                        }
                      </div>
                    </div>
                }
              </div>
              {
                shouldShowContactInfo && data &&
                  <div className={classes.contactCard}>
                    <div className={classes.contactInfoTitleRow}>
                      <Typography level='h4' className={classes.sectionTitle}>
                        <ContactMail />
                        Contact Info
                      </Typography>
                      <Button
                        onClick={() => setShowContactInfoModal(true)}
                        variant='outlined'
                        size='sm'
                      >
                        Edit
                      </Button>
                    </div>
                    <div className={classes.contactInfoRow}>
                      <Typography level='body-md'>
                        Name
                      </Typography>
                      {
                        data.contactInfo.firstName ?
                          <Typography level='body-md'>
                            {data.contactInfo.firstName} {data.contactInfo.lastName}
                          </Typography> :
                          <Typography level='body-md' color='danger'>
                            <i>
                              Unknown
                            </i>
                          </Typography>
                      }
                    </div>
                    <div className={classes.contactInfoRow}>
                      <Typography level='body-md'>
                        Job Title
                      </Typography>
                      {
                        data.contactInfo.jobTitle ?
                          <Typography level='body-md'>
                            {data.contactInfo.jobTitle}
                          </Typography> :
                          <Typography level='body-md' color='danger'>
                            <i>
                              Unknown
                            </i>
                          </Typography>
                      }
                    </div>
                    <div className={classes.contactInfoRow}>
                      <Typography level='body-md'>
                        Email Address
                      </Typography>
                      {
                        data.contactInfo.emailAddress ?
                          <Typography level='body-md'>
                            {data.contactInfo.emailAddress}
                          </Typography> :
                          <Typography level='body-md' color='danger'>
                            <i>
                              Unknown
                            </i>
                          </Typography>
                      }
                    </div>
                  </div>
              }
            </div>
          </div>
          <div className={classes.section}>
            <div className={classes.sectionTitleRow}>
              <Typography level='h4' className={classes.sectionTitle}>
                <Checklist />
                Requirements
              </Typography>
              {
                data?.forcePass ?
                  <Typography level='body-lg' color='success'>
                    <i>
                      Forced Pass
                    </i>
                  </Typography> :
                  <Button variant='outlined' onClick={async () => {
                    await api.autopilot.overrideRequirementResult(campaignId, businessId);
                    await fetchData();
                  }}>
                    Force Pass
                  </Button>
              }
            </div>
            <div className={classes.requirementCards}>
              {
                data?.requirementsResults.map((r) => (
                  <Card
                    key={r.requirement.id}
                    className={classes.requirementCard}
                    onClick={() => setInfoModalData(r)}
                  >
                    <Typography level='body-lg' className={classes.requirementTitle}>
                      {
                        r.value ?
                          <CheckCircle
                            color='success'
                            className={classes.requirementIcon}
                          /> :
                          <Cancel
                            color='error'
                            className={classes.requirementIcon}
                          />
                      }
                      <b>{r.requirement.name}</b>
                    </Typography>
                    <Typography level='body-md'>
                      {r.requirement.prompt}
                    </Typography>
                    <Typography
                      level='body-sm'
                      color='primary'
                      className={classes.requirementExplanation}
                    >
                      <i>
                        { r.explanation }
                      </i>
                    </Typography>
                  </Card>
                ))
              }
            </div>
          </div>
          {
            data?.sequence &&
              <div className={classes.section}>
                <div className={classes.emailSectionHeader}>
                  <Typography level='h4' className={classes.sectionTitle}>
                    <Email />
                    Communication
                  </Typography>
                  {
                    !sequenceStarted &&
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => setShowSubjectModal(true)}
                      >
                        Edit Subject
                      </Button>
                  }
                </div>
                {
                  data.sequence.emails
                    .map((e, idx) => (
                      <EngageEmail
                        key={e.colId}
                        campaignId={campaignId}
                        businessId={businessId}
                        recipientReplied={data.status === 'REPLIED'}
                        isFollowUp={idx > 0}
                        approved={data.sequence.approved}
                        subject={data.sequence!.subject}
                        contactInfo={data.contactInfo}
                        refetchData={fetchData}
                        email={e}
                      />
                    ))
                }
              </div>
          }
        </div>
      </LoadingContainer>
      {
        infoModalData &&
          <StepsModal
            value={infoModalData.value}
            explanation={infoModalData.explanation!}
            steps={infoModalData.steps!}
            prompt={infoModalData.requirement.prompt}
            onClose={() => setInfoModalData(null)}
          />
      }
      {
        showContactInfoModal && data &&
          <ContactInfoModal
            campaignId={campaignId}
            businessId={businessId}
            contactInfo={data.contactInfo}
            onClose={() => {
              setShowContactInfoModal(false);
              fetchData();
            }}
          />
      }
      {
        showSubjectModal && data &&
          <SubjectModal
            campaignId={campaignId}
            businessId={businessId}
            sequence={data.sequence}
            onClose={() => {
              setShowSubjectModal(false);
              fetchData();
            }}
          />
      }
    </>
  );
}
